import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "../inputs/DatePicker";
import Input from "../inputs/Input";
import ModalComponent from "../Modal";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  priceList?: any;
  getData: () => void;
}

const DictionaryEditPriceModal = ({
  isOpen,
  handleModalClose,
  priceList,
  getData,
}: Props) => {
  const { id, name, validFrom, validTo } = priceList;

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      id,
      priceListName: name,
      validFrom,
      validTo,
    },
  });

  useEffect(() => {
    reset({
      id,
      priceListName: name,
      validFrom,
      validTo,
    });
  }, [priceList]);

  const onSubmit = handleSubmit((data: any) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/updateById?priceListId=${id}`,
        {
          discountListName: data.priceListName,
          lastUpdate: dayjs(new Date()).format("YYYY-MM-DD"),
          name: data.priceListName,
          status: true,
          validFrom: dayjs(data.validFrom).format("YYYY-MM-DD"),
          validTo: dayjs(data.validTo).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Edited successfully");
          getData();
          handleModalClose();
        }
      })
      .catch((err) => {
        toast.error("Error occur");
        console.log(err);
      });
  });

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        handleClose={handleModalClose}
        title='Price List'
        w='xl'
      >
        <form style={{ width: "100%", marginTop: -20 }}>
          <Box sx={{ width: "100%" }}>
            <Input name='priceListName' label='Name' control={control} />
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box sx={{ width: "100%", mr: 1 }}>
              <DatePicker
                fullWidth
                name='validFrom'
                control={control}
                label='Valid from: '
              />
            </Box>
            <Box sx={{ width: "100%", ml: 1 }}>
              <DatePicker
                fullWidth
                name='validTo'
                control={control}
                label='Valid to: '
              />
            </Box>
          </Box>
          <Box></Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={onSubmit}>Save</Button>
          </Box>
        </form>
      </ModalComponent>
    </>
  );
};

export default DictionaryEditPriceModal;
