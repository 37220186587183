import { Redirect, RedirectProps, Route } from "react-router-dom";
import { Role } from "../hooks/useAuth";

interface Props {
  auth: boolean;
  component: any;
  hasAccess: Role[];
  role: Role | undefined;
  path: string;
  props?: RedirectProps | Readonly<RedirectProps>;
  exact?: boolean;
}

const ProtectedRoute = ({
  auth,
  role,
  hasAccess,
  path,
  exact,
  component: Component,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth && !!hasAccess.find((r) => r === role))
          return <Route exact={exact} component={Component} path={path} />;
        if (!role) return <Redirect to="/login" />;
        if (!auth)
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
      }}
    />
  );
};

export default ProtectedRoute;
