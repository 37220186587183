import axios from "axios";

export const AddNewProductsToOffer = async (offerId: number, inputData: any[]): Promise<void> => {
    await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/add?offerId=${offerId}`,
        inputData.map(data => ({
            baseProductId: data.baseProductId,
            competitor: data.competitor,
            competitorPrice: data.competitorPrice,
            discountFromPL: data.discountFromPL,
            funpriceProfitControl: data.funpriceProfitControl,
            funpriceSalesman: data.funpriceSalesman,
            marginTransferPrice: data.marginTransferPrice,
            profitProjectControl: data.profitProjectControl,
            projectPrice: data.projectPrice,
            quantity: data.quantity,
            salesmanProfit: data.salesmanProfit,
            value: data.value,
        })),
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }
    );
}

