import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import Input from "./inputs/Input";
import useAuth from "../hooks/useAuth";
import Logo from "../assets/balluf_logo.png";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";

const Login = () => {
  const [open, setOpen] = React.useState(true);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      login: "",
      password: "",
    },
  });

  const { onLogin, error } = useAuth();

  const onSubmit = handleSubmit((data) => {
    const { login, password } = data;
    onLogin(login, password);
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h2">
          {/* <img src={Logo} alt="Balluf Logo" /> */}
          BALLUFF
        </Typography>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <Input
            name="login"
            label="Login"
            control={control}
            variant="outlined"
          />
          <Input
            name="password"
            label="Password"
            type="password"
            control={control}
            variant="outlined"
          />
          {error && (
            <Box sx={{ width: "100%" }}>
              <Collapse in={open}>
                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {error}
                </Alert>
              </Collapse>
            </Box>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={onSubmit}
          >
            Sign In
          </Button>
          <Alert severity="info">
            This application may work differently on InternetExplorer and
            Microsoft Edge, so we recommend that you use it on supported
            browsers such as Chrome, Firefox and Opera.
          </Alert>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
