import { useState, useMemo, useEffect, useCallback } from "react";
import useFetch from "../../../hooks/useFetch";

const useGetAllTickets = () => {
  const [searchString, setSearchString] = useState<string>();
  const [showClosed, setShowClosed] = useState(false);

  const userInfo = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user")!)
    : null;
  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/ticket/get/receiverid?page=0&receiverId=${userInfo.id}&size=1000&sortBy=id&sortDirection=DESC`
  );

  const visibleData = useMemo(() => {
    if (!data?.tickets) return null;

    if (searchString) {
      return data.tickets.filter(
        (f: any) =>
          (showClosed ? true : f.ticketStatus !== "CLOSED") &&
          (f.topic.toLowerCase().includes(searchString) ||
            f.creator.userName.toLowerCase().includes(searchString))
      );
    } else if (!showClosed)
      return data.tickets.filter((f: any) => f.ticketStatus !== "CLOSED");

    return data?.tickets;
  }, [data, searchString, showClosed]);

  return { visibleData, setSearchString, showClosed, setShowClosed, getData };
};

export default useGetAllTickets;
