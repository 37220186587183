import { TableRow } from "@mui/material";
import React from "react";
import Cell from "./cell";
import { getColumnKey } from "./getColumnKey";
import { TableColumnType } from "./types";

export interface BodyRowProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  rowData: T;
  rowIndex: number;
}

function bodyRow<T>({ columns, rowData, rowIndex }: BodyRowProps<T>) {
  return (
    <TableRow>
      {columns.map((column, index) => {
        if (column.subColumns !== undefined) {
          return column.subColumns.map((subColumn, subIndex) => {
            return (
                <Cell
                    key={getColumnKey(subColumn, subIndex)}
                    column={subColumn}
                    rowIndex={rowIndex}
                    rowData={rowData}
                />
            );
          });
        } else {
          return (
              <Cell
                  key={getColumnKey(column, index)}
                  column={column}
                  rowIndex={rowIndex}
                  rowData={rowData}
              />
          );
        }
      })}
    </TableRow>
  );
}

export default bodyRow;
