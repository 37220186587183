import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import ModalComponent from "../Modal";
import FileInput from "../inputs/FileInput";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  discounts?: any;
  getData: () => void;
}

const CompetitorsImportModal = ({
  isOpen,
  handleModalClose,
  getData,
}: Props) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = handleSubmit((data: any) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${sessionStorage.getItem("token")}`
    );
    var formdata = new FormData();
    formdata.append("file", data.file[0], data.file[0].name);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/app/competitor/import`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        toast.success("Imported successfully");
        getData();
        handleModalClose();
      })
      .catch((error) => {
        toast.error("Error occur");
        console.log("error", error);
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title='Competitors'
    >
      <form style={{ width: "100%", marginTop: -20 }}>
        <Box>
          <FileInput name='file' control={control} label='Add File' />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={onSubmit}>Save</Button>
        </Box>
      </form>
    </ModalComponent>
  );
};

export default CompetitorsImportModal;
