import axios from "axios";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { login } from "../api/auth";

export const AuthContext = React.createContext<ReturnType<
  typeof useAuthContext
> | null>(null);

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
  ACCEPT1 = "ACCEPT1",
  ACCEPT2 = "ACCEPT2",
}

export interface UserInfo {
  username: string;
  role: Role;
}

export const useAuthContext = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const authenticated = sessionStorage.getItem("token") ? true : false;
  const userInfo = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user")!)
    : null;

  const onLogout = useCallback(() => {
    sessionStorage.clear();
    setLoading(true);
    window.location.reload();
  }, []);

  if (authenticated && userInfo === null) {
    setError("An error occurred. Please try again");
    sessionStorage.clear();
  }

  const onLogin = useCallback(async (username: string, password: string) => {
    setLoading(true);
    const response = await login(username, password);

    if (response) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/app/user/getCurrentInfo`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError("An error occurred. Please try again");
          sessionStorage.clear();
        });
    } else {
      setError("Invalid username or password");
    }
  }, []);

  return { authenticated, loading, userInfo, onLogin, onLogout, error };
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === null) {
    throw new Error(
      "useAuth must be used within an AuthProvider to work correctly"
    );
  }
  return context;
};

export default useAuth;
