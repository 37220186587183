import { useEffect, useState } from "react";
import { Box, IconButton, Button } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Input from "../inputs/Input";
import DatePicker from "../inputs/DatePicker";
import ModalComponent from "../Modal";
// import Select from '../inputs/Select';

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
}

const GenerateProjectsReport = ({ isOpen, handleModalClose }: Props) => {
  const valuesToExport = [
    {
      id: 0,
      value: "OFFER_NUMBER",
      label: "OFFER NUMBER",
    },
    {
      id: 1,
      value: "OFFER_CREATOR",
      label: "OFFER CREATOR",
    },
    {
      id: 2,
      value: "CUSTOMER",
      label: "CUSTOMER",
    },
    {
      id: 3,
      value: "REGION_UNIT",
      label: "REGION UNIT",
    },
    {
      id: 4,
      value: "CLOSED_AT",
      label: "CLOSED AT",
    },
    {
      id: 5,
      value: "OFFER_STATUS",
      label: "OFFER STATUS",
    },
    {
      id: 6,
      value: "SUM",
      label: "SUM",
    },
    {
      id: 7,
      value: "TOT_PROFIT_CONTROL",
      label: "TOTAL PROFIT CONTROL",
    },
    {
      id: 8,
      value: "MARGIN_TRANSFER_PRICE",
      label: "MARGIN TRANSFER PRICE",
    },
  ];
  const [getReport, setGetReport] = useState<boolean>(false);
  const [sortField, setSortField] = useState<string>("id");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [filters, setFilters] = useState({
    // valuesToExport: [],
    closedAtDateFrom: "",
    closedAtDateTo: "",
    creatorName: "",
    customerName: "",
    maxSum: "",
    maxSumMarginTransferPrice: "",
    maxTotalityProjectProfitControl: "",
    minSum: "",
    minSumMarginTransferPrice: "",
    minTotalityProjectProfitControl: "",
    offerNumber: "",
  });

  useEffect(() => {
    if (getReport) {
      fetchReport();
      setGetReport(false);
    }
  }, [getReport]);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      // valuesToExport: [],
      closedAtDateFrom: "",
      closedAtDateTo: "",
      creatorName: "",
      customerName: "",
      maxSum: "",
      maxSumMarginTransferPrice: "",
      maxTotalityProjectProfitControl: "",
      minSum: "",
      minSumMarginTransferPrice: "",
      minTotalityProjectProfitControl: "",
      offerNumber: "",
    },
  });

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      // valuesToExport: data.valuesToExport,
      closedAtDateFrom:
        data.closedAtDateFrom !== ""
          ? dayjs(data.closedAtDateFrom).format("YYYY-MM-DD")
          : "",
      closedAtDateTo:
        data.closedAtDateTo !== ""
          ? dayjs(data.closedAtDateTo).format("YYYY-MM-DD")
          : "",
      creatorName: data.creatorName,
      customerName: data.customerName,
      maxSum: data.maxSum,
      maxSumMarginTransferPrice: data.maxSumMarginTransferPrice,
      maxTotalityProjectProfitControl: data.maxTotalityProjectProfitControl,
      minSum: data.minSum,
      minSumMarginTransferPrice: data.minSumMarginTransferPrice,
      minTotalityProjectProfitControl: data.minTotalityProjectProfitControl,
      offerNumber: data.offerNumber,
    });
    reset();
    handleModalClose();
    setGetReport(!getReport);
  });

  const fetchReport = () => {
    axios
      // .get(
      // 	`${process.env.REACT_APP_API_BASE_URL}/app/report/export/reportOffer/sort/filter?${
      // 		filters.closedAtDateFrom !== ''
      // 			? `closedAtDateFrom=${filters.closedAtDateFrom}`
      // 			: ''
      // 	}${
      // 		filters.closedAtDateTo !== '' ? `&closedAtDateTo=${filters.closedAtDateTo}` : ''
      // 	}${filters.creatorName !== '' ? `&creatorName=${filters.creatorName}` : ''}${
      // 		filters.customerName !== '' ? `&customerName=${filters.customerName}` : ''
      // 	}${filters.maxSum !== '' ? `&maxSum=${filters.maxSum}` : ''}${
      // 		filters.maxSumMarginTransferPrice !== ''
      // 			? `&maxSumMarginTransferPrice=${filters.maxSumMarginTransferPrice}`
      // 			: ''
      // 	}${
      // 		filters.maxTotalityProjectProfitControl !== ''
      // 			? `&maxTotalityProjectProfitControl=${filters.maxTotalityProjectProfitControl}`
      // 			: ''
      // 	}${filters.minSum !== '' ? `&minSum=${filters.minSum}` : ''}${
      // 		filters.minSumMarginTransferPrice !== ''
      // 			? `&minSumMarginTransferPrice=${filters.minSumMarginTransferPrice}`
      // 			: ''
      // 	}${
      // 		filters.minTotalityProjectProfitControl !== ''
      // 			? `&minTotalityProjectProfitControl=${filters.minTotalityProjectProfitControl}`
      // 			: ''
      // 	}${filters.offerNumber !== '' ? `&offerNumber=${filters.offerNumber}` : ''}
      // 	&sortBy=${sortField}&sortDirection=${sortDirection}&valuesToExport=${filters.valuesToExport[0]}
      // 	${filters.valuesToExport[1] !== undefined ? `&valuesToExport=${filters.valuesToExport[1]} ` : ''}
      // 	${filters.valuesToExport[2] !== undefined ? `&valuesToExport=${filters.valuesToExport[2]} ` : ''}
      // 	${filters.valuesToExport[3] !== undefined ? `&valuesToExport=${filters.valuesToExport[3]} ` : ''}
      // 	${filters.valuesToExport[4] !== undefined ? `&valuesToExport=${filters.valuesToExport[4]} ` : ''}
      // 	${filters.valuesToExport[5] !== undefined ? `&valuesToExport=${filters.valuesToExport[5]} ` : ''}
      // 	${filters.valuesToExport[6] !== undefined ? `&valuesToExport=${filters.valuesToExport[6]} ` : ''}
      // 	${filters.valuesToExport[7] !== undefined ? `&valuesToExport=${filters.valuesToExport[7]} ` : ''}
      // 	${filters.valuesToExport[8] !== undefined ? `&valuesToExport=${filters.valuesToExport[8]} ` : ''}
      // 	`,
      // 	{
      // 		headers: {
      // 			Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      // 		},
      // 		responseType: 'blob',
      // 	}
      // )
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/app/report/export/reportOffer/sort/filter?${
          filters.closedAtDateFrom !== ""
            ? `closedAtDateFrom=${filters.closedAtDateFrom}`
            : ""
        }${
          filters.closedAtDateTo !== ""
            ? `&closedAtDateTo=${filters.closedAtDateTo}`
            : ""
        }${
          filters.creatorName !== ""
            ? `&creatorName=${filters.creatorName}`
            : ""
        }${
          filters.customerName !== ""
            ? `&customerName=${filters.customerName}`
            : ""
        }${filters.maxSum !== "" ? `&maxSum=${filters.maxSum}` : ""}${
          filters.maxSumMarginTransferPrice !== ""
            ? `&maxSumMarginTransferPrice=${filters.maxSumMarginTransferPrice}`
            : ""
        }${
          filters.maxTotalityProjectProfitControl !== ""
            ? `&maxTotalityProjectProfitControl=${filters.maxTotalityProjectProfitControl}`
            : ""
        }${filters.minSum !== "" ? `&minSum=${filters.minSum}` : ""}${
          filters.minSumMarginTransferPrice !== ""
            ? `&minSumMarginTransferPrice=${filters.minSumMarginTransferPrice}`
            : ""
        }${
          filters.minTotalityProjectProfitControl !== ""
            ? `&minTotalityProjectProfitControl=${filters.minTotalityProjectProfitControl}`
            : ""
        }${
          filters.offerNumber !== ""
            ? `&offerNumber=${filters.offerNumber}`
            : ""
        }
				&sortBy=${sortField}&sortDirection=${sortDirection}&valuesToExport=${
          valuesToExport[0].value
        }
				${`&valuesToExport=${valuesToExport[1].value}`}
				${`&valuesToExport=${valuesToExport[2].value}`}
				${`&valuesToExport=${valuesToExport[3].value}`}
				${`&valuesToExport=${valuesToExport[4].value}`}
				${`&valuesToExport=${valuesToExport[5].value}`}
				${`&valuesToExport=${valuesToExport[6].value}`}
				${`&valuesToExport=${valuesToExport[7].value}`}
				${`&valuesToExport=${valuesToExport[8].value}`}
				`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, "Report.xlsx");
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
    handleModalClose();
  };

  const handleFilterClear = () => {
    reset();
  };

  return (
    //delete filter from this component
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title="Generate Project Report"
      w="xl"
    >
      <Box sx={{ mx: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Filter</h3>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: "100%" }}>
          <form>
            {/* <Select
							control={control}
							options={valuesToExport}
							name="valuesToExport"
							label="Values To Export"
							required
							multiple
						/> */}
            <DatePicker
              fullWidth
              name="closedAtDateFrom"
              control={control}
              label="Closed At Date From: "
            />
            <DatePicker
              fullWidth
              name="closedAtDateTo"
              control={control}
              label="Closed At Date To: "
            />
            <Input name="creatorName" control={control} label="Creator Name" />
            <Input
              name="customerName"
              control={control}
              label="Customer Name"
            />
            <Input name="maxSum" control={control} label="Max Project Value" />
            <Input
              name="maxSumMarginTransferPrice"
              control={control}
              label="Max Margin Transfer Price"
            />
            <Input
              name="maxTotalityProjectProfitControl"
              control={control}
              label="Max Project Profit Control"
            />
            <Input name="minSum" control={control} label="Min Project Value" />
            <Input
              name="minSumMarginTransferPrice"
              control={control}
              label="Min Margin Transfer Price"
            />
            <Input
              name="minTotalityProjectProfitControl"
              control={control}
              label="Min Project Profit Control"
            />
            <Input
              name="offerNumber"
              control={control}
              label="Project Number"
            />
          </form>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button color="primary" onClick={handleFilterClear}>
            Clear
          </Button>
          <Button color="primary" onClick={handleFilterSubmit}>
            Confirm
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default GenerateProjectsReport;
