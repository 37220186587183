import { useState } from "react";

const useTableSorting = (
  defaultSoftField: string,
  defaultSortDirection?: "ASC" | "DESC"
) => {
  const [sortField, setSortField] = useState(defaultSoftField);
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">(
    defaultSortDirection ?? "ASC"
  );

  return {
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  };
};

export default useTableSorting;
