import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import DatePicker from '../inputs/DatePicker';
import ModalComponent from '../Modal';
import dayjs from 'dayjs';
import FileInput from '../inputs/FileInput';
import Input from '../inputs/Input';
import { toast } from 'react-toastify';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	discounts?: any;
	getData: () => void;
}

const DictionaryReplaceDiscountsModal = ({
	isOpen,
	handleModalClose,
	discounts,
	getData,
}: Props) => {
	const { id } = discounts;

	const { control, handleSubmit } = useForm({
		defaultValues: {
			id,
			name: '',
			validFrom: '',
			validTo: '',
		},
	});

	const onSubmit = handleSubmit((data: any) => {
		const validFrom = dayjs(data.validFrom).format('YYYY-MM-DD');
		const validTo = dayjs(data.validTo).format('YYYY-MM-DD');

		var myHeaders = new Headers();
		myHeaders.append('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
		var formdata = new FormData();
		formdata.append('file', data.file[0], data.file[0].name);

		var requestOptions = {
			method: 'PUT',
			headers: myHeaders,
			body: formdata,
		};

		fetch(
			`${process.env.REACT_APP_API_BASE_URL}/app/discountlist/import_file?activeFrom=${validFrom}&activeTo=${validTo}&fileName=${data.name}`,
			requestOptions
		)
			//.then(response => response.text())
			.then(result => {
				if (result.status === 200) {
					toast.success('Imported successfully');
				} else {
					toast.error('The import failed');
				}
				getData();
				handleModalClose();
			})
			.catch(error => {
				toast.error('Error occur');
				console.log('error', error);
			});
	});

	return (
		<ModalComponent
			isOpen={isOpen}
			handleClose={handleModalClose}
			title="Import discounts list"
		>
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="name" label="Name" control={control} required />
				</Box>
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Box sx={{ width: '100%', mr: 1 }}>
						<DatePicker
							fullWidth
							name="validFrom"
							control={control}
							label="Valid from: "
						/>
					</Box>
					<Box sx={{ width: '100%', ml: 1 }}>
						<DatePicker fullWidth name="validTo" control={control} label="Valid to: " />
					</Box>
				</Box>
				<Box>
					<FileInput name="file" control={control} label="Select File" />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>
					<Button onClick={onSubmit}>Import</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default DictionaryReplaceDiscountsModal;
