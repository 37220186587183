import { post } from "./client";

export async function login(username: string, password: string) {
  try {
    await post("/login", { username, password }).then((res) =>
      sessionStorage.setItem("token", res.access_token)
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
