import { Key } from "react";
import { TableColumnType, TableGetRowKey } from "./types";
import BodyRow from "./bodyRow";
import { TableBody } from "@mui/material";

export interface BodyProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  data?: ReadonlyArray<T>;
  getRowKey?: TableGetRowKey<T>;
}

function Body<T>({ columns, data, getRowKey }: BodyProps<T>) {
  return (
    <TableBody>
      {data && data.length ? (
        data.map((rowData, index) => {
          let key: Key = index;

          if (typeof getRowKey === "function") {
            key = getRowKey(rowData, index);
          } else if (getRowKey) {
            key = rowData[getRowKey] as any;
          }
          return (
            <BodyRow
              key={index}
              columns={columns}
              rowData={rowData}
              rowIndex={index}
            />
          );
        })
      ) : (
        <tr>
          <td>no data</td>
        </tr>
      )}
    </TableBody>
  );
}

export default Body;
