import { Button, Typography, Box } from "@mui/material";
import axios from "axios";
import ModalComponent from "../Modal";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  userId: number;
  getData: () => void;
}

const DeleteUserModal = ({
  isOpen,
  handleModalClose,
  userId,
  getData,
}: Props) => {
  const handleDeleteUser = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/app/user/delete?userName=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          getData();
          handleModalClose();
          toast.success("Deleted successfully");
        } else {
          toast.error("Error occurred");
        }
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
  };

  return (
    <ModalComponent
      title='Delete User'
      isOpen={isOpen}
      handleClose={handleModalClose}
      dialogActions={
        <Box
          display='flex'
          justifyContent='space-between'
          sx={{ p: 2, width: 180 }}
        >
          <Button variant='outlined' onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleDeleteUser}>
            Delete
          </Button>
        </Box>
      }
    >
      <div>
        <Typography variant='h4'>
          Are you sure you want to delete user?
        </Typography>
      </div>
    </ModalComponent>
  );
};

export default DeleteUserModal;
