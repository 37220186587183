import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const useFetch = (url: string) => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();

  const getData = useCallback(() => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError(err as any);
      });
  }, [url, setData, setError]);

  useEffect(() => {
    getData();
  }, [getData]);

  return { data, error, getData };
};

export default useFetch;
