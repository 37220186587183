import {Box, Button, Chip, Grid, Typography} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { idText } from "typescript";
import useFetch from "../../hooks/useFetch";
import Input from "../inputs/Input";
import SelectComponent from "../inputs/Select";
import ModalComponent from "../Modal";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import dayjs from "dayjs";
import {checkStatus} from "./Projects";
import {formatValueToPLN} from "../helpers/currencyFormatter";

type Props = {
  isOpen: boolean;
  handleModalClose: () => void;
  project: any;
};

const ProposePriceModal = ({ isOpen, handleModalClose, project }: Props) => {
  const { id, projectNumber } = project;
  const [sortField, setSortField] = useState<any>("id");
  const [sortDirection, setSortDirection] = useState<any>("ASC");

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/offer/get/offerId?offerId=${id}`
  );

  const status = data?.status;
  const contractorName = data?.contractorName;
  const contractorNumber = data?.contractorNumber;
  const updatedAt = data?.offerDateInfo?.updatedAt;
  const sumMarginTransferPrice = data?.offerExtendedInfo.sumMarginTransferPrice;
  const projectProfitControl = data?.totalityProjectProfitControl;
  const totalProjectProfitSalesman =
      data?.offerBasicInfo.totalityProjectProfitSalesman;
  const totalProjectValue = data?.sum;
  const sapOfferNumber = data?.offerBasicInfo?.sapOfferNumber;

  const products = data?.products;

  const productsOptions = data?.products?.map(({ id, orderCode }: any) => {
    return {
      id,
      label: orderCode,
      value: id,
    };
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      proposedPrice: "",
      offeredProductId: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    const offeredData = [
      {
        offeredProductId: data.offeredProductId,
        proposedPrice: data.proposedPrice,
      },
    ];

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/propose?offerId=${id}`,
        offeredData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Offer has been proposed");
        reset();
        getData();
        //handleModalClose();
      })
      .catch((err) => {
        toast.error("Offer has not been proposed");
      });
  });

  const columns: TableColumnType<any>[] = [
    {
      title: "Order code",
      dataKey: "orderCode",
      width: 100,
      sort: false,
    },
    {
      title: "Material description",
      dataKey: "materialDescription",
      width: 150,
      sort: false,
    },
    {
      title: "Project Price",
      dataKey: "projectPrice",
      width: 50,
      align: "center",
      sort: false,
    },
    {
      title: "Qty",
      dataKey: "quantity",
      width: 50,
      align: "center",
      sort: false,
    },
    {
      title: "Value",
      dataKey: "value",
      width: 50,
      align: "center",
      sort: false,
    },
    {
      title: "Proposed price Accept 1",
      dataKey: "proposedPriceAccOne",
      width: 50,
      align: "center",
      sort: false,
    },
  ];

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title={`Change status for offer ${projectNumber}`}
      dialogActions={
        <Box display="flex" sx={{ p: 2 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          {/*<Button onClick={onSubmit}>Save</Button>*/}
        </Box>
      }
    >
      <Box sx={{ width: "100%" }}>

        <Box sx={{ width: "100%", mt: 3, ml: 3 }}>
          <Grid container justifyContent="center">
            <Grid item xs={2}>
              <Typography variant="h6">Sap Number</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Customer Number</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Customer Name</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Last Update</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Status</Typography>
            </Grid>
          </Grid>
          <Grid container mt={2} justifyContent="center">
            <Grid item xs={2} pr={10}>
              {sapOfferNumber}
            </Grid>
            <Grid item xs={2}>
              {contractorNumber}
            </Grid>
            <Grid item xs={2} pr={10}>
              {contractorName}
            </Grid>
            <Grid item xs={2}>
              {dayjs(updatedAt).format("YYYY-MM-DD")}
            </Grid>
            <Grid item xs={2}>
              <Chip
                  label={status}
                  color={status ? checkStatus(status) : "primary"}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mt: 3, ml: 3 }}>
          <Grid container justifyContent="center">
            <Grid item xs={2}>
              <Typography variant="h6">Total Project Value</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Total Profit Project Control</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Total Salesman Profit</Typography>
            </Grid>
            {/*{userRole !== "USER" && (*/}
                <Grid item xs={3}>
                  <Typography variant="h6">Total Margin Transfer</Typography>
                </Grid>
            {/*)}*/}
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mt: 3, ml: 3, marginBottom: 5 }}>
          <Grid container justifyContent="center">
            <Grid item xs={2} pr={10} >
              {formatValueToPLN(totalProjectValue)}
            </Grid>
            <Grid item xs={3}>
              {formatValueToPLN(projectProfitControl)}
            </Grid>
            <Grid item xs={3}>
              {formatValueToPLN(totalProjectProfitSalesman)}
            </Grid>
            {/*{userRole !== "USER" && (*/}
                <Grid item xs={3}>
                  {formatValueToPLN(sumMarginTransferPrice)}
                </Grid>
            {/*)}*/}
          </Grid>
        </Box>

        <SelectComponent
            control={control}
            name="offeredProductId"
            label="Product"
            options={productsOptions}
        />
        <Input control={control} name="proposedPrice" label="Proposed Price" />

        <Box display="flex" sx={{ p: 2 }} justifyContent="right">
          <Button onClick={onSubmit}>Save</Button>
        </Box>

        <TableComponent
          columns={columns}
          data={products}
          noSearch
          noPagination
          sortField={sortField}
          setSortField={setSortField}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Box>
    </ModalComponent>
  );
};

export default ProposePriceModal;
