import { useState, useEffect } from "react";
import usePriceListProducts from "./usePriceListProducts";
import useGetDataFromCSV from "../../../hooks/useGetDataFromCSV";
import ProductFormInput from "../models/ProductFormInput";
import * as calculator from "../tools/productCalculator";
import { toast } from "react-toastify";
import useCompetitionShortNames from "./useCompetitionShortNames";

const useAddProductsFromCSV = (
  onFinish: () => void,
  save: (newProducts: any[]) => void | Promise<void>,
  customerCategory: string | null,
  currentPriceListId?: Number | null,
  lastLocalId? : Number | null,
) => {
  const [isSaving, setIsSaving] = useState(false);
  const { priceListProductsLoading, priceListProducts } =
    usePriceListProducts(currentPriceListId);
  const { loadingCompetitionShortNames, competitionShortNames } =
    useCompetitionShortNames();
  const {
    loadingDataFromCSV,
    fileName,
    fileDataArray,
    handleFileAdded,
    resetFileData,
  } = useGetDataFromCSV();
  const [products, setProducts] = useState<ProductFormInput[]>([]);

  const onClose = (): void => {
    resetFileData();
    setProducts([]);
    onFinish();
  };

  const onConfirm = async () => {
    try {
      setIsSaving(true);
      await save(products);
      setIsSaving(false);
      onClose();
    } catch (err) {
      toast.error("Error during saving products");
      console.error(err);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    console.log(fileDataArray);
    if (fileDataArray.length === 0) return;
    console.log("=== ok ide dalej");

    const mappedProducts = fileDataArray
      .map((el): ProductFormInput | null => {
          console.log(el);
        if (!el.orderCode) return null;

        const priceListProduct = priceListProducts.find(
          (f) => f.orderCode === el.orderCode
        );

        if (!priceListProduct) {
          toast.warn(
            `${el.orderCode} skipped due to not existing on current price list`
          );
          return null;
        }

        if (
          el.competitor &&
          !competitionShortNames.find((f) => f === el.competitor)
        ) {
          toast.warn(
            `${el.orderCode} skipped due to competitor ${el.competitor} not existing on competitor list`
          );
          return null;
        }

        console.log("===== ADD PRODUCT CSV =====");
        console.log(customerCategory);
        console.log(priceListProduct);

          const categoryDiscounts = priceListProduct.productDiscounts?.find(
              (categoryDiscount: any) => categoryDiscount.category == customerCategory
          );

        console.log(categoryDiscounts);

        const product: ProductFormInput = {
          //properties from csv
          orderCode: el.orderCode,
          projectPrice: Number(el.projectPrice),
          quantity: Number(el.quantity),
          competitor: el.competitor ?? "",
          competitorPrice: Number(el.competitorPrice) ?? 0,

          //properties from price list
          baseProductId: priceListProduct.id,
          materialDescription: priceListProduct.materialDescription,
          priceListPrice: priceListProduct.priceListPrice,
          transferPrice: priceListProduct.transferPrice,
          // salesmanProfitAmount: priceListProduct.productDiscounts.discountSMMax,
          // allowedProfitForGroup: priceListProduct.productDiscounts.discountTLMax,
            salesmanProfitAmount: categoryDiscounts.discountSMMax,
            allowedProfitForGroup: categoryDiscounts.discountTLMax,

          //properties that need to be calculated
          value: 0,
          salesmanProfit: 0,
          profitProjectControl: 0,
          discountFromPL: 0,
          funpriceSalesman: 0,
          funpriceProfitControl: 0,
          marginTransferPrice: 0,

          local_id: 0
        };

        product.value = calculator.getValue(
          Number(product.projectPrice),
          product.quantity
        );
        product.salesmanProfit = calculator.getSalesmanProfit(
          product.value,
          product.priceListPrice,
          product.quantity,
          product.salesmanProfitAmount
        );
        product.profitProjectControl = calculator.getProfitProjectControl(
          product.value,
          product.priceListPrice,
          product.quantity,
          product.allowedProfitForGroup
        );
        product.discountFromPL = calculator.getDiscountFromPL(
          product.priceListPrice,
          product.projectPrice
        );
        product.funpriceSalesman = calculator.getFunPriceSalesman(
          product.priceListPrice,
          product.salesmanProfitAmount
        );
        product.funpriceProfitControl = calculator.getFunPriceProfitControl(
          product.priceListPrice,
          product.allowedProfitForGroup
        );
        product.marginTransferPrice = calculator.getMarginTransferPrice(
          product.projectPrice,
          product.transferPrice,
          product.quantity
        );

       if (typeof lastLocalId === "number") {
              product.local_id = ++lastLocalId;
       }

        return product;
      })
      .filter((f) => f !== null);
    setProducts(mappedProducts as ProductFormInput[]);
  }, [fileDataArray, priceListProducts]);

  const isProcessing =
    priceListProductsLoading ||
    loadingCompetitionShortNames ||
    loadingDataFromCSV ||
    isSaving;

  return {
    isProcessing,
    fileName,
    handleFileAdded,
    products,
    onConfirm,
    onClose,
  };
};

export default useAddProductsFromCSV;
