import { Chip, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableComponent from "../table/Table";
import useFetch from "../../hooks/useFetch";
import { TableColumnType } from "../table/types";
import dayjs from "dayjs";
import React, { useState } from "react";
import { checkStatus } from "./Projects";
import { useParams, useHistory } from "react-router-dom";
import ComponentCard from "../ComponentCard";
import { useAuthContext } from "../../hooks/useAuth";
import { formatValueToPLN } from "../helpers/currencyFormatter";
import Input from "../inputs/Input";

const ProjectInfo = () => {
  const [sortField, setSortField] = useState<any>("id");
  const [sortDirection, setSortDirection] = useState<any>("ASC");

  const { id } = useParams<any>();
  const history = useHistory();

  const auth = useAuthContext();
  const { userInfo } = auth;
  const userRole = userInfo?.role;

  const { data } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/offer/get/offerId?offerId=${id}`
  );

  const projectNumber = data?.projectNumber;
  const status = data?.status;
  const contractorName = data?.contractorName;
  const contractorNumber = data?.contractorNumber;
  const products = data?.products;
  const updatedAt = data?.offerDateInfo?.updatedAt;
  const sumMarginTransferPrice = data?.offerExtendedInfo.sumMarginTransferPrice;
  const projectProfitControl = data?.totalityProjectProfitControl;
  const totalProjectProfitSalesman =
    data?.offerBasicInfo.totalityProjectProfitSalesman;
  const totalProjectValue = data?.sum;
  const sapOfferNumber = data?.offerBasicInfo?.sapOfferNumber;
  const additionalInfo = data?.offerBasicInfo?.additionalInfo;

  const handleGoBack = () => {
    history.goBack();
  };

  let columns: TableColumnType<any>[] = [
    {
      title: "Order code",
      dataKey: "orderCode",
      width: 100,
      sort: true,
    },
    {
      title: "Material description",
      dataKey: "materialDescription",
      width: 150,
    },
    {
      title: "Project Price",
      dataKey: "projectPrice",
      width: 50,
      align: "center",
    },
    {
      title: "Price List Price",
      dataKey: "priceListPrice",
      width: 50,
      align: "center",
    },
    {
      title: "Qty",
      dataKey: "quantity",
      width: 50,
      align: "center",
    },
    {
      title: "Value",
      dataKey: "value",
      width: 50,
      align: "center",
    },
    {
      title: "Profit project control",
      dataKey: "profitProjectControl",
      width: 150,
      align: "center",
    },
    {
      title: "Salesman Profit",
      width: 150,
      align: "center",
      dataKey: "salesmanProfit",
    },
  ]
  if (userRole !== 'USER') {
    columns.push(
        {
          title: "Transfer Price",
          width: 150,
          align: "center",
          children: ({transferPrice}) =>
              userRole !== "USER" ? formatValueToPLN(transferPrice) : "-",
        }
    )
  }
  if (userRole !== 'USER') {
    columns.push(
        {
          title: "Margin Transfer Price",
          width: 150,
          align: "center",
          children: ({marginTransferPrice}) =>
              userRole !== "USER" ? formatValueToPLN(marginTransferPrice) : "-",
        }
    )
  }
  // if (userRole === 'ACCEPT1' || userRole === 'ACCEPT2' || userRole === 'USER') {
    columns.push(
        {
          title: "Discount from PL",
          dataKey: "discountFromPL",
          width: 100,
          align: "center",
        }
    )
  // }
  // if (userRole === 'USER' || userRole === 'ACCEPT1' || userRole === 'ACCEPT2') {
    columns.push(
        {
          title: "Competitor",
          dataKey: "competitor",
          width: 100,
          align: "center",
        }
    );
    columns.push(
        {
          title: "Competitor Price",
          dataKey: "competitorPrice",
          width: 100,
          align: "center",
        }
    );
  // }
  columns.push(
    {
      title: "Proposed price ACCEPT1",
      children: ({ proposedPriceAccOne }) => (
          <>{proposedPriceAccOne !== null ? formatValueToPLN(proposedPriceAccOne) : "-"}</>
      ),
      width: 100,
      align: "center",
    }
  );
  // if (userRole !== 'ACCEPT1' && userRole !== 'ACCEPT2' && userRole !== 'USER') {
  //   columns.push(
  //       {
  //         title: "Proposed price ACCEPT2",
  //         children: ({ proposedPriceAccTwo }) => (
  //             <>{proposedPriceAccTwo !== null ? formatValueToPLN(proposedPriceAccTwo) : "-"}</>
  //         ),
  //         width: 100,
  //         align: "center",
  //       }
  //   );
  // }

  // const columns: TableColumnType<any>[] = [
  //   {
  //     title: "Order code",
  //     dataKey: "orderCode",
  //     width: 100,
  //     sort: true,
  //   },
  //   {
  //     title: "Material description",
  //     dataKey: "materialDescription",
  //     width: 150,
  //   },
  //   {
  //     title: "Project Price",
  //     dataKey: "projectPrice",
  //     width: 50,
  //     align: "center",
  //   },
  //   {
  //     title: "Price List Price",
  //     dataKey: "priceListPrice",
  //     width: 50,
  //     align: "center",
  //   },
  //   {
  //     title: "Qty",
  //     dataKey: "quantity",
  //     width: 50,
  //     align: "center",
  //   },
  //   {
  //     title: "Value",
  //     dataKey: "value",
  //     width: 50,
  //     align: "center",
  //   },
  //   {
  //     title: "Profit project control",
  //     dataKey: "profitProjectControl",
  //     width: 150,
  //     align: "center",
  //   },
  //   {
  //     title: "Salesman Profit",
  //     width: 150,
  //     align: "center",
  //     dataKey: "salesmanProfit",
  //   },
  //   {
  //     title: "Transfer Price",
  //     width: 150,
  //     align: "center",
  //     children: ({ transferPrice }) =>
  //       userRole !== "USER" ? formatValueToPLN(transferPrice) : "-",
  //   },
  //   {
  //     title: "Margin Transfer Price",
  //     width: 150,
  //     align: "center",
  //     children: ({ marginTransferPrice }) =>
  //       userRole !== "USER" ? formatValueToPLN(marginTransferPrice) : "-",
  //   },
  //   {
  //     title: "Proposed price ACCEPT1",
  //     children: ({ proposedPriceAccOne }) => (
  //       <>{proposedPriceAccOne !== null ? formatValueToPLN(proposedPriceAccOne) : "-"}</>
  //     ),
  //     width: 100,
  //     align: "center",
  //   },
  //   {
  //     title: "Proposed price ACCEPT2",
  //     children: ({ proposedPriceAccTwo }) => (
  //       <>{proposedPriceAccTwo !== null ? formatValueToPLN(proposedPriceAccTwo) : "-"}</>
  //     ),
  //     width: 100,
  //     align: "center",
  //   },
  // ];

  return (
    <ComponentCard>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box mr={2}>
            <IconButton onClick={handleGoBack}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Typography variant="h5" mr={1}>
            Project Number #
          </Typography>
          <Typography variant="h6" color="secondary.dark">
            {projectNumber}
          </Typography>
        </Box>

        <Box sx={{ width: "100%", mt: 3, ml: 3 }}>
          <Grid container justifyContent="center">
            <Grid item xs={2}>
              <Typography variant="h6">Sap Number</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Customer Number</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Customer Name</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Last Update</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6">Status</Typography>
            </Grid>
          </Grid>
          <Grid container mt={2} justifyContent="center">
            <Grid item xs={2} pr={10}>
              {sapOfferNumber}
            </Grid>
            <Grid item xs={2}>
              {contractorNumber}
            </Grid>
            <Grid item xs={2} pr={10}>
              {contractorName}
            </Grid>
            <Grid item xs={2}>
              {dayjs(updatedAt).format("YYYY-MM-DD")}
            </Grid>
            <Grid item xs={2}>
              <Chip
                label={status}
                color={status ? checkStatus(status) : "primary"}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mt: 3, ml: 3 }}>
          <Grid container justifyContent="center">
            <Grid item xs={2}>
              <Typography variant="h6">Total Project Value</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Total Profit Project Control</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Total Salesman Profit</Typography>
            </Grid>
            {userRole !== "USER" && (
                <Grid item xs={3}>
                  <Typography variant="h6">Total Margin Transfer</Typography>
                </Grid>
            )}
          </Grid>
        </Box>
        <Box sx={{ width: "100%", mt: 3, ml: 3 }}>
          <Grid container justifyContent="center">
            <Grid item xs={2} pr={10} >
              {formatValueToPLN(totalProjectValue)}
            </Grid>
            <Grid item xs={3}>
              {formatValueToPLN(projectProfitControl)}
            </Grid>
            <Grid item xs={3}>
              {formatValueToPLN(totalProjectProfitSalesman)}
            </Grid>
            {userRole !== "USER" && (
                <Grid item xs={3}>
                  {formatValueToPLN(sumMarginTransferPrice)}
                </Grid>
            )}
          </Grid>
        </Box>
        <br/>
        {(additionalInfo) && (
            <Box sx={{ width: "100%", mt: 3, ml: 3 }}>
              <Typography variant="h6" mr={1}>
                Additional info
              </Typography>
              <br/>
              <Typography>
                {additionalInfo}
              </Typography>
            </Box>
        )}
        <TableComponent
          columns={columns}
          data={products}
          noSearch
          noPagination
          sortField={sortField}
          setSortField={setSortField}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Box>
    </ComponentCard>
  );
};

export default ProjectInfo;
