import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import DictionaryCard from "./DictionaryCard";
import EditIcon from "@mui/icons-material/Edit";
import DictionaryEditDiscountsModal from "./DictionaryEditDiscountsModal";
import DictionaryReplaceDiscountsModal from "./DictionaryReplaceDiscountsModal";
import DiscountListModal from "./DiscountListModal";
import ViewListIcon from "@mui/icons-material/ViewList";

interface Props {
  userRole: string;
}

const Discounts = ({ userRole }: Props) => {
  const [editData, setEditData] = useState([]);
  const [replace, setReplace] = useState(Boolean);
  const [edit, setEdit] = useState(Boolean);
  const [discountLists, setDiscountLists] = useState([]);
  const [discountListModalOpen, setDiscountListModalOpen] = useState(false);

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/discountlist/get/newest`
  );

  useEffect(() => {
    if (data) {
      setDiscountLists(data.discountLists);
    }
  }, [data]);

  const handleOpen = (fn: any, data?: any) => {
    fn(true);
    if (data) {
      setEditData(data);
    }
  };

  const handleClose = (fn: any) => {
    fn(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          mx: 1,
          mb: 1,
        }}
      >
        <Typography variant="h5" sx={{ minHeight: 35 }}>
          Discounts
        </Typography>
        {userRole === "ADMIN" ? (
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            onClick={() => handleOpen(setReplace)}
          >
            Import file
          </Button>
        ) : null}
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        {discountLists
          ? discountLists.map((item: any) => {
              return (
                <DictionaryCard
                  key={item.id}
                  name={item.name}
                  desc={`valid from: ${item.validFrom} to ${item.validTo}`}
                  menuItems={[
                    <MenuItem key={1} onClick={() => handleOpen(setEdit, item)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit Discount List" />
                    </MenuItem>,
                    <MenuItem
                      key={2}
                      onClick={() => handleOpen(setDiscountListModalOpen, item)}
                    >
                      <ListItemIcon>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Display Discounts" />
                    </MenuItem>,
                  ]}
                  userRole={userRole}
                />
              );
            })
          : null}
      </Box>
      <DictionaryEditDiscountsModal
        getData={getData}
        discounts={editData}
        isOpen={edit}
        handleModalClose={() => handleClose(setEdit)}
      />
      <DictionaryReplaceDiscountsModal
        getData={getData}
        discounts={editData}
        isOpen={replace}
        handleModalClose={() => handleClose(setReplace)}
      />
      <DiscountListModal
        discounts={editData}
        isOpen={discountListModalOpen}
        handleModalClose={() => handleClose(setDiscountListModalOpen)}
      />
    </>
  );
};

export default Discounts;
