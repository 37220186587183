import axios from "axios";
import React, { useEffect, useState } from "react";
import ComponentCard from "./ComponentCard";
import EditUserProfile from "./EditUserProfile";
import ShowUserInfo from "./ShowUserInfo";

const UserProfile = () => {
  const [edit, setEdit] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/app/user/getCurrentInfo`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setUserData({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          userName: res.data.userName,
          role: res.data.role,
          password: "",
          showPassword: false,
          id: res.data.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <ComponentCard>
      {edit ? (
        <EditUserProfile userData={userData} setEdit={setEdit} />
      ) : (
        <ShowUserInfo userData={userData} setEdit={setEdit} />
      )}
    </ComponentCard>
  );
};

export default UserProfile;
