import { useState } from "react";
import { Box, IconButton, Button } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import ComponentCard from "../ComponentCard";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../DrawerComponent";
import Input from "../inputs/Input";
import DatePicker from "../inputs/DatePicker";
import GenerateProjectsReport from "./GenerateProjectsReport";

const ProjectsReport = () => {
  const [drawer, setDrawer] = useState<boolean>(false);
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("projectNumber");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [filters, setFilters] = useState({
    closedAtDateFrom: "",
    closedAtDateTo: "",
    creatorName: "",
    customerName: "",
    maxSum: "",
    maxSumMarginTransferPrice: "",
    maxTotalityProjectProfitControl: "",
    minSum: "",
    minSumMarginTransferPrice: "",
    minTotalityProjectProfitControl: "",
    offerNumber: "",
    regionUnit: "",
  });
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      closedAtDateFrom: "",
      closedAtDateTo: "",
      creatorName: "",
      customerName: "",
      maxSum: "",
      maxSumMarginTransferPrice: "",
      maxTotalityProjectProfitControl: "",
      minSum: "",
      minSumMarginTransferPrice: "",
      minTotalityProjectProfitControl: "",
      offerNumber: "",
      regionUnit: "",
    },
  });

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/report/get/p/reportOffer/sort/filter?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&closedAtDateFrom=${filters.closedAtDateFrom}&closedAtDateTo=${filters.closedAtDateTo}&creatorName=${filters.creatorName}&customerName=${filters.customerName}&maxSum=${filters.maxSum}&maxSumMarginTransferPrice=${filters.maxSumMarginTransferPrice}&maxTotalityProjectProfitControl=${filters.maxTotalityProjectProfitControl}&minSum=${filters.minSum}&minSumMarginTransferPrice=${filters.minSumMarginTransferPrice}&minTotalityProjectProfitControl=${filters.minTotalityProjectProfitControl}&offerNumber=${filters.offerNumber}&regionUnit=${filters.regionUnit}`
  );

  const projectReportData = data?.reportOffers.map((el: any) => ({
    closedAt: el.closedAt !== null ? el.closedAt : "-",
    contractorName: el.contractorName !== null ? el.contractorName : "-",
    projectNumber: el.projectNumber !== null ? el.projectNumber : "-",
    regionUnit: el.regionUnit !== null ? el.regionUnit : "-",
    status: el.status !== null ? el.status : "-",
    sum: el.sum !== null ? el.sum : "-",
    sumMarginTransferPrice:
      el.sumMarginTransferPrice !== null ? el.sumMarginTransferPrice : "-",
    totalityProjectProfitControl:
      el.totalityProjectProfitControl !== null
        ? el.totalityProjectProfitControl
        : "-",
    userName: el.userName !== null ? el.userName : "-",
  }));
  const totalItems = data?.totalItems;

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      closedAtDateFrom:
        data.closedAtDateFrom !== ""
          ? dayjs(data.closedAtDateFrom).format("YYYY-MM-DD")
          : "",
      closedAtDateTo:
        data.closedAtDateTo !== ""
          ? dayjs(data.closedAtDateTo).format("YYYY-MM-DD")
          : "",
      creatorName: data.creatorName,
      customerName: data.customerName,
      maxSum: data.maxSum,
      maxSumMarginTransferPrice: data.maxSumMarginTransferPrice,
      maxTotalityProjectProfitControl: data.maxTotalityProjectProfitControl,
      minSum: data.minSum,
      minSumMarginTransferPrice: data.minSumMarginTransferPrice,
      minTotalityProjectProfitControl: data.minTotalityProjectProfitControl,
      offerNumber: data.offerNumber,
      regionUnit: data.regionUnit,
    });
    getData();
    setDrawer(false);
  });

  const handleFilterClear = () => {
    reset();
  };

  const columns: TableColumnType<any>[] = [
    {
      title: "Project Number",
      dataKey: "projectNumber",
      width: 100,
      sort: true,
    },

    {
      title: "Closed At",
      dataKey: "closedAt",
      width: 100,
      sort: true,
    },
    {
      title: "Creator Name",
      dataKey: "userName",
      width: 100,
      sort: true,
    },
    {
      title: "Custormer Name",
      dataKey: "contractorName",
      width: 100,
      sort: true,
    },
    {
      title: "Region",
      dataKey: "regionUnit",
      width: 100,
      sort: true,
    },
    {
      title: "Project Value",
      dataKey: "sum",
      width: 100,
      sort: true,
    },
    {
      title: "Project Profit Control",
      dataKey: "totalityProjectProfitControl",
      width: 100,
      sort: true,
    },
    {
      title: "Margin Transfer Price",
      dataKey: "sumMarginTransferPrice",
      width: 100,
      sort: true,
    },
  ];

  const handleGenerateReport = () => {
    setGenerateReport(true);
  };

  const handleCloseModal = (fn: any) => {
    fn(false);
  };

  return (
    <ComponentCard>
      <TableComponent
        columns={columns}
        data={projectReportData}
        onClickButtonVoid={handleGenerateReport}
        onClickButtonVoidText="Generate Report"
        noSearch
        //pagination
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        page={page}
        totalItems={totalItems}
        filter={() => setDrawer(true)}
        //sorting
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <GenerateProjectsReport
        isOpen={generateReport}
        handleModalClose={() => handleCloseModal(setGenerateReport)}
      />
      <DrawerComponent drawer={drawer} setDrawer={setDrawer}>
        <Box sx={{ mx: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Filter</h3>
            <IconButton onClick={() => setDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <form>
              <DatePicker
                fullWidth
                name="closedAtDateFrom"
                control={control}
                label="Closed At Date From: "
              />
              <DatePicker
                fullWidth
                name="closedAtDateTo"
                control={control}
                label="Closed At Date To: "
              />
              <Input
                name="creatorName"
                control={control}
                label="Creator Name"
              />
              <Input
                name="customerName"
                control={control}
                label="Customer Name"
              />
              <Input
                name="maxSum"
                control={control}
                label="Max Project Value"
              />
              <Input
                name="maxSumMarginTransferPrice"
                control={control}
                label="Max Margin Transfer Price"
              />
              <Input
                name="maxTotalityProjectProfitControl"
                control={control}
                label="Max Project Profit Control"
              />
              <Input
                name="minSum"
                control={control}
                label="Min Project Value"
              />
              <Input
                name="minSumMarginTransferPrice"
                control={control}
                label="Min Margin Transfer Price"
              />
              <Input
                name="minTotalityProjectProfitControl"
                control={control}
                label="Min Project Profit Control"
              />
              <Input
                name="offerNumber"
                control={control}
                label="Project Number"
              />
              <Input name="regionUnit" control={control} label="Region" />
            </form>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button color="primary" onClick={handleFilterClear}>
              Clear
            </Button>
            <Button color="primary" onClick={handleFilterSubmit}>
              Confirm
            </Button>
          </Box>
        </Box>
      </DrawerComponent>
    </ComponentCard>
  );
};

export default ProjectsReport;
