import React from "react";
import { Controller } from "react-hook-form";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { TextField } from "@mui/material";

interface Props {
  control: any;
  name: string;
  label?: string;
  fullWidth?: boolean;
}

const DatePicker = ({ control, label, name, fullWidth }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, onChange, value, ...rest } }) => (
          <DesktopDatePicker
            label={label}
            inputFormat="yyyy-MM-dd"
            value={value || null}
            onChange={onChange}
            disableMaskedInput={true}
            renderInput={(params: any) => (
                <TextField {...params} variant="standard" fullWidth={fullWidth} />
            )}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
