import {
  Alert,
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import React, {useMemo, useEffect, useState, useCallback} from "react";
import { useForm } from "react-hook-form";
import Input from "../inputs/Input";
import ModalComponent from "../Modal";
import axios from "axios";
// import { productTableColumns } from "./configuration/productTableColumns";
import AddProductsModal from "./AddProductsModal";
import { toast } from "react-toastify";
import SearchSelect from "../inputs/SearchSelect";
import AddProductsFromCSVModal from "./AddProductsFromCSVModal";
import useTablePagination from "../table/hooks/useTablePagination";
import useTableSorting from "../table/hooks/useTableSorting";
import EditProductModal from "./EditProductModal";
import { TableColumnType } from "./table/types";
import FloatingMenu from "../floatingMenu";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "../../hooks/useAuth";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatValueToPLN } from "../helpers/currencyFormatter";
import AddProjectTableComponent from "./table/AddProjectTable";
import usePriceListProducts from "./hooks/usePriceListProducts";
import useGetCompetition from "./hooks/useGetCompetition";
import {
  getDiscountFromPL,
  getFunPriceProfitControl, getFunPriceSalesman, getMarginTransferPrice,
  getProfitProjectControl,
  getSalesmanProfit, getValue
} from "./tools/productCalculator";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  getProjectData: () => void;
}

const AddProjectModal = ({
  isOpen,
  handleModalClose,
  getProjectData,
}: Props) => {
  const { competition } = useGetCompetition();
  const [addProducts, setAddProducts] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [editData, setEditData] = useState([]);
  const [addProductsFromCSV, setAddProductsFromCSV] = useState(false);
  const [contractors, setContractors] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const tablePagination = useTablePagination();
  const tableSorting = useTableSorting("id");
  const [avaliablePriceLists, setAvaliablePriceLists] = useState<any[]>([]);
  const [currentPriceListId, setCurrentPriceListId] = useState<Number | null>(
    null
  );
  const [customer, setCustomer] = useState<any>();

  const auth = useAuthContext();
  const { userInfo } = auth;
  const userRole = userInfo?.role;

  const { priceListProducts } = usePriceListProducts(currentPriceListId);

  const totalValue = products
    .reduce((acc: any, curr: any) => {
      return acc + curr.value;
    }, 0)
    .toFixed(2);

  const totalMarginTransfer = products
    .reduce((acc: any, curr: any) => {
      return acc + curr.marginTransferPrice;
    }, 0)
    .toFixed(2);

  const totalProjectControl = products
    .reduce((acc: any, curr: any) => {
      return acc + curr.profitProjectControl;
    }, 0)
    .toFixed(2);

  const totalProjectSalesman = products
    .reduce((acc: any, curr: any) => {
      return acc + curr.salesmanProfit;
    }, 0)
    .toFixed(2);

  const totalMarginValue = useMemo(() => {
    return products.reduce((acc: any, curr: any) => {
      return acc + curr.transferPrice;
    }, 0);
  }, [products]).toFixed(2);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/app/customer/get/all`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const contractors = res.data.map(
          ({ customerName, innerIdCode, city, region, id, category }: any) => {
            return {
              id,
              label:
                innerIdCode + ", " + customerName + ", " + city + ", " + region,
              value: id,
              category: category
            };
          }
        );
        setContractors(contractors);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/get/newest_price_lists`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const priceListsOptions = res.data.priceLists.map(
          ({ id, name }: any) => {
            return {
              id,
              label: name,
              value: id,
            };
          }
        );
        setAvaliablePriceLists(priceListsOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      sapOfferNumber: "",
      customerId: "",
      additionalInfo: "",
      currentPriceList: avaliablePriceLists[0]?.value,
    },
  });


  useEffect(() => {
    if (products !== undefined) {
      let sortedData = products.sort((a: any, b: any) => {
        if (tableSorting.sortDirection === 'ASC') {
          if (tableSorting.sortField === 'competitor' || tableSorting.sortField === 'orderCode') {
            return a[tableSorting.sortField] < b[tableSorting.sortField] ? -1 : 1;
          } else {
            return a[tableSorting.sortField] - b[tableSorting.sortField];
          }
        } else {
          if (tableSorting.sortField === 'competitor' || tableSorting.sortField === 'orderCode') {
            return a[tableSorting.sortField] > b[tableSorting.sortField] ? -1 : 1;
          } else {
            return b[tableSorting.sortField] - a[tableSorting.sortField];
          }
        }
      });
      setProducts(JSON.parse(JSON.stringify(sortedData)));
    }
  }, [tableSorting.sortField, tableSorting.sortDirection]);


  const onSubmit = handleSubmit((data) => {
    let valid = true
    let errorMessage = '';
    products.forEach((product : any) => {
      if (product.quantity < 1) {
        valid = false;
        errorMessage = 'The quantity of the product cannot be less than 1';
      }
      if (!Number.isInteger(Number(product.quantity))) {
        valid = false;
        errorMessage = 'The quantity must be an integer';
      }
    })

    if (!valid) {
      toast.error(errorMessage);
      return;
    }
    const postData = {
      ...data,
      offeredProducts: products,
      margin: totalMarginValue,
      priceListId: currentPriceListId,
      sum: totalValue,
      sumMarginTransferPrice: totalMarginTransfer,
      totalityProjectProfitControl: totalProjectControl,
      totalityProjectProfitSalesman: totalProjectSalesman,
    } as any;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/app/offer/newofferandproducts`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Project added successfully");
        handleModalClose();
        getProjectData();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  });

  const handleOpen = (fn: any, data?: any) => {
    fn(true);
    if (data) {
      setEditData(data);
    }
  };

  const handleDeleteProduct = (data?: any) => {

    const newList = products.filter((item: any) => {
      return item.local_id !== data.local_id;
    });

    setProducts(newList);

  };



  const priceListProductsOptions = priceListProducts.map(
      ({ id, orderCode }: any) => {
        return {
          id,
          label: orderCode,
          value: orderCode,
        };
      }
  );

  const getProductDiscounts = useCallback((orderCode: string) => {
        const productDiscounts = priceListProducts.find(
            (p: any) => p.orderCode === orderCode
        );
        // return productDiscounts?.productDiscounts;

        return productDiscounts?.productDiscounts.find(
            (categoryDiscount: any) => categoryDiscount.category == customer.category
        );
      },
      [priceListProducts]
  );

  const productTableColumns: TableColumnType<any>[] = [
    {
      title: "",
      dataKey: "editRow",
      align: "left",
      sort: false,
      width: 50,
      editable: false
    },
    {
      title: "Order Code",
      dataKey: "orderCode",
      align: "left",
      sort: true,
      width: 100,
      editable: false,
      inputType: "autocomplete"
    },
    {
      title: "Project Price",
      dataKey: "projectPrice",
      align: "center",
      sort: true,
      width: 150,
      editable: true,
      inputType: "number"
    },
    {
      title: "Price List Price",
      dataKey: "priceListPrice",
      width: 100,
      align: "center",
      editable: false
    },
    {
      title: "Quantity",
      dataKey: "quantity",
      align: "center",
      sort: true,
      width: 50,
      editable: true,
      inputType: "number"
    },
    {
      title: "Value",
      dataKey: "value",
      align: "center",
      sort: true,
      width: 100,
      editable: false
    },
    {
      title: "Profit project control",
      dataKey: "profitProjectControl",
      width: 100,
      align: "center",
      editable: false
    },
    {
      title: "Salesman Profit",
      width: 100,
      align: "center",
      dataKey: "salesmanProfit",
      editable: false
    },
    {
      title: "Discount from PL",
      dataKey: "discountFromPL",
      align: "center",
      sort: true,
      width: 150,
      editable: false
    },
    {
      title: "Competitor",
      dataKey: "competitor",
      align: "center",
      sort: true,
      width: 40,
      editable: true,
      inputType: "select"
    },
    {
      title: "Competitor Price",
      dataKey: "competitorPrice",
      align: "center",
      sort: true,
      width: 150,
      editable: true,
      inputType: "number"
    },
    {
      title: "Actions",
      width: 10,
      align: "center",
      sort: false,
      editable: false,
      children: (item) => {
        if (item.isNew) {
          return (
              <FloatingMenu
                  menuItems={[
                    <MenuItem key={5} onClick={() => handleDeleteProduct(item)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Delete product" />
                    </MenuItem>,
                  ]}
              />
          )
        }
        return (
            <FloatingMenu
                menuItems={[
                    <MenuItem key={1} onClick={() => handleOpen(setEditProduct, item)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit" />
                    </MenuItem>,
                    <MenuItem key={5} onClick={() => handleDeleteProduct(item)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Delete product" />
                    </MenuItem>,
                  ]}
              />
          )
      }
    },
  ];

  const handleSelectContractor = (val: any) => {
    const contractor = contractors.find(
      (c: { id: any }) => c.id === val
    );
    if (contractor) {
      setCustomer(contractor);
    }
  }


  const addRow = () => {
    let id = Math.random().toString(16).slice(2);
    products.push({
      "materialDescription": null,
      "orderCode": null,
      "projectPrice": null,
      "quantity": null,
      "value": null,
      "salesmanProfit": null,
      "profitProjectControl": null,
      "pricelistPrice": null,
      "discountFromPL": null,
      "funpriceSalesman": null,
      "funpriceProfitControl": null,
      "competitor": null,
      "competitorPrice": null,
      "transferPrice": null,
      "marginTransferPrice": null,
      // "id": id,
      "local_id": id,
      "isEdited": true,
      "isNew": true
    });
    setProducts(
        products.map((row: any) => {
          return row;
        })
    );
  }


  const dataChanged = (changedData: any) => {
    let productDiscounts = getProductDiscounts(changedData.orderCode);
    console.log("=== DATA CHANGED ===");
    console.log(productDiscounts);
    let salesmanProfitAmount = Number(productDiscounts?.discountSMMax);
    let allowedProfitForGroup = Number(productDiscounts?.discountTLMax);
    setProducts(
        products.map((row: any) => {
          if (row.local_id === changedData.local_id) {
            if (changedData.key === 'orderCode') {
              // usuwamy wybrany order code
              if (changedData.value === undefined) {
                row.materialDescription = null;
                row.priceListPrice = 0.0;
                row.transferPrice = 0.0;
                row.profitProjectControl = 0.0;
                row.funpriceSalesman = 0.0;
                row.salesmanProfit = 0.0;
                row.funpriceProfitControl = 0.0;
                row.marginTransferPrice = 0.0;
                row.discountFromPL = 0.0;
                row.salesmanProfit = 0.0;
                row.profitProjectControl = 0.0;
                row.discountFromPL = 0.0;
                row.baseProductId = undefined;
              } else {
                let dataToFill: any = priceListProducts.find(
                    (p: any) => p.orderCode === changedData.value
                );
                row.materialDescription = dataToFill?.materialDescription;
                row.priceListPrice = dataToFill?.priceListPrice;
                row.transferPrice = dataToFill?.transferPrice;
                row.baseProductId = dataToFill?.id;
                row.salesmanProfit = getSalesmanProfit(row.value, row.priceListPrice, row.quantity, salesmanProfitAmount);
                row.profitProjectControl = getProfitProjectControl(row.value, row.priceListPrice, row.quantity, allowedProfitForGroup);
                row.discountFromPL = getDiscountFromPL(row.priceListPrice, row.projectPrice);
                row.funpriceProfitControl = getFunPriceProfitControl(row.priceListPrice, allowedProfitForGroup);
                row.funpriceSalesman = getFunPriceSalesman(row.priceListPrice, salesmanProfitAmount);
                row.marginTransferPrice = getMarginTransferPrice(row.projectPrice, row.transferPrice, row.quantity);
              }

            }
            row[changedData.key] = changedData.value;
            if (changedData.key === 'projectPrice') {
              row.projectPrice = changedData.value;
              row.value = getValue(changedData.value, row.quantity);
              if (row.priceListPrice > 0) {
                row.salesmanProfit = getSalesmanProfit(row.value, row.priceListPrice, row.quantity, salesmanProfitAmount);
                row.profitProjectControl = getProfitProjectControl(row.value, row.priceListPrice, row.quantity, allowedProfitForGroup);
                row.funpriceProfitControl = getFunPriceProfitControl(row.priceListPrice, allowedProfitForGroup);
                row.funpriceSalesman = getFunPriceSalesman(row.priceListPrice, salesmanProfitAmount);
                row.discountFromPL = getDiscountFromPL(row.priceListPrice, row.projectPrice);
                row.marginTransferPrice = getMarginTransferPrice(row.projectPrice, row.transferPrice, row.quantity);
              }
            }
            if (changedData.key === 'quantity') {
              row.quantity = changedData.value;
              row.value = getValue(row.projectPrice, row.quantity);
              if (row.priceListPrice > 0) {
                row.salesmanProfit = getSalesmanProfit(row.value, row.priceListPrice, row.quantity, salesmanProfitAmount);
                row.profitProjectControl = getProfitProjectControl(row.value, row.priceListPrice, row.quantity, allowedProfitForGroup);
                row.marginTransferPrice = getMarginTransferPrice(row.projectPrice, row.transferPrice, row.quantity);
              }
            }
          }
          return row;
        })
    );
  }

  const cancelEdit = (rowData: any) => {
    if (rowData.isNew) {
      setProducts(
          products.filter((row: any) => row.local_id !== rowData.local_id)
      );
    } else {
      let previousRow: any = null;
      // previousData.forEach((object : any, index: number) => {
      //   if (object.id === rowData.id) {
      //     console.log('===== mam poprzedn wiersz');
      //     previousRow = JSON.parse(JSON.stringify(object));
      //     console.log(previousRow);
      //   }
      // });

      setProducts(
          products.map((row: any) => {
            if (row.local_id === row.local_id && previousRow !== null) {
              row.isEdited = false;
              row = previousRow;
            }
            return row;
          })
      );
    }
  }

  const saveRowData = (rowData: any) => {
    let valid = true
    let errorMessage = '';
    if (rowData.quantity < 1) {
      valid = false;
      errorMessage = 'The quantity of the product cannot be less than 1';
    }
    if (!Number.isInteger(Number(rowData.quantity))) {
      valid = false;
      errorMessage = 'The quantity must be an integer';
    }
    if (Number(rowData.projectPrice) <= 0) {
      valid = false;
      errorMessage = 'The project price cannot be 0 or less';
    }
    if (rowData.orderCode === undefined || rowData.orderCode === null) {
      valid = false;
      errorMessage = 'Order code must be set';
    }
    if (!valid) {
      toast.error(errorMessage);
      return;
    }

    setProducts(
        products.map((row: any) => {
          if (row.local_id === rowData.local_id) {
            row.isEdited = false;
            row.isNew = false;
          }
          return row;
        })
    );
  }

  const productsFormCSV = (rowsData: any[]) => {
    rowsData.forEach((rowData : any) => {
      rowData.local_id = Math.random().toString(16).slice(2);
      products.push(rowData);
    });
    setProducts(JSON.parse(JSON.stringify(products)));
  }

  const editRow = (rowData: any) => {
    setProducts(
        products.map((row: any) => {
          if (row.local_id === rowData.local_id) {
            row.isEdited = true;
            row.isNew = false;
          }
          return row;
        })
    );
  }


  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={
        !addProducts && !addProductsFromCSV && !editProduct
          ? handleModalClose
          : () => {}
      }
      title = {addProducts && "Add Product" || editProduct && "Edit Product" || addProductsFromCSV && "Import Products" || "Add Project"}
      w="xl"
      dialogActions={
        addProducts || addProductsFromCSV || editProduct ? null : (
          <Box display="flex" sx={{ p: 2 }}>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={onSubmit}>Save</Button>
          </Box>
        )
      }
    >
      <Box sx={{ width: "100%", overflowX: "scroll", px: 1 }}>
        {!addProducts && !addProductsFromCSV && !editProduct ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box width={"60%"}>
              <form>
                <Input
                  control={control}
                  name="sapOfferNumber"
                  label="SAP Offer Number"
                  required
                />
                <SearchSelect
                  options={contractors}
                  label="Customer"
                  name="customerId"
                  control={control}
                  setSelectedValue={handleSelectContractor}
                  selectedValue={customer && customer.label}
                  required
                />
                <Input
                  control={control}
                  name="additionalInfo"
                  label="Additional Info"
                />
                {currentPriceListId ? (
                  <Alert severity="info">Price List selected : {avaliablePriceLists?.filter(i => i.id == currentPriceListId)[0]?.label} </Alert>
                ) : (
                  <SearchSelect
                    control={control}
                    name="currentPriceList"
                    label="Price List"
                    options={avaliablePriceLists}
                    setSelectedValue={setCurrentPriceListId}
                    required
                  />
                )}
              </form>
            </Box>
            <Box mt={5} width="100%">
              <Grid container justifyContent="center">
                <Grid item xs={2.2}>
                  <Typography variant="h6">Total Project Value</Typography>
                </Grid>
                <Grid item xs={2.2}>
                  <Typography variant="h6">
                    Total Profit Project Control
                  </Typography>
                </Grid>
                <Grid item xs={2.2}>
                  <Typography variant="h6">Total Salesman Profit</Typography>
                </Grid>
                {userRole !== "USER" && (
                  <>
                    <Grid item xs={2.2}>
                      <Typography variant="h6">
                        Total Margin Transfer
                      </Typography>
                    </Grid>
                    <Grid item xs={2.2}>
                      <Typography variant="h6">Total Transfer Price</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container mt={3} mb={3} justifyContent="center">
                <Grid item xs={2.2}>
                  {formatValueToPLN(totalValue)}
                </Grid>
                <Grid item xs={2.2}>
                  {formatValueToPLN(totalProjectControl)}
                </Grid>
                <Grid item xs={2.2}>
                  {formatValueToPLN(totalProjectSalesman)}
                </Grid>
                {userRole !== "USER" && (
                  <>
                    <Grid item xs={2.2}>
                      {formatValueToPLN(totalMarginTransfer)}
                    </Grid>
                    <Grid item xs={2.2}>
                      {formatValueToPLN(totalMarginValue)}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>



            <AddProjectTableComponent
              data={products}
              columns={productTableColumns}
              competitionData={competition}
              noSearch
              totalItems={products.length}
              {...tablePagination}
              {...tableSorting}
              addRowEnabled={!(currentPriceListId && customer)}
              addRow={() => addRow()}
              additionalActions={[
                {
                  text: "Add from CSV",
                  action: () => setAddProductsFromCSV(true),
                  // disabled: !!currentPriceListId,
                  disabled: !!currentPriceListId && customer,
                },
                {
                  text: "Add Product",
                  action: () => setAddProducts(true),
                  // disabled: !!currentPriceListId,
                  disabled: !!currentPriceListId && customer,
                },
              ]}
              orderCodeData={priceListProductsOptions}
              changeInputData={(changedData: any) => dataChanged(changedData)}
              onClickCancel={(row: any) => cancelEdit(row)}
              onClickSave={(row: any) => saveRowData(row)}
              onClickEdit={(row: any) => editRow(row)}
            />


          </Box>
        ) : addProducts ? (
          <AddProductsModal
            products={products}
            setAddProducts={setAddProducts}
            setProducts={setProducts}
            getData={getProjectData}
            currentPriceListId={currentPriceListId}
            customerId={customer && customer.value}
            customerCategory={customer && customer.category}
          />
        ) : editProduct ? (
          <EditProductModal
            isAddProject={true}
            addProjectData={products}
            editData={editData}
            setEditProduct={setEditProduct}
            getEditData={() => null}
            currentPriceListId={currentPriceListId}
            customerCategory={customer && customer.category}
          />
        ) : addProductsFromCSV ? (
          <AddProductsFromCSVModal
            userRole={userRole}
            close={() => setAddProductsFromCSV(false)}
            currentPriceListId={currentPriceListId}
            lastLocalId= {products.length > 0 ? products[products.length - 1].local_id + 1 : 1}
            save={(newProducts) => productsFormCSV(newProducts)}
            customerCategory={customer && customer.category}
            // save={(newProducts) => setProducts([...products, ...newProducts])}
            // save={(newProducts) => console.log(newProducts)}
          />
        ) : null}
      </Box>
    </ModalComponent>
  );
};

export default AddProjectModal;
