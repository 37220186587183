import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button, InputLabel } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../inputs/Input';
import ModalComponent from '../Modal';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	rowData?: any;
	forceUpdate: () => void;
	pickedDiscountList?: any;
}

const DiscountListEditModal = ({
	isOpen,
	handleModalClose,
	rowData,
	forceUpdate,
	pickedDiscountList,
}: Props) => {
	const formatWithoutPercentage = (percentageNumber: any) => {
		const percentageIndex = percentageNumber?.indexOf('%');
		const pureNumber = percentageNumber?.slice(0, percentageIndex);
		return pureNumber;
	};

	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			discountGroup: rowData?.discountGroup,
			// discountISMax: formatWithoutPercentage(rowData?.discountISMax),
			// discountCSMMax: formatWithoutPercentage(rowData?.discountSMMaxC),
			// discountCTLMax: formatWithoutPercentage(rowData?.discountSMMaxC),
			// discountEUSMMax: formatWithoutPercentage(rowData?.discountSMMaxEU),
			// discountEUTLMax: formatWithoutPercentage(rowData?.discountSMMaxEU),
			// discountOEMSMMax: formatWithoutPercentage(rowData?.discountSMMaxOEM),
			// discountOEMTLMax: formatWithoutPercentage(rowData?.discountSMMaxOEM),
			// discountISMax: rowData?.discountISMax,
			discountCSMMax: rowData?.discountSMMaxC,
			discountCTLMax: rowData?.discountTLMaxC,
			discountEUSMMax: rowData?.discountSMMaxEU,
			discountEUTLMax: rowData?.discountTLMaxEU,
			discountOEMSMMax: rowData?.discountSMMaxOEM,
			discountOEMTLMax: rowData?.discountTLMaxOEM,
			productAreaCode: rowData?.productAreaCode,
			productAreaName: rowData?.productAreaName,
			productGroupCode: rowData?.productGroupCode,
			productGroupName: rowData?.productGroupName,
		},
	});

	useEffect(() => {
		reset({
			discountGroup: rowData.discountGroup,
			// discountISMax: formatWithoutPercentage(rowData.discountISMax),
			// discountSMMax: formatWithoutPercentage(rowData.discountSMMax),
			// discountTLMax: formatWithoutPercentage(rowData.discountTLMax),
			// discountCSMMax: formatWithoutPercentage(discountByCategory("C").discountCSMMax),
			// discountCTLMax: formatWithoutPercentage(discountByCategory("C").discountCTLMax),
			// discountEUSMMax: formatWithoutPercentage(discountByCategory("EU").discountCSMMax),
			// discountEUTLMax: formatWithoutPercentage(discountByCategory("EU").discountCTLMax),
			// discountOEMSMMax: formatWithoutPercentage(discountByCategory("OEM").discountCSMMax),
			// discountOEMTLMax: formatWithoutPercentage(discountByCategory("OEM").discountCTLMax),
			discountCSMMax: rowData?.discountSMMaxC,
			discountCTLMax: rowData?.discountTLMaxC,
			discountEUSMMax: rowData?.discountSMMaxEU,
			discountEUTLMax: rowData?.discountTLMaxEU,
			discountOEMSMMax: rowData?.discountSMMaxOEM,
			discountOEMTLMax: rowData?.discountTLMaxOEM,
			productAreaCode: rowData.productAreaCode,
			productAreaName: rowData.productAreaName,
			productGroupCode: rowData.productGroupCode,
			productGroupName: rowData.productGroupName,
		});
	}, [rowData]);

	const formatAsPercentage = (dataNumber: any) => {
		const result = `${dataNumber / 100}`;
		return result;
	};

	const onSubmit = handleSubmit((data: any) => {
		axios
			.put(
				`${process.env.REACT_APP_API_BASE_URL}/app/discount/edit?discountGroup=${rowData.discountGroup}&discountListId=${pickedDiscountList.id}
				`,
				{
					discountGroup: data.discountGroup,
					// discountISMax: formatAsPercentage(data.discountISMax),
					// discountSMMax: formatAsPercentage(data.discountSMMax),
					// discountTLMax: formatAsPercentage(data.discountTLMax),
					// discountCSMMax: formatWithoutPercentage(discountByCategory("C").discountCSMMax),
					// discountCTLMax: formatWithoutPercentage(discountByCategory("C").discountCTLMax),
					// discountEUSMMax: formatWithoutPercentage(discountByCategory("EU").discountCSMMax),
					// discountEUTLMax: formatWithoutPercentage(discountByCategory("EU").discountCTLMax),
					// discountOEMSMMax: formatWithoutPercentage(discountByCategory("OEM").discountCSMMax),
					// discountOEMTLMax: formatWithoutPercentage(discountByCategory("OEM").discountCTLMax),
					discountCategories: [
						{
							category: 'C',
							discountSMMax: data.discountCSMMax,
							discountTLMax: data.discountCTLMax,
						},
						{
							category: 'EU',
							discountSMMax: data.discountEUSMMax,
							discountTLMax: data.discountEUTLMax,
						},
						{
							category: 'OEM',
							discountSMMax: data.discountOEMSMMax,
							discountTLMax: data.discountOEMTLMax,
						}
					],
					productAreaCode: data.productAreaCode,
					productAreaName: data.productAreaName,
					productGroupCode: data.productGroupCode,
					productGroupName: data.productGroupName,
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Added successfully');
				forceUpdate();
				handleModalClose();
				reset();
			})
			.catch(err => {
				toast.error('Error occurred');
				console.log(err);
			});
	});

	return (
		<ModalComponent
			isOpen={isOpen}
			handleClose={handleModalClose}
			title="Edit discount in discount list"
			w="xl"
		>
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="discountGroup" label="Discount Group" control={control} />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productAreaCode" control={control} label="Product Area Code" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productAreaName" control={control} label="Product Area Name" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productGroupCode" control={control} label="Product Group Code" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productGroupName" control={control} label="Product Group Name" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<InputLabel>Category C</InputLabel>
					<Input
						name="discountCSMMax"
						control={control}
						label="Discount PL [Salesman Max] (%)"
					/>
					<Input
						name="discountCTLMax"
						control={control}
						label="Discount PL [Team Leader] (%)"
					/>
				</Box>
				<Box sx={{ width: '100%' }}>
					<InputLabel>Category EU</InputLabel>
					<Input
						name="discountEUSMMax"
						control={control}
						label="Discount PL [Salesman Max] (%)"
					/>
					<Input
						name="discountEUTLMax"
						control={control}
						label="Discount PL [Team Leader] (%)"
					/>
				</Box>
				<Box sx={{ width: '100%' }}>
					<InputLabel>Category OEM</InputLabel>
					<Input
						name="discountOEMSMMax"
						control={control}
						label="Discount PL [Salesman Max] (%)"
					/>
					<Input
						name="discountOEMTLMax"
						control={control}
						label="Discount PL [Team Leader] (%)"
					/>
				</Box>
				<Box></Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>

					<Button onClick={onSubmit}>Save</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default DiscountListEditModal;
