import { TableCell } from "@mui/material";
import { ReactNode } from "react";
import { formatValueToPLN } from "../helpers/currencyFormatter";
import { TableColumnType } from "./types";

interface CellProps<T> {
  children?: ReactNode;
  column: TableColumnType<T>;
  rowData?: T;
  rowIndex: number;
  sx?: object;
  onClick?: () => void;
}

/**
 * Array of project's attribute names to reformat and localize
 */
 const projectCurrencyTypeAttributes = [
  "competitorPrice",
  "funpriceProfitControl",
  "funpriceSalesman",
  "marginTransferPrice",
  "offeredPrice",
  "priceListPrice",
  "profitProjectControl",
  "projectPrice",
  "proposedPriceAccOne",
  "proposedPriceAccTwo",
  "salesmanProfit",
  "transferPrice",
  "value",
  "sum",
  "totalityProjectProfitControl",
  "sumMarginTransferPrice"
]

function Cell<T>({
  children: childrenProp,
  column,
  rowData,
  rowIndex,
  sx,
  onClick,
}: CellProps<T>) {

  const { children: render, dataKey, align = "left" as any, width } = column;

  let children;

  if (childrenProp) {
    children = childrenProp;
  } else if (rowData) {
    if (render) {
      children = render(rowData, rowIndex);
    } else if (dataKey) {
      if (projectCurrencyTypeAttributes.includes(String(dataKey))) {
        children = formatValueToPLN(rowData[dataKey]);
      } else {
        children = rowData[dataKey];
      }
    }
  }

  return (
    <TableCell
      align={align}
      sx={sx}
      style={{ width: width || 100 }}
      onClick={onClick}
    >
      {children}
    </TableCell>
  );
}

export default Cell;
