import { useEffect, useState } from "react";
import ComponentCard from "../ComponentCard";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import Input from "../inputs/Input";
import AddUserModal from "./AddUserModal";
import DeleteUserModal from "./DeleteUserModal";
import EditUserModal from "./EditUserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import useFetch from "../../hooks/useFetch";
import FloatingMenu from "../floatingMenu/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import DrawerComponent from "../DrawerComponent";
import SelectComponent from "../inputs/Select";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";

interface Props {}

const Users = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<any>({});
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [page, setPage] = useState<any>(0);
  const [sortField, setSortField] = useState<any>("firstName");
  const [sortDirection, setSortDirection] = useState<any>("ASC");
  const [regionOptions, setRegionOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  // handle closign FloatingMenu after MenuItem click
  const [closeMenu, setCloseMenu] = useState(false);

  const [drawer, setDrawer] = useState(false);
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    region: "",
    role: "",
  });

  const roleOpts = [
    {
      id: 0,
      value: "",
      label: "-",
    },
    {
      id: 1,
      value: "USER",
      label: "USER",
    },
    {
      id: 2,
      value: "ACCEPT1",
      label: "ACCEPT1",
    },
    {
      id: 3,
      value: "ACCEPT2",
      label: "ACCEPT2",
    },
    {
      id: 4,
      value: "ADMIN",
      label: "ADMIN",
    },
    {
      id: 5,
      value: "INACTIVE",
      label: "INACTIVE",
    },
  ];

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      userName: "",
      role: roleOpts[0].value,
      region: "",
    },
  });

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/user/get/p/sort/filter?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&firstNameFilter=${filters.firstName}&lastNameFilter=${filters.lastName}&userNameFilter=${filters.userName}&regionUnitFilter=${filters.region}&roleFilter=${filters.role}`
  );

  const usersData = data?.users;
  const totalItems = data?.totalItems;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/app/regionunit/get/ru`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const setUpOptions = res.data.map((data: string, index: Number) => {
          return {
            id: index,
            label: data,
            value: data,
          };
        });

        setRegionOptions(setUpOptions);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/app/user/getAllRoles`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const setUpRoles = res.data
          .filter((x: string) => x !== "INACTIVE" && x !== "ADMIN")
          .map((data: string, index: Number) => {
            return {
              id: index,
              label: data,
              value: data,
            };
          });

        setRoleOptions(setUpRoles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOpen = (fn: any, row?: any) => {
    setCloseMenu(true);

    fn(true);
    if (row) {
      setUserToEdit(row);
    }
  };

  const handleModalClose = (fn: any) => {
    setCloseMenu(false);
    fn(false);
  };

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      firstName: data.firstName,
      lastName: data.lastName,
      userName: data.userName,
      region: data.region,
      role: data.role,
    });
    //getData();
    setDrawer(false);
  });

  const columns: TableColumnType<any>[] = [
    // NOTE: we're sorting by FirstName
    {
      title: "Fullname",
      dataKey: "firstName",
      sort: true,
      width: 200,
      children: (row: any) => (
        <div>
          <Typography>
            {row.firstName} {row.lastName}
          </Typography>
          <Typography color="secondary.dark">{row.userName}</Typography>
        </div>
      ),
    },
    {
      title: "Role",
      dataKey: "role",
      sort: true,
      width: 200,
    },
    {
      title: "Region",
      dataKey: "region",
      sort: true,
      width: 100,
    },
    {
      title: "Actions",
      dataKey: "action",
      sort: false,
      width: 50,
      children: (row) => {
        return (
          <FloatingMenu
            close={closeMenu}
            menuItems={[
              <MenuItem key={1} onClick={() => handleOpen(setEditOpen, row)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </MenuItem>,
              <MenuItem key={2} onClick={() => handleOpen(setDeleteOpen, row)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary="Delete" />
              </MenuItem>,
              <MenuItem
                key={2}
                onClick={() => handleOpen(setResetPasswordOpen, row)}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Reset Password" />
              </MenuItem>,
            ]}
          />
        );
      },
    },
  ];

  return (
    <ComponentCard>
      <TableComponent
        columns={columns}
        data={usersData}
        onClickButtonVoid={() => handleOpen(setIsOpen)}
        noSearch={true}
        // data for pagination
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        page={page}
        totalItems={totalItems}
        // data for sorting
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        filter={() => setDrawer(true)}
      />
      <DrawerComponent drawer={drawer} setDrawer={setDrawer}>
        <form>
          <Box sx={{ mx: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Filter</h3>
              <IconButton onClick={() => setDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <form>
                <Input control={control} name="firstName" label="First Name" />
                <Input control={control} name="lastName" label="Last Name" />
                <Input control={control} name="userName" label="User Name" />
                <SelectComponent
                  control={control}
                  options={roleOpts}
                  name="role"
                  label="Role"
                  variant="filled"
                />
                <Input control={control} name="region" label="Region" />
              </form>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="primary"
                onClick={() =>
                  reset({
                    firstName: "",
                    lastName: "",
                    role: roleOpts[0].value,
                    region: "",
                  })
                }
              >
                Clear
              </Button>
              <Button color="primary" onClick={handleFilterSubmit}>
                Confirm
              </Button>
            </Box>
          </Box>
        </form>
      </DrawerComponent>
      <AddUserModal
        getData={getData}
        isOpen={isOpen}
        handleModalClose={() => handleModalClose(setIsOpen)}
        regionOptions={regionOptions}
        roleOptions={roleOptions}
      />
      <DeleteUserModal
        getData={getData}
        userId={userToEdit.userName}
        isOpen={isDeleteOpen}
        handleModalClose={() => handleModalClose(setDeleteOpen)}
      />
      <EditUserModal
        getData={getData}
        isOpen={isEditOpen}
        user={userToEdit}
        handleModalClose={() => handleModalClose(setEditOpen)}
        regionOptions={regionOptions}
        roleOptions={roleOptions}
      />
      <ResetPasswordModal
        getData={getData}
        isOpen={resetPasswordOpen}
        user={userToEdit}
        handleModalClose={() => handleModalClose(setResetPasswordOpen)}
      />
    </ComponentCard>
  );
};

export default Users;
