import { Paper, Box, Icon, Typography, IconButton } from '@mui/material';
import { InsertDriveFile, MoreVert } from '@mui/icons-material';
import FloatingMenu from '../floatingMenu';

interface Props {
	menuItems?: any[];
	name: string;
	desc: string;
	secondDesc?: string;
	userRole?: string;
}

const DictionaryCard = ({ menuItems, name, desc, secondDesc, userRole }: Props) => {
	return (
		<Box sx={{ width: '100%', m: 1 }}>
			<Paper
				sx={{
					p: 2,
					height: 250,
				}}
				elevation={3}
			>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					{userRole === 'ADMIN' ? (
						menuItems ? (
							<FloatingMenu menuItems={menuItems} />
						) : (
							<IconButton>
								<MoreVert />
							</IconButton>
						)
					) : (
						<Box sx={{ height: 40 }}></Box>
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Icon sx={{ mb: 3 }}>
						<InsertDriveFile />
					</Icon>
					<Typography variant="h5">{name}</Typography>
					<Typography variant="subtitle1">{desc}</Typography>
					<Typography variant="subtitle1" fontWeight="bold">
						{secondDesc}
					</Typography>
				</Box>
			</Paper>
		</Box>
	);
};

export default DictionaryCard;
