import React, { useEffect, useRef, useContext } from 'react';
import axios, { AxiosError } from 'axios';
import { Box, Typography, Button } from '@mui/material';
import useFetch from '../../hooks/useFetch';
import MessageContentCard from './MessageContentCard';
import MessageInput from './MessageInput';
import { MessagesContext } from './Messages';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

type Props = {
	id?: number;
};

const MessageBox = ({ id }: Props): JSX.Element => {
	const context = useContext(MessagesContext);
	const scrollRef = useRef<HTMLDivElement>(null);

	const { data, getData } = useFetch(
		`${process.env.REACT_APP_API_BASE_URL}/app/ticket/get/ticketid?ticketId=${id}`
	);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView();
		}
	}, [data?.ticketMessages]);

	const offerRefenceId = data?.offerReferenceId;

	const changeTicketStatus = async (newStatus: string): Promise<void> => {
		try {
			await axios.put(
				`${process.env.REACT_APP_API_BASE_URL}/app/ticket/change_status?ticketId=${id}&ticketStatus=${newStatus}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			);
			getData();
			context.loadTickets();
		} catch (err: any) {
			const message = err?.response?.data?.error;
			if (message) toast.error(message);
		}
	};

	const ActionButtons = (): JSX.Element => {
		const userInfo = sessionStorage.getItem('user')
			? JSON.parse(sessionStorage.getItem('user')!)
			: null;

		if (data.ticketStatus === 'NO_STATUS')
			return <Button onClick={() => changeTicketStatus('CLOSED')}>CLOSE</Button>;

		if (userInfo?.userName === data.creator.userName) return <></>;

		if (data.ticketStatus === 'IN_PROGRESS')
			return <Button onClick={() => changeTicketStatus('CLOSED')}>Close</Button>;

		if (data.ticketStatus === 'OPEN')
			return (
				<Button onClick={() => changeTicketStatus('IN_PROGRESS')}>Start progress</Button>
			);

		return <></>;
	};

	return (
		<Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
			<Box ml={1}>
				{data ? (
					<Box display="flex" flexDirection="row" justifyContent="space-between">
						<Box>
							<Typography variant="h6">{data.topic}</Typography>
							<Typography mb={3} variant="body2">
								Status: {data.ticketStatus}
							</Typography>
							{offerRefenceId != 0 && (
								<Button>
									<Link
										to={`/projects/info/${offerRefenceId}`}
										style={{ textDecoration: 'none', color: 'black' }}
									>
										Offer Info
									</Link>
								</Button>
							)}
						</Box>
						<ActionButtons />
					</Box>
				) : (
					<Box>
						<Typography variant="h6">Please Select Message</Typography>
					</Box>
				)}
			</Box>
			<Box sx={{ overflowY: 'scroll' }} height="100%">
				{data
					? data.ticketMessages.map((message: any) => (
							<MessageContentCard
								key={message.id}
								id={message.id}
								fullName={message.sender}
								date={message.createdAt}
								message={message.message}
							/>
					  ))
					: null}
				<div ref={scrollRef} />
			</Box>
			{data && data.ticketStatus !== 'CLOSED' && (
				<Box>
					<MessageInput ticketId={data.id} onMessageSent={getData} />
				</Box>
			)}
		</Box>
	);
};

export default MessageBox;
