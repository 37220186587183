import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import ModalComponent from "../Modal";
import SearchSelect from "../inputs/SearchSelect";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  pickedPriceList?: any;
  getNewestPriceList: () => void;
}

const ConnectPriceListModal = ({
  isOpen,
  handleClose,
  pickedPriceList,
  getNewestPriceList,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/discountlist/get/newest`
  );

  const discountLists = data?.discountLists?.map((el: any) => ({
    id: el.id,
    value: el.id,
    label: el.name,
  }));

  const { control, handleSubmit } = useForm<any>({
    defaultValues: {
      discounts: discountLists,
    },
  });

  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    const discountListId = {
      discounts: data.discounts,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/adddiscountlist?discountListId=${discountListId.discounts}&priceListId=${pickedPriceList.id}
				`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        handleClose();
        getNewestPriceList();
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      title="Add Discount List"
      isSmall
      dialogActions={<Box display="flex" sx={{ p: 2 }}></Box>}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        {loading ? (
          <CircularProgress disableShrink />
        ) : (
          <>
            <Typography variant="h5">Choose Discount List</Typography>
            <Box width="40%">
              <SearchSelect
                control={control}
                options={discountLists}
                name="discounts"
                label="Discount List"
                required
              />
            </Box>
            <Box display="flex" sx={{ p: 2 }}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={onSubmit}>Save</Button>
            </Box>
          </>
        )}
      </Box>
    </ModalComponent>
  );
};

export default ConnectPriceListModal;
