import { useEffect, useState } from "react";
import { Box, IconButton, Button } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Input from "../inputs/Input";
import DatePicker from "../inputs/DatePicker";
import SearchSelect from "../inputs/SearchSelect";
import ModalComponent from "../Modal";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
}

const GenerateProductsSumReports = ({ isOpen, handleModalClose }: Props) => {
  const valuesToExport = [
    {
      id: 0,
      value: "ORDER_CODE",
      label: "ORDER CODE",
    },
    {
      id: 1,
      value: "PAPG",
      label: "PAPG",
    },
    {
      id: 2,
      value: "COMPETITOR",
      label: "COMPETITOR",
    },
  ];
  const [sortField, setSortField] = useState<string>("id");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [filters, setFilters] = useState({
    valuesToExport: "",
    closedAtDateFrom: "",
    closedAtDateTo: "",
    competitorFilter: "",
    maxMarginTransferPriceFilter: "",
    maxProfitProjectControlFilter: "",
    maxQuantityFilter: "",
    minMarginTransferPriceFilter: "",
    minProfitProjectControlFilter: "",
    minQuantityFilter: "",
    offerNumberFilter: "",
    orderCodeFilter: "",
    papgFilter: "",
  });

  useEffect(() => {
    if (filters.valuesToExport !== "") {
      fetchReport();
    }
  }, [filters]);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      valuesToExport: "",
      closedAtDateFrom: "",
      closedAtDateTo: "",
      competitorFilter: "",
      maxMarginTransferPriceFilter: "",
      maxProfitProjectControlFilter: "",
      maxQuantityFilter: "",
      minMarginTransferPriceFilter: "",
      minProfitProjectControlFilter: "",
      minQuantityFilter: "",
      offerNumberFilter: "",
      orderCodeFilter: "",
      papgFilter: "",
    },
  });

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      valuesToExport: data.valuesToExport,
      closedAtDateFrom:
        data.closedAtDateFrom !== ""
          ? dayjs(data.closedAtDateFrom).format("YYYY-MM-DD")
          : "",
      closedAtDateTo:
        data.closedAtDateTo !== ""
          ? dayjs(data.closedAtDateTo).format("YYYY-MM-DD")
          : "",
      competitorFilter: data.competitorFilter,
      maxMarginTransferPriceFilter: data.maxMarginTransferPriceFilter,
      maxProfitProjectControlFilter: data.maxProfitProjectControlFilter,
      maxQuantityFilter: data.maxQuantityFilter,
      minMarginTransferPriceFilter: data.minMarginTransferPriceFilter,
      minProfitProjectControlFilter: data.minProfitProjectControlFilter,
      minQuantityFilter: data.minQuantityFilter,
      offerNumberFilter: data.offerNumberFilter,
      orderCodeFilter: data.orderCodeFilter,
      papgFilter: data.papgFilter,
    });
    reset();
    handleModalClose();
  });

  const fetchReport = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/app/report/export/reportProductSum/sort/filter?${
          filters.closedAtDateFrom !== ""
            ? `closedAtDateFrom=${filters.closedAtDateFrom}`
            : ""
        }${
          filters.closedAtDateTo !== ""
            ? `&closedAtDateTo=${filters.closedAtDateTo}`
            : ""
        }${
          filters.competitorFilter !== ""
            ? `&competitorFilter=${filters.competitorFilter}`
            : ""
        }${
          filters.maxMarginTransferPriceFilter !== ""
            ? `&maxMarginTransferPriceFilter=${filters.maxMarginTransferPriceFilter}`
            : ""
        }${
          filters.maxProfitProjectControlFilter !== ""
            ? `&maxProfitProjectControlFilter=${filters.maxProfitProjectControlFilter}`
            : ""
        }${
          filters.maxQuantityFilter !== ""
            ? `&maxQuantityFilter=${filters.maxQuantityFilter}`
            : ""
        }${
          filters.minMarginTransferPriceFilter !== ""
            ? `&minMarginTransferPriceFilter=${filters.minMarginTransferPriceFilter}`
            : ""
        }${
          filters.minProfitProjectControlFilter !== ""
            ? `&minProfitProjectControlFilter=${filters.minProfitProjectControlFilter}`
            : ""
        }${
          filters.minQuantityFilter !== ""
            ? `&minQuantityFilter=${filters.minQuantityFilter}`
            : ""
        }${
          filters.offerNumberFilter !== ""
            ? `&offerNumberFilter=${filters.offerNumberFilter}`
            : ""
        }${
          filters.orderCodeFilter !== ""
            ? `&orderCodeFilter=${filters.orderCodeFilter}`
            : ""
        }
				${
          filters.papgFilter !== "" ? `papgFilter=${filters.papgFilter}` : ""
        }&sortBy=${sortField}&sortDirection=${sortDirection}&sumBy=${
          filters.valuesToExport
        }
				`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, "Report.xlsx");
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
    handleModalClose();
  };

  const handleFilterClear = () => {
    reset();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title="Generate Products Sum Report"
      w="xl"
    >
      <Box sx={{ mx: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Filter</h3>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <form>
            <SearchSelect
              control={control}
              options={valuesToExport}
              name="valuesToExport"
              label="Sum By"
              required
            />
            <DatePicker
              fullWidth
              name="closedAtDateFrom"
              control={control}
              label="Closed At Date From: "
            />
            <DatePicker
              fullWidth
              name="closedAtDateTo"
              control={control}
              label="Closed At Date To: "
            />
            <Input
              name="competitorFilter"
              control={control}
              label="Competitor"
            />
            <Input
              name="maxMarginTransferPriceFilter"
              control={control}
              label="Max Margin Transfer Price"
            />
            <Input
              name="maxProfitProjectControlFilter"
              control={control}
              label="Max Profit Project Control"
            />
            <Input
              name="maxQuantityFilter"
              control={control}
              label="Max Quantity"
            />
            <Input
              name="minMarginTransferPriceFilter"
              control={control}
              label="Min Margin Transger Price"
            />
            <Input
              name="minProfitProjectControlFilter"
              control={control}
              label="Min Profit Project Control"
            />
            <Input
              name="minQuantityFilter"
              control={control}
              label="Min Quantity"
            />
            {/* <Input
              name="offerNumberFilter"
              control={control}
              label="Offer Number"
            /> */}
            <Input
              name="orderCodeFilter"
              control={control}
              label="Order Code"
            />
            <Input name="papgFilter" control={control} label="PAPG" />
          </form>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button color="primary" onClick={handleFilterClear}>
            Clear
          </Button>
          <Button color="primary" onClick={handleFilterSubmit}>
            Confirm
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default GenerateProductsSumReports;
