import {
    Alert,
    Box,
    Button,
    Grid,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography
} from "@mui/material";

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import Input from "../inputs/Input";
import ModalComponent from "../Modal";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import TableComponent from "../tableEditable/Table";
import {TableColumnType} from "../tableEditable/types";
import AddProductsModal from "./AddProductsModal";
import SearchSelect from "../inputs/SearchSelect";
import FloatingMenu from "../floatingMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {toast} from "react-toastify";
import AddProductsFromCSVModal from "./AddProductsFromCSVModal";
import {AddNewProductsToOffer} from "./api/OfferedProduct.Api";
import dayjs from "dayjs";
import {useAuthContext} from "../../hooks/useAuth";
import EditProductModal from "./EditProductModal";
import useGetCompetition from "./hooks/useGetCompetition";

import useTablePagination from "../table/hooks/useTablePagination";
import useTableSorting from "../tableEditable/hooks/useTableSorting";
import {formatValueToPLN} from "../helpers/currencyFormatter";
import {array} from "yup";
import {
    getDiscountFromPL,
    getFunPriceProfitControl, getFunPriceSalesman, getMarginTransferPrice,
    getProfitProjectControl,
    getSalesmanProfit,
    getValue
} from "./tools/productCalculator";
import usePriceListProducts from "./hooks/usePriceListProducts";


interface ProjectEditFields {
    id: number;
    projectNumber: string;
    contractorNumber: string;
    contractorName: string;
    additionalInfo: string;
    offerDateInfo: any;
}

interface Props {
    isOpen: boolean;
    handleModalClose: () => void;
    getEditData: () => void;
    project: ProjectEditFields;
}

const EditProjectModal = ({
                              isOpen,
                              handleModalClose,
                              getEditData,
                              project,
                          }: Props) => {
    const {
        id,
        contractorName,
        additionalInfo,
        projectNumber,
        contractorNumber,
    } = project;
    const {competition} = useGetCompetition();
    const [offerBasicInfo, setOfferBasicInfo] = useState<any>();
    const [priceListName, setPriceListName] = useState<any>();
    const [priceListId, setPriceListId] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [addProducts, setAddProducts] = useState(false);
    const [addProductsFromCSV, setAddProductsFromCSV] = useState(false);

    //ignore this is only because in other component setProducts can't be undefined
    const [, setProducts] = useState<any>([]);

    const [customers, setCustomers] = useState<any[]>([]);
    const [selectedCustomer, setSelectedCustomers] = useState<any>([]);
    const [editProduct, setEditProduct] = useState<any>(false);
    const [editData, setEditData] = useState<any>();
    const tableSorting = useTableSorting("id");
    const updatedAt = project.offerDateInfo.updatedAt;

    //const [rowEdit, setRowEdit] = useState<any>();
    // const [rowsEditStatus, setRowsEditStatus] = useState<Map<number, boolean>>(new Map());

    const auth = useAuthContext();
    const {userInfo} = auth;
    const userRole = userInfo?.role;

    const [data, setData] = useState<any>();
    const [previousData, setPreviousData] = useState<any>();

    const getData = useCallback((data?: any, sortField?: string, sortDirection?: string) => {
        if (sortField === undefined) {
            sortField = tableSorting.sortField;
        }
        if (sortDirection === undefined) {
            sortDirection = tableSorting.sortDirection;
        }
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/get/sort/offer?offerId=${id}&sortBy=${sortField}&sortDirection=${sortDirection}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                let newData: any[] = [];
                if (data !== undefined) {
                    data.forEach((oldData: any) => {
                        if (oldData.isNew) {
                            newData.push(oldData);
                        }
                    });
                }

                response.data.forEach((rowData: any) => {
                    rowData.isEdited = false;
                    rowData.isNew = false;
                    if (data !== undefined) {
                        if (data.length > 0) {
                            data.forEach((oldData: any) => {
                                if (rowData.id === oldData.id) {
                                    rowData.isEdited = oldData.isEdited;
                                }
                            });
                        }
                    }
                });

                newData.forEach((newRow: any) => {
                    response.data.push(newRow);
                });
                setData(response.data);
                setPreviousData(JSON.parse(JSON.stringify(response.data)));
            })
            .catch((err) => {
                //setError(err as any);
            });
    }, []);

    useEffect(() => {
        getData(data);
    }, [getData]);

    useEffect(() => {
        if (data !== undefined) {
            let sortedData = data.sort((a: any, b: any) => {
                if (tableSorting.sortDirection === 'ASC') {
                    if (tableSorting.sortField === 'competitor' || tableSorting.sortField === 'orderCode') {
                        return a[tableSorting.sortField] < b[tableSorting.sortField] ? -1 : 1;
                    } else {
                        return a[tableSorting.sortField] - b[tableSorting.sortField];
                    }
                } else {
                    if (tableSorting.sortField === 'competitor' || tableSorting.sortField === 'orderCode') {
                        return a[tableSorting.sortField] > b[tableSorting.sortField] ? -1 : 1;
                    } else {
                        return b[tableSorting.sortField] - a[tableSorting.sortField];
                    }
                }
            });
            setData(JSON.parse(JSON.stringify(sortedData)));
        }
    }, [tableSorting.sortField, tableSorting.sortDirection]);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/app/offer/get/offerId?offerId=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setOfferBasicInfo(res.data.offerBasicInfo);
                setPriceListName(res.data.offerBasicInfo.priceListName);
                // console.log('ustawima value na: ' + res.data.offerBasicInfo.additionalInfo);
                setValue('additionalInfo', res.data.offerBasicInfo.additionalInfo);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    const {priceListProducts} = usePriceListProducts(priceListId);

    const getProductDiscounts = useCallback((orderCode: string) => {
            const productDiscounts = priceListProducts.find(
                (p: any) => p.orderCode === orderCode
            );

            // return productDiscounts?.productDiscounts;

            return productDiscounts?.productDiscounts.find(
                (categoryDiscount: any) => categoryDiscount.category == selectedCustomer.category
            );
        },
        [priceListProducts]
    );

    useEffect(() => {
        if (priceListName !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/getByName?priceListName=${priceListName}`,
                    {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                        },
                    }
                )
                .then((res) => {
                    setPriceListId(res.data.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [priceListName]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/app/customer/get/all`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                const contractors = res.data.map(
                    ({customerName, innerIdCode, city, region, id, category}: any) => {
                        return {
                            id,
                            label:
                                customerName + ", " + innerIdCode + ", " + city + ", " + region,
                            value: id,
                            category: category
                        };
                    }
                );
                setCustomers(contractors);
                // const customerId = contractors.find((customer: any) =>
                //     customer.label.includes(contractorName)
                // )?.value;

                const customer = contractors.find((customer: any) =>
                    customer.label.includes(contractorName)
                );

                setSelectedCustomers(customer);
            })
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const saveData = (rowData: any) => {
        let rowToSave: any = null;
        data.forEach((object: any, index: number) => {
            if (object.id === rowData.id) {
                rowToSave = object;
            }
        });

        let valid = true
        let errorMessage = '';
        if (rowToSave.quantity < 1) {
            valid = false;
            errorMessage = 'The quantity of the product cannot be less than 1';
        }
        if (!Number.isInteger(Number(rowToSave.quantity))) {
            valid = false;
            errorMessage = 'The quantity must be an integer';
        }
        if (Number(rowToSave.projectPrice) <= 0) {
            valid = false;
            errorMessage = 'The project price cannot be 0 or less';
        }
        if (rowToSave.orderCode === undefined || rowToSave.orderCode === null) {
            valid = false;
            errorMessage = 'Order code must be set';
        }
        if (!valid) {
            toast.error(errorMessage);
            return;
        }

        if (rowData.isNew) {
            axios
                .post(
                    `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/add?offerId=${id}`,
                    [
                        {
                            baseProductId: rowToSave.baseProductId,
                            competitor: rowToSave.competitor,
                            competitorPrice: rowToSave.competitorPrice,
                            discountFromPL: rowToSave.discountFromPL,
                            funpriceProfitControl: rowToSave.funpriceProfitControl,
                            funpriceSalesman: rowToSave.funpriceSalesman,
                            marginTransferPrice: rowToSave.marginTransferPrice,
                            profitProjectControl: rowToSave.profitProjectControl,
                            projectPrice: rowToSave.projectPrice,
                            quantity: rowToSave.quantity,
                            salesmanProfit: rowToSave.salesmanProfit,
                            value: rowToSave.value,
                        },
                    ],
                    {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                        },
                    }
                )
                .then((res) => {
                    data.forEach((data: any) => {
                        if (rowToSave.id === data.id) {
                            data.isEdited = false;
                            data.isNew = false;
                        }
                    });
                    getData(data);
                    toast.success("Product added successfully");
                    // setAddProducts(false);
                })
                .catch((err) => {
                    toast.error("Something went wrong");
                    console.log(err);
                });
        } else {
            axios
                .put(
                    `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/update?offeredProductId=${rowToSave.id}`,
                    rowToSave,
                    {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                        },
                    }
                )
                .then((res) => {
                    data.forEach((data: any) => {
                        if (rowToSave.id === data.id) {
                            data.isEdited = false;
                        }
                    });
                    getData(data);
                    toast.success("Product has been edited successfully");
                    // fixme zostanaowic sie czy nie lepiej wczensiej zmienic na koniec edycji
                    // rowsEditStatus!.set(rowData.id, !rowsEditStatus!.get(rowData.id));
                    // setRowsEditStatus(new Map(rowsEditStatus!))
                })
                .catch((err) => {
                    toast.error("Product has not been edited");
                    console.log(err);
                });
        }
    };

    const dataChanged = (changedData: any) => {
        console.log("====== DATA CHANED EDIT");

        let productDiscounts = getProductDiscounts(changedData.orderCode);

        console.log(productDiscounts);

        let salesmanProfitAmount = Number(productDiscounts?.discountSMMax);
        let allowedProfitForGroup = Number(productDiscounts?.discountTLMax);
        setData(
            data.map((row: any) => {
                if (row.id === changedData.id) {
                    if (changedData.key === 'orderCode') {
                        // usuwamy wybrany order code
                        if (changedData.value === undefined) {
                            row.materialDescription = null;
                            row.priceListPrice = 0.0;
                            row.transferPrice = 0.0;
                            row.profitProjectControl = 0.0;
                            row.funpriceSalesman = 0.0;
                            row.salesmanProfit = 0.0;
                            row.funpriceProfitControl = 0.0;
                            row.marginTransferPrice = 0.0;
                            row.discountFromPL = 0.0;
                            row.salesmanProfit = 0.0;
                            row.profitProjectControl = 0.0;
                            row.discountFromPL = 0.0;
                            row.baseProductId = undefined;
                        } else {
                            let dataToFill: any = priceListProducts.find(
                                (p: any) => p.orderCode === changedData.value
                            );
                            row.materialDescription = dataToFill?.materialDescription;
                            row.priceListPrice = dataToFill?.priceListPrice;
                            row.transferPrice = dataToFill?.transferPrice;
                            row.baseProductId = dataToFill?.id;
                            row.salesmanProfit = getSalesmanProfit(row.value, row.priceListPrice, row.quantity, salesmanProfitAmount);
                            row.profitProjectControl = getProfitProjectControl(row.value, row.priceListPrice, row.quantity, allowedProfitForGroup);
                            row.discountFromPL = getDiscountFromPL(row.priceListPrice, row.projectPrice);
                            row.funpriceProfitControl = getFunPriceProfitControl(row.priceListPrice, allowedProfitForGroup);
                            row.funpriceSalesman = getFunPriceSalesman(row.priceListPrice, salesmanProfitAmount);
                            row.marginTransferPrice = getMarginTransferPrice(row.projectPrice, row.transferPrice, row.quantity);
                        }

                    }
                    row[changedData.key] = changedData.value;
                    if (changedData.key === 'projectPrice') {
                        row.projectPrice = changedData.value;
                        row.value = getValue(changedData.value, row.quantity);
                        if (row.priceListPrice > 0) {
                            row.salesmanProfit = getSalesmanProfit(row.value, row.priceListPrice, row.quantity, salesmanProfitAmount);
                            row.profitProjectControl = getProfitProjectControl(row.value, row.priceListPrice, row.quantity, allowedProfitForGroup);
                            row.funpriceProfitControl = getFunPriceProfitControl(row.priceListPrice, allowedProfitForGroup);
                            row.funpriceSalesman = getFunPriceSalesman(row.priceListPrice, salesmanProfitAmount);
                            row.discountFromPL = getDiscountFromPL(row.priceListPrice, row.projectPrice);
                            row.marginTransferPrice = getMarginTransferPrice(row.projectPrice, row.transferPrice, row.quantity);
                        }
                    }
                    if (changedData.key === 'quantity') {
                        row.quantity = changedData.value;
                        row.value = getValue(row.projectPrice, row.quantity);
                        if (row.priceListPrice > 0) {
                            row.salesmanProfit = getSalesmanProfit(row.value, row.priceListPrice, row.quantity, salesmanProfitAmount);
                            row.profitProjectControl = getProfitProjectControl(row.value, row.priceListPrice, row.quantity, allowedProfitForGroup);
                            row.marginTransferPrice = getMarginTransferPrice(row.projectPrice, row.transferPrice, row.quantity);
                        }
                    }
                }
                return row;
            })
        );
    };

    const cancelEditData = (rowData: any) => {
        if (rowData.isNew) {
            setData(
                data.filter((row: any) => rowData.id !== row.id)
            );
        } else {
            let previousRow: any = null;
            previousData.forEach((object: any, index: number) => {
                if (object.id === rowData.id) {
                    previousRow = JSON.parse(JSON.stringify(object));
                }
            });

            setData(
                data.map((row: any) => {
                    if (row.id === rowData.id && previousRow !== null) {
                        row.isEdited = false;
                        row = previousRow;
                    }
                    return row;
                })
            );
        }

        // setData(data);
        // rowsEditStatus!.set(rowData.id, !rowsEditStatus!.get(rowData.id));
        // setRowsEditStatus(new Map(rowsEditStatus!))
    };

    const addRow = () => {
        let id = Math.random().toString(16).slice(2);
        data.push({
            "materialDescription": null,
            "orderCode": null,
            "projectPrice": null,
            "quantity": null,
            "value": null,
            "salesmanProfit": null,
            "profitProjectControl": null,
            "pricelistPrice": null,
            "discountFromPL": null,
            "funpriceSalesman": null,
            "funpriceProfitControl": null,
            "competitor": null,
            "competitorPrice": null,
            "transferPrice": null,
            "marginTransferPrice": null,
            "id": id,
            "isEdited": true,
            "isNew": true
        });
        setData(
            data.map((row: any) => {
                return row;
            })
        );
    }

    const {control, handleSubmit, setValue} = useForm<any>({
        defaultValues: {
            customerId: 0,
            additionalInfo: "",
        },
    });

    const handleDeleteProduct = (id: any) => {
        axios
            .delete(
                `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/delete?id=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                toast.success("Product has been deleted");
                getData();
            })
            .catch((err) => {
                toast.error("Something went wrong");
                console.log(err);
            });
    };

    const handleOpen = (fn: any, data?: any) => {
        fn(true);
        if (data) {
            setEditData(data);
        }
    };

    const columns: TableColumnType<any>[] = [
        {
            title: "",
            dataKey: "editRow",
            align: "left",
            sort: false,
            width: 50,
            editable: false
        },
        {
            title: "Order Code",
            dataKey: "orderCode",
            align: "left",
            sort: true,
            width: 100,
            editable: false,
            inputType: "autocomplete"
        },
        {
            title: "Project Price",
            dataKey: "projectPrice",
            align: "center",
            sort: true,
            width: 150,
            editable: true,
            inputType: "number"
        },
        {
            title: "Price List Price",
            dataKey: "priceListPrice",
            width: 100,
            align: "center",
            editable: false
        },
        {
            title: "Quantity",
            dataKey: "quantity",
            align: "center",
            sort: true,
            width: 50,
            editable: true,
            inputType: "number"
        },
        {
            title: "Value",
            dataKey: "value",
            align: "center",
            sort: true,
            width: 100,
            editable: false
        },
        {
            title: "Profit project control",
            dataKey: "profitProjectControl",
            width: 100,
            align: "center",
            editable: false
        },
        {
            title: "Salesman Profit",
            width: 100,
            align: "center",
            dataKey: "salesmanProfit",
            editable: false
        },
        {
            title: "Discount from PL",
            dataKey: "discountFromPL",
            align: "center",
            sort: true,
            width: 150,
            editable: false
        },
        {
            title: "Competitor",
            dataKey: "competitor",
            align: "center",
            sort: true,
            width: 40,
            editable: true,
            inputType: "select"
        },
        {
            title: "Competitor Price",
            dataKey: "competitorPrice",
            align: "center",
            sort: true,
            width: 150,
            editable: true,
            inputType: "number"
        },
        {
            title: "Actions",
            width: 10,
            align: "center",
            sort: false,
            editable: false,
            children: (item) => (
                <FloatingMenu
                    menuItems={[
                        <MenuItem key={1} onClick={() => handleOpen(setEditProduct, item)}>
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Edit"/>
                        </MenuItem>,
                        <MenuItem key={5} onClick={() => handleDeleteProduct(item.id)}>
                            <ListItemIcon>
                                <DeleteIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Delete product"/>
                        </MenuItem>,
                    ]}
                />
            ),
        },
    ];

    const totalValue = useMemo(() => {
        if (data) {
            return data.reduce((acc: any, curr: any) => {
                return acc + curr.value;
            }, 0);
        }
    }, [data]);

    const totalMarginTransfer = useMemo(() => {
        if (data) {
            return data.reduce((acc: any, curr: any) => {
                return acc + curr.marginTransferPrice;
            }, 0);
        }
    }, [data]);

    const totalProjectControl = useMemo(() => {
        if (data) {
            return data.reduce((acc: any, curr: any) => {
                return acc + curr.profitProjectControl;
            }, 0);
        }
    }, [data]);

    const totalProjectSalesman = useMemo(() => {
        if (data) {
            return data.reduce((acc: any, curr: any) => {
                return acc + curr.salesmanProfit;
            }, 0);
        }
    }, [data]);

    const totalMarginValue = useMemo(() => {
        if (data) {
            return data.reduce((acc: any, curr: any) => {
                return acc + curr.transferPrice;
            }, 0);
        }
    }, [data]);

    const onSubmit = handleSubmit((data) => {
        const editData = {
            additionalInfo: data.additionalInfo ? data.additionalInfo : "",
            customerId:
                data.customerId === 0 || !data.customerId
                    ? selectedCustomer.value
                    : data.customerId,
            margin: totalMarginValue,
            sum: totalValue,
            sumMarginTransferPrice: totalMarginTransfer,
            totalityProjectProfitControl: totalProjectControl,
            totalityProjectProfitSalesman: totalProjectSalesman,
        } as any;
        axios
            .put(
                `${process.env.REACT_APP_API_BASE_URL}/app/offer/save?offerId=${id}`,
                editData,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                toast.success("Project has been edited successfully");
                handleModalClose();
                getEditData();
            })
            .catch((err) => {
                toast.error("Project has not been edited");
            });
    });

    const changeRowsEditStatus = (id: number) => {
        setData(
            data.map((row: any) => {
                if (row.id === id) {
                    row.isEdited = true;
                }
                return row;
            })
        );
    }

    const priceListProductsOptions = priceListProducts.map(
        ({id, orderCode}: any) => {
            return {
                id,
                label: orderCode,
                value: orderCode,
            };
        }
    );

    return (
        <ModalComponent
            isOpen={isOpen}
            handleClose={
                !addProducts && !addProductsFromCSV && !editProduct
                    ? handleModalClose
                    : () => {
                    }
            }
            title={addProducts && "Add Product" || editProduct && "Edit Product" || addProductsFromCSV && "Import Products" || "Edit Project"}
            w="xl"
            dialogActions={
                addProducts || addProductsFromCSV || editProduct ? null : (
                    <Box display="flex" sx={{p: 2}}>
                        <Button onClick={handleModalClose}>Cancel</Button>
                        <Button onClick={onSubmit}>Save</Button>
                    </Box>
                )
            }
        >
            <Box sx={{width: "100%", overflowX: "scroll", px: 1}}>
                {!addProducts && !addProductsFromCSV && !editProduct ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box width="60%">
                            <form>
                                <p>Customer: {contractorName}</p>
                                <SearchSelect
                                    loading={loading}
                                    options={customers}
                                    name="customerId"
                                    label="Customer Name"
                                    control={control}
                                    selectedValue={customers.find(c => c.id === selectedCustomer.value)?.label || selectedCustomer.value}
                                />
                                <Input
                                    control={control}
                                    name="additionalInfo"
                                    label="Additional info"
                                    defaultValue=""
                                />
                                <Alert severity="info">
                                    Selected Price List: {offerBasicInfo?.priceListName}
                                </Alert>
                            </form>
                        </Box>
                        <Box mt={5} ml={3} width="100%">
                            <Grid container justifyContent="center">
                                <Grid item xs={2.5}>
                                    <Typography variant="h6">Project Number</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography variant="h6">Last Updated</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography variant="h6">Customer Number</Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography variant="h6">Customer Name</Typography>
                                </Grid>
                            </Grid>
                            <Grid container mt={3} mb={3} justifyContent="center">
                                <Grid item xs={2.5}>
                                    {projectNumber}
                                </Grid>
                                <Grid item xs={2.5}>
                                    {dayjs(updatedAt).format("DD.MM.YYYY")}
                                </Grid>
                                <Grid item xs={2.5}>
                                    {contractorNumber}
                                </Grid>
                                <Grid item xs={2.5}>
                                    {contractorName}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={5} width="100%">
                            <Grid container justifyContent="center">
                                <Grid item xs={2}>
                                    <Typography variant="h6">Total Project Value</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h6">
                                        Total Profit Project Control
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h6">Total Salesman Profit</Typography>
                                </Grid>
                                {userRole !== "USER" && (
                                    <>
                                        <Grid item xs={2}>
                                            <Typography variant="h6">
                                                Total Margin Transfer
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="h6">Total Transfer Price</Typography>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid container mt={3} mb={3} justifyContent="center">
                                <Grid item xs={2}>
                                    {totalValue
                                        ? formatValueToPLN(totalValue.toFixed(2))
                                        : totalValue}
                                </Grid>
                                <Grid item xs={2}>
                                    {totalProjectControl
                                        ? formatValueToPLN(totalProjectControl.toFixed(2))
                                        : totalProjectControl}
                                </Grid>
                                <Grid item xs={2}>
                                    {totalProjectSalesman
                                        ? formatValueToPLN(totalProjectSalesman.toFixed(2))
                                        : totalProjectSalesman}
                                </Grid>
                                {userRole !== "USER" && (
                                    <>
                                        <Grid item xs={2}>
                                            {totalMarginTransfer
                                                ? formatValueToPLN(totalMarginTransfer.toFixed(2))
                                                : totalMarginTransfer}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {totalMarginValue
                                                ? formatValueToPLN(totalMarginValue.toFixed(2))
                                                : totalMarginValue}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>

                        <TableComponent
                            data={data}
                            competitionData={competition}
                            columns={columns}
                            noSearch
                            onClickButtonVoid={() => setAddProducts(true)}
                            onClickButtonVoidText="Add Product"
                            noPagination
                            {...tableSorting}
                            additionalActions={[
                                {
                                    text: "Add from CSV",
                                    action: () => setAddProductsFromCSV(true),
                                    disabled: !!priceListId,
                                },
                            ]}
                            // rowsEditStatus={rowsEditStatus}
                            //setRowsEditStatus={setRowsEditStatus}
                            setRowsEditStatus={(id: number) => changeRowsEditStatus(id)}
                            saveRowData={(rowData: any) => saveData(rowData)}
                            changeInputData={(changedData: any) => dataChanged(changedData)}
                            cancelEdit={(rowData: any) => cancelEditData(rowData)}
                            addRow={() => addRow()}
                            orderCodeData={priceListProductsOptions}
                        />
                    </Box>
                ) : addProducts ? (
                    <AddProductsModal
                        products={data}
                        setAddProducts={setAddProducts}
                        setProducts={setProducts}
                        isEdit={true}
                        offerId={id}
                        getData={getData}
                        currentPriceListId={priceListId}
                        customerCategory={selectedCustomer.category}
                    />
                ) : editProduct ? (
                    <EditProductModal
                        editData={editData}
                        setEditProduct={setEditProduct}
                        getEditData={getData}
                        currentPriceListId={priceListId}
                        customerCategory={selectedCustomer.category}
                    />
                ) : addProductsFromCSV ? (
                    <AddProductsFromCSVModal
                        userRole={userRole}
                        close={() => setAddProductsFromCSV(false)}
                        customerCategory={selectedCustomer.category}
                        currentPriceListId={priceListId}
                        save={async (newProducts) => {
                            await AddNewProductsToOffer(id, newProducts);
                            toast.success("Products added succesfully");
                            getData();
                        }}
                    />
                ) : null}
            </Box>
        </ModalComponent>
    );
};

export default EditProjectModal;
