import {
  Button,
  Table,
  Box,
  TablePagination,
  IconButton,
  TableRow,
  TableHead,
  TableCell,
  TableBody, MenuItem, Autocomplete, TextField
} from "@mui/material";
import SearchInput from "../../inputs/SearchInput";
// import Body from "./body";
// import Header from "./header";
// import { TableColumnType, TableGetRowKey, TableSize } from "./types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {TableColumnType} from "./types";
import Cell from "../../tableEditable/cell";
import {getColumnKey} from "../../tableEditable/getColumnKey";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import DoneIcon from "@mui/icons-material/DoneAllTwoTone";
import RevertIcon from "@mui/icons-material/NotInterestedOutlined";
import Product from "../models/Product";
import EditIcon from "@mui/icons-material/EditOutlined";
import Input from "@material-ui/core/Input";
import Select from "@mui/material/Select";
import {formatValueToPLN} from "../../helpers/currencyFormatter";
// import {TableColumnType} from "../../table/types";

export type AdditionalAction = {
  text: string;
  action(): void;
  disabled: boolean;
};

type Option = {
  id: number;
  label: string;
  value: string;
};

export interface TableProps<T extends Product> {
  columns?: ReadonlyArray<TableColumnType<T>>;
  data?: ReadonlyArray<T>;
  competitionData?: Array<any>;
  disableHeader?: boolean;
  // size?: TableSize;
  // getRowKey?: TableGetRowKey<T>;
  onClickButtonVoid?: () => void;
  onClickButtonVoidText?: string;
  onClickRemoveFilter?: (data: any) => void;
  onClick?: () => void;
  filter?: () => void;
  filters?: any;
  noSearch?: boolean;
  noPagination?: boolean;
  setRowsPerPage?: (value: any) => void;
  rowsPerPage?: any;
  page?: any;
  setPage?: (value: any) => void;
  totalItems?: any;
  sortField?: any;
  setSortField?: (value: any) => void;
  sortDirection?: any;
  setSortDirection?: (value: any) => void;
  additionalActions?: AdditionalAction[];
  rowsEditStatus?: Map<number, boolean>;
  //setRowsEditStatus?: (value: Map<number, boolean>) => void;
  setRowsEditStatus?: (id: number) => void;
  saveRowData?: any;
  cancelEdit?: any;
  changeInputData?: any;
  addRow?: any;
  orderCodeData: Option[];
  addRowEnabled?: boolean;
  onClickSave?: (data: any) => void;
  onClickCancel?: (data: any) => void;
  onClickEdit?: (data: any) => void;
}

function AddProjectTableComponent<T extends Product>({
  columns,
  data,
  competitionData,
  // getRowKey,
  onClickButtonVoid,
  onClickButtonVoidText,
  filter,
  filters,
  onClickRemoveFilter,
  onClickEdit,
  noSearch,
  noPagination,
  setRowsPerPage,
  rowsPerPage,
  page,
  setPage,
  totalItems,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
  additionalActions,
  // setRowsEditStatus,
  // rowsEditStatus,
  saveRowData,
  cancelEdit,
  changeInputData,
  addRow,
  orderCodeData,
  addRowEnabled,
  onClickCancel,
  onClickSave,
  onClick
}: TableProps<T>) {
  const handleChangePage = (event: unknown, newPage: number) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setRowsPerPage(+event.target.value);
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setPage(0);
  };

  const removeCurrentFilter = (data : any) => {
    if (onClickRemoveFilter) {
      onClickRemoveFilter(data);
    }
  };

  const anyFilterActive = () => {
    let emptyFilters = false;
    if (filters !== undefined) {
      Object.keys(filters).map(function(key) {
        if (filters[key].length > 0) {
          emptyFilters = true;
        }
      });
    }
    return emptyFilters;
  }


  const onInputChange = (value : any, key: string, id: number, orderCode?: string) => {
    if (key === 'orderCode') {
      changeInputData({
        local_id: id,
        orderCode: value,
        key: key,
        value: value
      });
    } else {
      changeInputData({
        local_id: id,
        orderCode: orderCode,
        key: key,
        value: value
      });
    }
  }

  const handleDataSort = (el: any, sortOrder: any) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setSortField(el.dataKey);
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setSortDirection(sortOrder);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: noSearch ? "flex-end" : "space-between",
          p: 1,
          mb: 5,
        }}
      >
        {noSearch ? null : <SearchInput />}
        <Box>
          {filters && filters.customerInnerId.length > 0 && (
              <Button
                  key="customerInnerId"
                  variant="contained"
                  onClick={() => removeCurrentFilter("customerInnerId")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Customer Number: {filters.customerInnerId}
              </Button>
          )
          }
          {filters && filters.customerName.length > 0 && (
                  <Button
                      key="customerName"
                      variant="contained"
                      onClick={() => removeCurrentFilter("customerName")}
                      style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
                  >
                    Customer Name: {filters.customerName}
                  </Button>
              )
          }
          {filters && filters.offerNumber.length > 0 && (
              <Button
                  key="offerNumber"
                  variant="contained"
                  onClick={() => removeCurrentFilter("offerNumber")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Project Number: {filters.offerNumber}
              </Button>
          )
          }
          {filters && filters.updateDateFrom.length > 0 && (
              <Button
                  key="updateDateFrom"
                  variant="contained"
                  onClick={() => removeCurrentFilter("updateDateFrom")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Last updated from: {filters.updateDateFrom}
              </Button>
          )
          }
          {filters && filters.updateDateTo.length > 0 && (
              <Button
                  key="updateDateTo"
                  variant="contained"
                  onClick={() => removeCurrentFilter("updateDateTo")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Last updated to: {filters.updateDateTo}
              </Button>
          )
          }
          {filters && filters.offerStatus.length > 0 && (
              <Button
                  key="offerStatus"
                  variant="contained"
                  onClick={() => removeCurrentFilter("offerStatus")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Status: {filters.offerStatus}
              </Button>
          )
          }
          {filter && (
            <IconButton onClick={filter} sx={{ mx: 2, ...(anyFilterActive() && { backgroundColor: "#e0e0e0" }) }}>
              <FilterAltIcon />
            </IconButton>
          )}
          {additionalActions &&
            additionalActions.map((el) => (
              <Button
                key={`${el.text}`}
                variant="contained"
                disabled={!el.disabled}
                onClick={el.action}
                style={{ marginRight: "5px" }}
              >
                {el.text}
              </Button>
            ))}
          {onClickButtonVoid ? (
            <Button variant="contained" onClick={onClickButtonVoid}>
              {onClickButtonVoidText || "Add"}
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          {columns && setSortField && (
            <>
              <TableHead>
                <TableRow>
                  {columns &&
                      sortField &&
                      columns.map((column, index) => (
                          // TODO: fix issue with unique "key" prop
                          <>
                            {column.dataKey === 'editRow' && (
                                <TableCell
                                    align={'left'}
                                    sx={{
                                      color: "secondary.dark",
                                    }}
                                    style={{ width: column.width || 100 }}
                                    // onClick={onClick}
                                >
                                  <IconButton
                                      disabled={addRowEnabled!}
                                      aria-label="add"
                                      onClick={addRow}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </TableCell>
                                // <Cell
                                //     key={getColumnKey(column, index)}
                                //     column={column}
                                //     rowIndex={NaN}
                                //     sx={{
                                //       color: "secondary.dark",
                                //     }}
                                // >
                                //   <IconButton
                                //       aria-label="add"
                                //       // onClick={addRow}
                                //   >
                                //     <AddIcon />
                                //   </IconButton>
                                // </Cell>
                            )}
                            {column.dataKey !== 'editRow' && column.sort && sortField !== column.dataKey && (
                                <TableCell
                                    align={'left'}
                                    sx={{
                                      color: "secondary.dark",
                                      cursor: "pointer",
                                      position: "relative",
                                    }}
                                    style={{ width: column.width || 100 }}
                                    onClick={() => handleDataSort(column, "ASC")}
                                >
                                  {column.title} <div></div>
                                </TableCell>
                                // <Cell
                                //     key={getColumnKey(column, index)}
                                //     column={column}
                                //     rowIndex={NaN}
                                //     sx={{
                                //       color: "secondary.dark",
                                //       cursor: "pointer",
                                //       position: "relative",
                                //     }}
                                //     // onClick={() => handleDataSort(column, "ASC")}
                                // >
                                //   {column.title} <div></div>
                                // </Cell>
                            )}

                            {column.dataKey !== 'editRow' && column.sort && sortField === column.dataKey &&
                                sortDirection === "ASC" && (
                                    <TableCell
                                        align={'left'}
                                        sx={{
                                          color: "secondary.dark",
                                          cursor: "pointer",
                                          position: "relative",
                                        }}
                                        style={{ width: column.width || 100 }}
                                        onClick={() => handleDataSort(column, "DESC")}
                                    >
                                      {column.title}
                                      <ArrowDropUpIcon
                                          sx={{
                                            position: "absolute",
                                          }}
                                      />
                                    </TableCell>
                                    // <Cell
                                    //     key={getColumnKey(column, index)}
                                    //     column={column}
                                    //     rowIndex={NaN}
                                    //     sx={{
                                    //       color: "secondary",
                                    //       cursor: "pointer",
                                    //       position: "relative",
                                    //     }}
                                    //     // onClick={() => handleDataSort(column, "DESC")}
                                    // >
                                    //   {column.title}
                                    //   <ArrowDropUpIcon
                                    //       sx={{
                                    //         position: "absolute",
                                    //       }}
                                    //   />
                                    // </Cell>
                                )}

                            {column.dataKey !== 'editRow' && column.sort && sortField === column.dataKey &&
                                sortDirection === "DESC" && (
                                    <TableCell
                                        align={'left'}
                                        sx={{
                                          color: "secondary.dark",
                                          cursor: "pointer",
                                          position: "relative",
                                        }}
                                        style={{ width: column.width || 100 }}
                                        onClick={() => handleDataSort(column, "ASC")}
                                    >
                                      {column.title}
                                      <ArrowDropDownIcon
                                          sx={{
                                            position: "absolute",
                                          }}
                                      />
                                    </TableCell>
                                    // <Cell
                                    //     key={getColumnKey(column, index)}
                                    //     column={column}
                                    //     rowIndex={NaN}
                                    //     sx={{
                                    //       color: "secondary",
                                    //       cursor: "pointer",
                                    //       position: "relative",
                                    //     }}
                                    //     // onClick={() => handleDataSort(column, "ASC")}
                                    // >
                                    //   {column.title}
                                    //   <ArrowDropDownIcon
                                    //       sx={{
                                    //         position: "absolute",
                                    //       }}
                                    //   />
                                    // </Cell>
                                )}

                            {column.dataKey !== 'editRow' && !column.sort && (
                                <TableCell
                                    align={'left'}
                                    sx={{
                                      color: "secondary.dark",
                                    }}
                                    style={{ width: column.width || 100 }}
                                    // onClick={onClick}
                                >
                                  {column.title}
                                </TableCell>
                                // <Cell
                                //     key={getColumnKey(column, index)}
                                //     column={column}
                                //     rowIndex={NaN}
                                //     sx={{
                                //       color: "secondary.dark",
                                //     }}
                                // >
                                //   {column.title}
                                // </Cell>
                            )}
                          </>
                      ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {data && data.length ?
                    (
                        data.map((rowData, index) => {
                          return (
                              <TableRow>
                                {columns.map((column, index) => {
                                  // czy kolumna jest kolumna z przyciskami do edycji
                                  if (column.dataKey === 'editRow') {
                                    if (rowData.isEdited === true) {
                                      return (
                                          <TableCell
                                              align={'left'}
                                              style={{ width: column.width || 100 }}
                                          >
                                            <IconButton
                                                aria-label="done"
                                                onClick={() => onClickSave!(rowData)}
                                            >
                                              <DoneIcon />
                                            </IconButton>
                                            {/*<IconButton*/}
                                            {/*    aria-label="revert"*/}
                                            {/*    onClick={() => onClickCancel!(rowData)}*/}
                                            {/*>*/}
                                            {/*  <RevertIcon />*/}
                                            {/*</IconButton>*/}
                                          </TableCell>
                                      )
                                    } else {
                                      return (
                                          <TableCell
                                              align={'left'}
                                              style={{ width: column.width || 100 }}
                                          >
                                            <IconButton
                                                aria-label="edit"
                                                onClick={() => onClickEdit!(rowData)}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </TableCell>
                                      )
                                    }
                                  } else {
                                    // sprawdzenie czy kolumna jest edytowalna i czy dane sa w trybie edycji
                                    if (column.editable && rowData.isEdited) {
                                      if (column.inputType === 'number') {
                                        return (
                                            <TableCell
                                                align={'left'}
                                                style={{ width: column.width || 100 }}
                                                //onClick={onClick}
                                            >
                                              <Input
                                                  defaultValue={rowData[column.dataKey!]}
                                                  //value={children}
                                                  name={String(column.dataKey!)}
                                                  type="number"
                                                  onChange={e => onInputChange(e.target.value, String(column.dataKey), rowData.local_id, rowData.orderCode)}
                                              />
                                            </TableCell>
                                        );
                                      }
                                      if (column.inputType === 'select') {
                                        return (
                                            <TableCell
                                                align={'left'}
                                                style={{ width: column.width || 100 }}
                                                //onClick={onClick}
                                            >
                                              <Select variant="standard"
                                                      defaultValue={rowData.competitor}
                                                      onChange={e => onInputChange(e.target.value, String(column.dataKey), rowData.local_id, rowData.orderCode)}
                                              >
                                                {competitionData
                                                    ? competitionData.map((option: any) => (
                                                        <MenuItem
                                                            key={option.label}
                                                            value={option.value}
                                                        >
                                                          {option.label}
                                                        </MenuItem>
                                                    ))
                                                    : []}
                                              </Select>
                                            </TableCell>
                                        );
                                      }
                                      return (
                                          <TableCell
                                              align={'left'}
                                              style={{ width: column.width || 100 }}
                                              //onClick={onClick}
                                          >
                                            <Input
                                                defaultValue={rowData[column.dataKey!]}
                                                name={'name'}
                                            />
                                          </TableCell>
                                      );
                                    } else {
                                      if (column.inputType === 'autocomplete' && rowData.isNew) {
                                        return (
                                            <TableCell
                                                align={'left'}
                                                style={{ width: column.width || 100 }}
                                                //onClick={onClick}
                                            >
                                              <Autocomplete
                                                  onChange={(e, data) => {
                                                    onInputChange(data?.value, String(column.dataKey), rowData.local_id, rowData.orderCode)
                                                  }}
                                                  // onChange={e => onInputChange(e.target.value, String(column.dataKey), rowData.id, rowData.orderCode)}
                                                  options={orderCodeData!.map((option: Option) => ({
                                                    id: option.id,
                                                    label: option.label,
                                                    value: option.value,
                                                  }))}
                                                  // loading={loading}
                                                  renderInput={(params) => (
                                                      <TextField
                                                          {...params}
                                                          //label={'label'}
                                                          variant={"standard"}
                                                          required={true}
                                                      />
                                                  )}
                                              />
                                            </TableCell>
                                        );
                                      }
                                      // jesli nie to wyswietlamy dane
                                      if (column.inputType === 'select') {
                                        return (
                                            <TableCell
                                                align={'left'}
                                                style={{ width: column.width || 100 }}
                                            >
                                              {rowData[column.dataKey!]}
                                            </TableCell>
                                        );
                                      }
                                      if (column.inputType === 'autocomplete') {
                                        return (
                                            <TableCell
                                                align={'left'}
                                                style={{ width: column.width || 100 }}
                                            >
                                              {rowData[column.dataKey!]}
                                            </TableCell>
                                        );
                                      }
                                      if (column.children !== undefined) {
                                        return (
                                            <TableCell
                                                align={'left'}
                                                style={{ width: column.width || 100 }}
                                            >
                                              {column.children(rowData, rowData.local_id)}
                                            </TableCell>
                                        );
                                      }
                                      return (
                                          <TableCell
                                              align={'left'}
                                              style={{ width: column.width || 100 }}
                                          >
                                            {formatValueToPLN(rowData[column.dataKey!])}
                                          </TableCell>
                                      );
                                    }
                                  }
                                })}
                              </TableRow>
                          )
                        })
                    )
                    : (
                      <tr>
                        <td>no data</td>
                      </tr>
                    )
                }
              </TableBody>

              {/*<Header*/}
              {/*  columns={columns}*/}
              {/*  sortField={sortField}*/}
              {/*  setSortField={setSortField}*/}
              {/*  sortDirection={sortDirection}*/}
              {/*  setSortDirection={setSortDirection}*/}
              {/*  addRow={addRow}*/}
              {/*/>*/}
              {/*<Body*/}
              {/*    columns={columns}*/}
              {/*    data={data}*/}
              {/*    competitionData={competitionData}*/}
              {/*    getRowKey={getRowKey}*/}
              {/*    setRowsEditStatus={setRowsEditStatus}*/}
              {/*    rowsEditStatus={rowsEditStatus}*/}
              {/*    saveRowData={saveRowData}*/}
              {/*    cancelEdit={cancelEdit}*/}
              {/*    changeInputData={changeInputData}*/}
              {/*    orderCodeData={orderCodeData}*/}
              {/*/>*/}
            </>
          )}
        </Table>
      </Box>
      {noPagination ? null : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={totalItems ? totalItems : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default AddProjectTableComponent;
