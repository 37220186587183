import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Controller } from "react-hook-form";

interface Props {
  control: any;
  name: string;
  label?: string;
  variant?: "contained" | "outlined" | "text";
}

const FileInput = ({ name, control, label, variant = "contained" }: Props) => {
  let fileName = "";
  return (
    <Box sx={{ my: 2 }}>
      <Controller
        render={({ field }) => (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant={variant}
                component='label'
                sx={{ marginRight: "16px" }}
              >
                {label}
                <input
                  type='file'
                  onChange={(e) => {
                    // @ts-ignore
                    fileName = e.target.files[0].name;
                    field.onChange(e.target.files);
                  }}
                  multiple
                  hidden
                />
              </Button>
              <p>{fileName}</p>
            </Box>
          </>
        )}
        name={name}
        control={control}
      />
    </Box>
  );
};

export default FileInput;
