import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { Role } from "../hooks/useAuth";

interface Props {
  icon: any;
  name: string;
  link: string;
}

const MenuItem = ({ icon, name, link }: Props) => {
  const history = useHistory();
  const clickHandler = () => {
    history.push(link);
  };
  return (
    <ListItem
      disablePadding
      sx={{
        color: "secondary.main",
        ":hover": {
          bgcolor: "primary.light",
        },
      }}
    >
      <ListItemButton onClick={clickHandler}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItem;
