import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import DictionaryCard from "./DictionaryCard";
import EditIcon from "@mui/icons-material/Edit";
import DictionaryEditPriceModal from "./DictionaryEditPriceModal";
import DictionaryReplacePriceModal from "./DictionaryReplacePriceModal";
import ConnectPriceListModal from "./ConnectPriceListModal";
import PriceListsProductsModal from "./PriceListsProductsModal";
import ArchiveIcon from "@mui/icons-material/Archive";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ViewListIcon from "@mui/icons-material/ViewList";
import OverlayLoading from "../../OverlayLoading";

interface Props {
  userRole: string;
}

const PriceLists = ({ userRole }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editData, setEditData] = useState([]);
  const [edit, setEdit] = useState(Boolean);
  const [replace, setReplace] = useState(Boolean);
  const [priceLists, setPriceLists] = useState([]);
  const [connectPriceListModalOpen, setConnectPriceListModalOpen] =
    useState(false);
  const [priceListsProductsModalOpen, setpriceListsProductsModalOpen] =
    useState(false);
  const [archive, setArchive] = useState(false);

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/get/newest_price_lists`
  );

  useEffect(() => {
    if (data) {
      setPriceLists(data.priceLists);
    }
  }, [data, connectPriceListModalOpen]);

  const handleOpen = (fn: any, data?: any) => {
    fn(true);
    if (data) {
      setEditData(data);
    }
  };

  const HandleClose = (fn: any) => {
    fn(false);
  };

  const handleDisconnect = (item?: any) => {
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/unplugdiscountlist?priceListId=${item.id}
				`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        getData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error occurred");
        console.log(err);
      });
  };

  const handleArchive = (item?: any) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/pricelist/changeById?active=false&priceListId=${item.id}
				`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        getData();
        setArchive(!archive);
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          mx: 1,
          mb: 1,
        }}
      >
        <Typography variant="h5">Price Lists</Typography>
        {userRole === "ADMIN" ? (
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            onClick={() => handleOpen(setReplace)}
          >
            Import file
          </Button>
        ) : null}
      </Box>

      <Box sx={{ display: "flex", width: "100%" }}>
        {data
          ? priceLists.map((item: any) => {
              return item.status === true ? (
                <DictionaryCard
                  key={item.id}
                  name={item.name}
                  desc={`Valid from: ${item.validFrom} to ${item.validTo}`}
                  secondDesc={`Connected Discount List: ${
                    item.discountListName !== null ? item.discountListName : "-"
                  }`}
                  menuItems={[
                    <MenuItem key={1} onClick={() => handleOpen(setEdit, item)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit Price List" />
                    </MenuItem>,
                    <MenuItem key={2} onClick={() => handleArchive(item)}>
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Archive" />
                    </MenuItem>,
                    <MenuItem
                      key={3}
                      onClick={() => {
                        handleOpen(setConnectPriceListModalOpen, item);
                      }}
                    >
                      <ListItemIcon>
                        <PlaylistPlayIcon />
                      </ListItemIcon>
                      <ListItemText primary="Connect Discount List" />
                    </MenuItem>,
                    <MenuItem
                      key={4}
                      onClick={() => {
                        handleDisconnect(item);
                      }}
                    >
                      <ListItemIcon>
                        <PlaylistRemoveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Disconnect Discount List" />
                    </MenuItem>,
                    <MenuItem
                      key={5}
                      onClick={() => {
                        handleOpen(setpriceListsProductsModalOpen, item);
                      }}
                    >
                      <ListItemIcon>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText primary="Display products" />
                    </MenuItem>,
                  ]}
                  userRole={userRole}
                />
              ) : null;
            })
          : null}
      </Box>
      <DictionaryEditPriceModal
        getData={getData}
        priceList={editData}
        isOpen={edit}
        handleModalClose={() => HandleClose(setEdit)}
      />
      <DictionaryReplacePriceModal
        getData={getData}
        priceList={editData}
        isOpen={replace}
        handleModalClose={() => HandleClose(setReplace)}
      />
      <ConnectPriceListModal
        isOpen={connectPriceListModalOpen}
        handleClose={() => HandleClose(setConnectPriceListModalOpen)}
        pickedPriceList={editData}
        getNewestPriceList={getData}
      />
      <PriceListsProductsModal
        isOpen={priceListsProductsModalOpen}
        handleClose={() => HandleClose(setpriceListsProductsModalOpen)}
        pickedPriceList={editData}
      />
      <OverlayLoading open={loading} />
    </>
  );
};

export default PriceLists;
