import { Button, IconButton, Typography } from "@mui/material";
import Input from "../inputs/Input";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import useFetchSorting from "../../hooks/useFetchSorting";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectComponent from "../inputs/Select";

interface Props {}

const PriceListTable = (props: Props) => {
  const currencyOptions = [
    {
      id: 1,
      value: "EUR",
      label: "EUR",
    },
    {
      id: 2,
      value: "PLN",
      label: "PLN",
    },
  ];

  const unitMeasureOptions = [
    {
      id: 1,
      value: "SZT",
      label: "SZT",
    },
    {
      id: 2,
      value: "KG",
      label: "KG",
    },
  ];

  const [addProduct, setAddProduct] = useState(false);
  const { sortingOption, getSortingOption } = useFetchSorting(
    "http://localhost:4444/usersSortOption"
  );

  const { data, getData } = useFetch("http://localhost:4444/priceListsTable");

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      product: "",
      price: "",
    },
  });

  const handleAddProduct = () => {
    setAddProduct(true);
  };

  const handleDelete = (id: Number) => {
    axios.delete(`http://localhost:4444/priceListsTable/${id}`).then((res) => {
      console.log(res);
      getData();
    });
  };

  const onSubmit = handleSubmit((data) => {
    axios
      .post(`http://localhost:4444/priceListsTable`, data)
      .then((res) => {
        console.log(res);
        setAddProduct(false);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const columns: TableColumnType<any>[] = [
    {
      title: "Order Code",
      dataKey: "orderCode",
      width: 150,
      sort: true,
    },
    {
      title: "Product Area",
      dataKey: "productArea",
      width: 150,
      sort: true,
    },
    {
      title: "Product Group",
      dataKey: "productGroup",
      width: 150,
      sort: true,
    },
    {
      title: "PAPG",
      dataKey: "papg",
      sort: true,
    },
    {
      title: "Description",
      dataKey: "materialDescription",
      width: 150,
      sort: true,
    },
    {
      title: "Global Price",
      dataKey: "globalPrice",
      sort: true,
    },
    {
      title: "Currency",
      dataKey: "currency",
      width: 50,
      sort: true,
    },
    {
      title: "Pricing Unit",
      dataKey: "pricingUnit",
      width: 100,
      align: "center",
      sort: true,
    },
    {
      title: "Unit Measure",
      dataKey: "unitMeasure",
      sort: true,
    },
    {
      title: "",
      dataKey: "actions",
      width: 20,
      children: (row) => (
        <IconButton onClick={() => handleDelete(row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];
  return (
    <>
      {addProduct ? (
        <form style={{ width: "100%", marginTop: -20 }}>
          <Typography variant='h5'>Add Product</Typography>
          <Box sx={{ width: "100%" }}>
            <Input
              name='orderCode'
              label='Order Code'
              control={control}
              required
            />
            <Input
              name='productArea'
              label='Product Area'
              control={control}
              required
            />
            <Input
              name='productGroup'
              label='Product Group'
              control={control}
              required
            />
            <Input name='papg' label='PAPG' control={control} required />
            <Input
              name='materialDescription'
              label='Description'
              control={control}
              required
            />
            <Input
              name='globalPrice'
              label='Global Price'
              control={control}
              required
            />
            <SelectComponent
              options={currencyOptions}
              control={control}
              name='currency'
              label='Currency'
            />
            <Input
              type='number'
              name='pricingUnit'
              label='Pricing Unit'
              control={control}
              required
            />
            <SelectComponent
              name='unitMeasure'
              label='Unit Measure'
              control={control}
              options={unitMeasureOptions}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button onClick={() => setAddProduct(false)}>Cancel</Button>
            <Button onClick={onSubmit}>Save</Button>
          </Box>
        </form>
      ) : (
        <TableComponent
          data={data}
          columns={columns}
          onClickButtonVoid={handleAddProduct}
        />
      )}
    </>
  );
};

export default PriceListTable;
