import Paper from "@mui/material/Paper";
import React from "react";

interface Props {
  children?: React.ReactNode;
  mt?: number;
  w?: number | string;
  mx?: number;
  my?: number;
}

const ComponentCard = ({ children, mt, w, mx, my }: Props) => {
  return (
    <Paper elevation={1} sx={{ p: 2, mt: mt, width: w, mx: mx, my: my }}>
      {children}
    </Paper>
  );
};

export default ComponentCard;
