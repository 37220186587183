import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../inputs/Input';
import ModalComponent from '../Modal';
import axios from 'axios';
import { toast } from 'react-toastify';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	competitor?: any;
	getData: () => void;
}

const CompetitorsEditModal = ({ isOpen, handleModalClose, competitor, getData }: Props) => {
	const { id, fullName, shortName } = competitor;

	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			id,
			fullName,
			shortName,
		},
	});

	useEffect(() => {
		reset({
			id,
			fullName,
			shortName,
		});
	}, [competitor]);

	const onSubmit = handleSubmit((data: any) => {
		const urlParm = encodeURI(shortName);

		axios
			.put(
				`${process.env.REACT_APP_API_BASE_URL}/app/competitor/edit/id?competitorId=${competitor.id}`,
				{
					fullName: data.fullName,
					shortName: data.shortName,
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Edited successfully');
				getData();
				handleModalClose();
			})
			.catch(err => {
				toast.error('Error occur');
				console.log(err);
			});
	});

	return (
		<ModalComponent isOpen={isOpen} handleClose={handleModalClose} title="Competitor" w="xl">
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="fullName" label="Full Name" control={control} />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="shortName" label="Short Name" control={control} />
				</Box>

				<Box></Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>
					<Button onClick={onSubmit}>Save</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default CompetitorsEditModal;
