import { useEffect, useState } from "react";
import { Box, IconButton, Button } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ModalComponent from "../Modal";
import Input from "../inputs/Input";
import DatePicker from "../inputs/DatePicker";
import SearchSelect from "../inputs/SearchSelect";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
}

const GenerateProjectsSumReport = ({ isOpen, handleModalClose }: Props) => {
  const valuesToExport = [
    {
      id: 0,
      value: "CREATOR",
      label: "CREATOR",
    },
    {
      id: 1,
      value: "CUSTOMER",
      label: "CUSTOMER",
    },
    {
      id: 2,
      value: "REGION_UNIT",
      label: "REGION UNIT",
    },
  ];
  const [sortField, setSortField] = useState<string>("id");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [filters, setFilters] = useState({
    valuesToExport: "",
    closedAtDateFrom: "",
    closedAtDateTo: "",
    creatorName: "",
    customerName: "",
    maxSum: "",
    maxSumMarginTransferPrice: "",
    maxTotalityProjectProfitControl: "",
    minSum: "",
    minSumMarginTransferPrice: "",
    minTotalityProjectProfitControl: "",
    offerNumber: "",
  });

  useEffect(() => {
    if (filters.valuesToExport !== "") {
      fetchReport();
    }
  }, [filters]);

  //add region as filter

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      valuesToExport: "",
      closedAtDateFrom: "",
      closedAtDateTo: "",
      creatorName: "",
      customerName: "",
      maxSum: "",
      maxSumMarginTransferPrice: "",
      maxTotalityProjectProfitControl: "",
      minSum: "",
      minSumMarginTransferPrice: "",
      minTotalityProjectProfitControl: "",
      offerNumber: "",
    },
  });

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      valuesToExport: data.valuesToExport,
      closedAtDateFrom:
        data.closedAtDateFrom !== ""
          ? dayjs(data.closedAtDateFrom).format("YYYY-MM-DD")
          : "",
      closedAtDateTo:
        data.closedAtDateTo !== ""
          ? dayjs(data.closedAtDateTo).format("YYYY-MM-DD")
          : "",
      creatorName: data.creatorName,
      customerName: data.customerName,
      maxSum: data.maxSum,
      maxSumMarginTransferPrice: data.maxSumMarginTransferPrice,
      maxTotalityProjectProfitControl: data.maxTotalityProjectProfitControl,
      minSum: data.minSum,
      minSumMarginTransferPrice: data.minSumMarginTransferPrice,
      minTotalityProjectProfitControl: data.minTotalityProjectProfitControl,
      offerNumber: data.offerNumber,
    });
    reset();
    handleModalClose();
  });

  const fetchReport = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/app/report/export/reportOfferSum/sort/filter?${
          filters.closedAtDateFrom !== ""
            ? `closedAtDateFrom=${filters.closedAtDateFrom}`
            : ""
        }${
          filters.closedAtDateTo !== ""
            ? `&closedAtDateTo=${filters.closedAtDateTo}`
            : ""
        }${
          filters.creatorName !== ""
            ? `&creatorName=${filters.creatorName}`
            : ""
        }${
          filters.customerName !== ""
            ? `&customerName=${filters.customerName}`
            : ""
        }${filters.maxSum !== "" ? `&maxSum=${filters.maxSum}` : ""}${
          filters.maxSumMarginTransferPrice !== ""
            ? `&maxSumMarginTransferPrice=${filters.maxSumMarginTransferPrice}`
            : ""
        }${
          filters.maxTotalityProjectProfitControl !== ""
            ? `&maxTotalityProjectProfitControl=${filters.maxTotalityProjectProfitControl}`
            : ""
        }${filters.minSum !== "" ? `&minSum=${filters.minSum}` : ""}${
          filters.minSumMarginTransferPrice !== ""
            ? `&minSumMarginTransferPrice=${filters.minSumMarginTransferPrice}`
            : ""
        }${
          filters.minTotalityProjectProfitControl !== ""
            ? `&minTotalityProjectProfitControl=${filters.minTotalityProjectProfitControl}`
            : ""
        }${
          filters.offerNumber !== ""
            ? `&offerNumber=${filters.offerNumber}`
            : ""
        }
				&sortBy=${sortField}&sortDirection=${sortDirection}&sumBy=${
          filters.valuesToExport
        }
				`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        fileDownload(res.data, "Report.xlsx");
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
    handleModalClose();
  };

  const handleFilterClear = () => {
    reset();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title="Generate Project Sum Report"
      w="xl"
    >
      <Box sx={{ mx: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3>Filter</h3>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: "100%" }}>
          <form>
            <SearchSelect
              control={control}
              options={valuesToExport}
              name="valuesToExport"
              label="Sum By"
              required
            />
            <DatePicker
              fullWidth
              name="closedAtDateFrom"
              control={control}
              label="Closed At Date From: "
            />
            <DatePicker
              fullWidth
              name="closedAtDateTo"
              control={control}
              label="Closed At Date To: "
            />
            <Input name="creatorName" control={control} label="Creator Name" />
            <Input
              name="customerName"
              control={control}
              label="Customer Name"
            />
            <Input name="maxSum" control={control} label="Max Project Value" />
            <Input
              name="maxSumMarginTransferPrice"
              control={control}
              label="Max Margin Transfer Price"
            />
            <Input
              name="maxTotalityProjectProfitControl"
              control={control}
              label="Max Project Profit Control"
            />
            <Input name="minSum" control={control} label="Min Project Value" />
            <Input
              name="minSumMarginTransferPrice"
              control={control}
              label="Min Margin Transfer Price"
            />
            <Input
              name="minTotalityProjectProfitControl"
              control={control}
              label="Min Project Profit Control"
            />
            {/* <Input name="region" control={control} label="Region" /> */}
            {/* <Input
              name="offerNumber"
              control={control}
              label="Project Number"
            /> */}
          </form>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button color="primary" onClick={handleFilterClear}>
            Clear
          </Button>
          <Button color="primary" onClick={handleFilterSubmit}>
            Confirm
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default GenerateProjectsSumReport;
