export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function request(url: string, options: any = {}): Promise<any> {
  const { headers, ...rest } = options;
  const response = await fetch(`${API_BASE_URL}${url}`, {
    ...rest,
    headers: {
      ...headers,
    },
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.message);
  }
  return json;
}

export async function post(
  url: string,
  body: any,
  options: any = {}
): Promise<any> {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });

  return request(url, {
    ...options,
    method: "POST",
    body: formData,
  });
}
