import { useState } from 'react';

const useTablePagination = () => {
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(0);

    return {
        rowsPerPage, setRowsPerPage, page, setPage
    }
}

export default useTablePagination;