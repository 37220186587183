import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const useFetchSorting = (url: string) => {
  const [sortingOption, setData] = useState<any>();
  const [error, setError] = useState<any>();

  const getSortingOption = useCallback(() => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        setError(err as any);
      });
  }, [url, setData, setError]);

  useEffect(() => {
    getSortingOption();
  }, [getSortingOption]);

  return { sortingOption, error, getSortingOption };
};

export default useFetchSorting;
