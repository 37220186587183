import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../inputs/Input';
import ModalComponent from '../Modal';
import Select from '../inputs/Select';
import DatePicker from '../inputs/DatePicker';

interface Props {
	getData: () => void;
	rowData?: any;
	isOpen: boolean;
	handleModalClose: () => void;
}

const PriceListsEditModal = ({ getData, rowData, isOpen, handleModalClose }: Props) => {
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			discountListName: rowData?.discountListName,
			name: rowData?.name,
			status: rowData?.status,
			validFrom: rowData?.validFrom,
			validTo: rowData?.validTo,
		},
	});

	useEffect(() => {
		reset({
			discountListName: rowData.discountListName,
			name: rowData.name,
			status: rowData.status,
			validFrom: rowData.validFrom,
			validTo: rowData.validTo,
		});
	}, [rowData]);

	const onSubmit = handleSubmit((data: any) => {
		axios
			.put(
				`${process.env.REACT_APP_API_BASE_URL}/app/pricelist/updateById?priceListId=${rowData.id}
				`,
				{
					discountListName: data.discountListName,
					name: data.name,
					status: data.status === 'Active' ? true : false,
					validFrom: data.validFrom,
					validTo: data.validTo,
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Edited successfully');
				getData();
				handleModalClose();
			})
			.catch(err => {
				toast.error('Error occurred');
				console.log(err);
			});
	});

	const selectOptions = [
		{
			id: 0,
			value: 'Active',
			label: 'Active',
		},
		{
			id: 1,
			value: 'Archived',
			label: 'Archived',
		},
	];

	return (
		<ModalComponent isOpen={isOpen} handleClose={handleModalClose} title="Price List" w="xl">
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="name" label="Name" control={control} />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Select
						control={control}
						options={selectOptions}
						name="status"
						label="status"
					/>
				</Box>
				<Box sx={{ width: '100%' }}>
					<DatePicker fullWidth name="validFrom" control={control} label="Valid From: " />
				</Box>
				<Box sx={{ width: '100%' }}>
					<DatePicker fullWidth name="validTo" control={control} label="Valid To: " />
				</Box>
				<Box></Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>
					<Button onClick={onSubmit}>Save</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default PriceListsEditModal;
