import { Box, Button } from "@mui/material";
import React from "react";
import ModalComponent from "../Modal";
import axios from "axios";
import { toast } from "react-toastify";
import Input from "../inputs/Input";
import { useForm } from "react-hook-form";
import InputMultiline from "../inputs/InputMultiline";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  project: any;
}

const SendOfferTicketModal = ({ isOpen, handleModalClose, project }: Props) => {
  const { id, projectNumber } = project;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/app/ticket/new`,
        {
          offerReferenceId: id,
          ticketMessage: data.message,
          topic: projectNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        handleModalClose();
        toast.success("Message for ticket has been sent");
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title={`Send message for project ${projectNumber}`}
      isSmall
      dialogActions={
        <Box display="flex" sx={{ p: 2 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={onSubmit}>Send</Button>
        </Box>
      }
    >
      <Box
        // display="flex"
        // flexDirection="column"
        // alignItems="center"
        // justifyContent="center"
        sx={{ width: "90%"}}
      >
        <InputMultiline name="message" control={control} label="message for admin" rows={6} />
      </Box>
    </ModalComponent>
  );
};

export default SendOfferTicketModal;
