import React, {
  createContext,
  ReactElement,
  ReactNode,
  useReducer,
  useContext,
} from "react";
import Reducer from "./reducers/Reducer";
import { ContextType, StateInterface } from "./types";

export const initialState: StateInterface = {
  userData: [],
};

const Context = createContext({} as ContextType);

export const useStore = (): ContextType => useContext(Context);

export function Store({ children }: { children: ReactNode }): ReactElement {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}
