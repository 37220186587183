import { useState } from "react";
import { toast } from "react-toastify";

const useGetDataFromCSV = () => {
  const [loadingDataFromCSV, setLoadingDataFromCSV] = useState(false);
  const [fileDataArray, setFileDataArray] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>();
  const csvListSeparator = ";";
  const newLineSeparator = '\n'

  const handleFileAdded = (e: any) => {
    setLoadingDataFromCSV(true);
    const fileReader = new FileReader();
    const csvFileToArray = (input: string) => {
      //let isSemicolonDelimiter = checkDelimiter(";", input);
      // let isComaDelimiter = checkDelimiter(",", input);

      // let csvSeparator = csvListSeparator;
      // if (isComaDelimiter) {
      //   csvSeparator = ",";
      // }

      const csvHeader = input.slice(0, input.indexOf(newLineSeparator)).split(csvListSeparator);
      console.log(csvHeader);
      const csvRows = input.slice(input.indexOf(newLineSeparator) + 1).split(newLineSeparator).map(i => i.replace('\r', ''));
      console.log(csvRows);

      const array: any = csvRows.map((i) => {
        let values = i.split(csvListSeparator);
        const obj = csvHeader.reduce((object: any, header: any, index) => {
          if (values[index] === undefined) {
            return {};
          }
          if (index === 1) {
            values[index] = values[index].replace(",",".");
          }
          if (index === 4) {
            values[index] = values[index].replace(",",".");
          }
          object[header.trim()] = values[index];
          return object;
        }, {});
        return obj;
      });

      setFileDataArray(array);
      setLoadingDataFromCSV(false);
    };

    const file = e.target.files[0];

    if (file) {
      fileReader.onload = function (event) {
        try {
          const csvOutput = event.target?.result;
          csvFileToArray(csvOutput as string);
          setFileName(file.name);
        } catch (err) {
          console.error(err);
          toast.error("Error during reading csv file");
          setLoadingDataFromCSV(false);
        }
      };

      fileReader.readAsText(file);
    }
  };

  const resetFileData = () => {
    setFileName(undefined);
    setFileDataArray([]);
  };

  // const checkDelimiter = (csvListSeparator: string, data: string) => {
  //   let csvHeader = data.slice(0, data.indexOf(newLineSeparator)).split(csvListSeparator);
  //   console.log("header: " + csvHeader);
  //   return csvHeader.length > 1;
  // };

  return {
    loadingDataFromCSV,
    fileName,
    fileDataArray,
    handleFileAdded,
    resetFileData,
  };
};

export default useGetDataFromCSV;
