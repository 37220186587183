import { Box, Button } from "@mui/material";
import ModalComponent from "../Modal";
import SelectComponent from "../inputs/Select";
import { useForm } from "react-hook-form";
import Input from "../inputs/Input";
import React from "react";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  getData: () => void;
  regionOptions: any;
  roleOptions: any;
}

type AddUserFields = {
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  region: string;
  role: string;
};

const AddUserModal = ({
  isOpen,
  handleModalClose,
  getData,
  regionOptions,
  roleOptions,
}: Props) => {
  const { control, handleSubmit } = useForm<AddUserFields>({
    defaultValues: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      region: "",
      role: "",
    },
  });

  const onSubmit = handleSubmit((data: AddUserFields) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/app/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.userName,
        password: data.password,
        region: data.region,
        role: data.role,
        activeTo: "2999-12-12",
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          getData();
          handleModalClose();
          toast.success("Added successfully");
        } else {
          toast.error("Error occurred");
        }
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log("err", err);
      });
  });

  return (
    <form onSubmit={onSubmit}>
      <ModalComponent
        isOpen={isOpen}
        handleClose={handleModalClose}
        title='Add user'
        dialogActions={
          <Box display='flex' sx={{ p: 2 }}>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={onSubmit}>Save</Button>
          </Box>
        }
      >
        <Box sx={{ width: "100%" }}>
          <Input
            name='firstName'
            label='First Name'
            control={control}
            required
          />
          <Input name='lastName' label='Last Name' control={control} required />
          <Input
            name='userName'
            label='User name (e-mail)'
            control={control}
            type='email'
            required
          />
          <Input
            name='password'
            label='Password'
            control={control}
            type='password'
            required
          />

          <SelectComponent
            options={regionOptions}
            name='region'
            label='Region'
            fullWidth
            control={control}
          />
          <SelectComponent
            options={roleOptions}
            name='role'
            label='Role'
            fullWidth
            control={control}
          />
        </Box>
      </ModalComponent>
    </form>
  );
};

export default AddUserModal;
