import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#074762",
      light: "#2D8DAB",
    },
    secondary: {
      main: "#ECECEC",
      dark: "#848585",
      light: "#DBDEE2",
    },
    success: {
      light: "#C7DFD4",
      main: "#46926C",
    },
    error: {
      light: "#E6C8C8",
      main: "#A1312F",
    },
  },
});
