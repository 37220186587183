import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, SelectChangeEvent} from "@mui/material";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

import React from "react";

interface Props {
  options: any[];
  name: string;
  label: string;
  fullWidth?: boolean;
  control?: any;
  value?: string[];
  variant?: "standard" | "outlined" | "filled";
  required?: boolean;
  multiple?: boolean;
  onChange?: any
}

const SelectMultipleComponent = React.forwardRef(
  (
    {
      options,
      name,
      label,
      fullWidth,
      control,
      value,
      variant,
      required,
      multiple,
      onChange,
      ...rest
    }: Props,
    ref
  ) => {
    return (
      <FormControl
        fullWidth
        variant={variant || "standard"}
        sx={{ my: 1 }}
        {...rest}
      >
        <InputLabel id={name}>{label}</InputLabel>
        <Controller
          render={({ field }) => (
            <Select {...field} required={required} multiple={multiple} value={value} renderValue={(selected) => selected.join(',')} onChange={onChange}>
              {options
                ? options.map((option: any) => (
                    <MenuItem
                      key={option.label}
                      value={option.value}
                    >
                        <Checkbox checked={value != null ? value.indexOf(option.value) > -1 : false} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                  ))
                : []}
            </Select>
          )}
          name={name}
          control={control}
        />
      </FormControl>
    );
  }
);

export default SelectMultipleComponent;
