import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

type Props = {
  id?: number;
  fullName: string;
  date: string;
  message: string;
};

const MessageContentCard = ({
  id,
  fullName,
  date,
  message,
}: Props) => {
  return (
    <Box my={2}>
      <hr />
      <Box
        mb={1}
        mt={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle2">{fullName}</Typography>
        <Typography variant="body2">{date}</Typography>
      </Box>
      <Typography mt={1} variant="subtitle1">
        <i>{message}</i>
      </Typography>
    </Box>
  );
};

export default MessageContentCard;
