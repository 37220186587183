import {
  Box,
  Typography,
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "./inputs/Input";
import { toast } from "react-toastify";
import ChangePasswordModal from "./users/ChangePasswordModal";

interface UserData {
  firstName: string;
  lastName: string;
  userName: string;
  role: string;
  id: string;
}

interface Props {
  userData: UserData;
  setEdit: (edit: boolean) => void;
}

const EditUserProfile = ({ userData, setEdit }: Props) => {
  const { firstName, lastName, userName, role } = userData;
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [closeMenu, setCloseMenu] = useState(false);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      userName: userName ? userName : "",
      role: role ? role : "",
      password: "",
      repeatPassword: "",
    },
  });

  useEffect(() => {
    reset({
      firstName,
      lastName,
      userName,
      role,
    });
  }, [userData]);

  const handleOpen = (fn: any, row?: any) => {
    setCloseMenu(true);

    fn(true);
  };

  const handleModalClose = (fn: any) => {
    setCloseMenu(false);
    fn(false);
  };

  const onSubmit = handleSubmit((data) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/app/user/edit?userName=${userName}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          userName: data.userName,
          role: data.role,
          activeTo: "2999-12-12",
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Edited successfully");
        } else {
          toast.error("Error occurred");
        }
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
  });

  return (
    <>
      <>
        <Box sx={{ display: "inline-block", justifyContent: "space-between", px: 2, py: 1 }}>
          <Typography variant="h6">Profile</Typography>
        </Box>
        <Box sx={{ display: "inline-block", float: "right"}}>
          <Button sx={{ marginRight: "1em" }} variant="outlined" onClick={() => handleOpen(setChangePasswordOpen)}>
            Change password
          </Button>
          <Button variant="outlined" onClick={() => setEdit(true)}>
            Edit
          </Button>
        </Box>
        <Box sx={{ display: "flex", px: 2, py: 1 }}>
          <form style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", width: "48%", marginRight: 2 }}>
                <Box sx={{ width: "100%" }}>
                  <Input
                    control={control}
                    name="firstName"
                    label="First name"
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "48%" }}>
                <Box sx={{ width: "100%" }}>
                  <Input control={control} name="lastName" label="Last name" />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", width: "48%", marginRight: 2 }}>
                <Box sx={{ width: "100%" }}>
                  <Input
                    control={control}
                    name="userName"
                    label="User name (e-mail)"
                    disabled={role !== "ADMIN" ? true : false}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "48%" }}>
                <Box sx={{ width: "100%" }}>
                  <Input control={control} name="role" label="Role" disabled />
                </Box>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", width: "48%", marginRight: 2 }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Box sx={{ width: "90%" }}>
                    <Input
                      control={control}
                      name='password'
                      label='Password'
                      type={showPassword ? "text" : "password"}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", width: "10%" }}
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: "flex", width: "48%" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Box sx={{ width: "90%" }}>
                    <Input
                      control={control}
                      name='repeatPassword'
                      label='Repeat password'
                      type={showRepeatPassword ? "text" : "password"}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", width: "10%" }}
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowRepeatPassword}
                    >
                      {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box> */}
          </form>
        </Box>
      </>
      <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, py: 1 }}>
        <Button variant="text" onClick={() => setEdit(false)}>
          Cancel
        </Button>
        <Button variant="text" onClick={onSubmit}>
          Save
        </Button>
      </Box>
      <ChangePasswordModal
          getData={() => null}
          isOpen={changePasswordOpen}
          user=""
          handleModalClose={() => handleModalClose(setChangePasswordOpen)}
      />
    </>
  );
};

export default EditUserProfile;
