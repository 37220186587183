import { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';

const usePriceListProducts = (priceListId?: Number | null) => {
    const [priceListProductsLoading, setPriceListProductsLoading] = useState(false);
    const [priceListProducts, setPriceListProducts] = useState<any[]>([]);

    useEffect(() => {
        if (!priceListId) return;
        setPriceListProductsLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/app/product/getActiveByPricelist?priceListId=${priceListId}`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setPriceListProducts(res.data);
            })
            .catch((err) => {
                toast.error('Error during loading price list');
                console.log(err);

            })
            .finally(() => {
                setPriceListProductsLoading(false);
            });

    }, [priceListId]);

    return {
        priceListProductsLoading, priceListProducts
    }
}

export default usePriceListProducts;