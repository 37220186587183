import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import SelectComponent from "../inputs/Select";
import ModalComponent from "../Modal";
import axios from "axios";
import { toast } from "react-toastify";

type Props = {
  handleModalClose: () => void;
  isOpen: boolean;
  project: any;
  getData: () => void;
};

const EditStatusModal = ({
  handleModalClose,
  isOpen,
  project,
  getData,
}: Props) => {
  const options = [
    {
      id: 1,
      label: "SUCCESS",
      value: "SUCCESS",
    },
    {
      id: 2,
      label: "DRAFT",
      value: "DRAFT",
    },
    {
      id: 3,
      label: "FAIL",
      value: "FAIL",
    },
    {
      id: 4,
      label: "ACCEPTED",
      value: "ACCEPTED",
    },
    {
      id: 5,
      label: "DECLINED",
      value: "DECLINED",
    },
    {
      id: 6,
      label: "ACCEPT1RQ",
      value: "ACCEPT1RQ",
    },
    {
      id: 7,
      label: "ACCEPT2RQ",
      value: "ACCEPT2RQ",
    },
    {
      id: 8,
      label: "ARCHIVED",
      value: "ARCHIVED",
    },
  ];

  const { id, projectNumber, status } = project;

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      status,
    },
  });

  useEffect(() => {
    reset({
      status,
    });
  }, [project]);

  const onSubmit = handleSubmit((data) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/offer/changestatus?offerId=${id}&status=${data.status}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        handleModalClose();
        toast.success("Status has been changed");
        getData();
      })
      .catch((err) => {
        toast.error("Status has not been changed");
        console.log(err);
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title="Edit Status"
      dialogActions={
        <Box display="flex" sx={{ p: 2 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={onSubmit}>Save</Button>
        </Box>
      }
    >
      <Box sx={{ width: "100%" }}>
        <h3>Edit status for project: {projectNumber}</h3>
        <SelectComponent
          control={control}
          name="status"
          label="status"
          options={options}
        />
      </Box>
    </ModalComponent>
  );
};

export default EditStatusModal;
