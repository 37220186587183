import { Alert, Box, Button, TextField } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import Input from "../inputs/Input";
import SelectComponent from "../inputs/Select";
import SearchSelect from "../inputs/SearchSelect";
import axios from "axios";
import { toast } from "react-toastify";
import usePriceListProducts from "./hooks/usePriceListProducts";
import useGetCompetition from "./hooks/useGetCompetition";
import { useAuthContext } from "../../hooks/useAuth";
import {
  getValue,
  getFunPriceProfitControl,
  getFunPriceSalesman,
  getSalesmanProfit,
  getProfitProjectControl,
  getDiscountFromPL,
  getMarginTransferPrice,
  getProjectPrice,
} from "./tools/productCalculator";
import { formatValueToPLN } from "../helpers/currencyFormatter";
import {da} from "date-fns/locale";

interface Props {
  setAddProducts: (value: boolean) => void;
  setProducts: (value: any) => void;
  products: any[];
  isEdit?: boolean;
  offerId?: number;
  getData: () => void;
  currentPriceListId?: Number | null;
  customerId?: string | number | null;
  customerCategory: string | null;
}

const AddProductsModal = ({
  setAddProducts,
  setProducts,
  products,
  isEdit,
  offerId,
  getData,
  currentPriceListId,
  customerId,
  customerCategory
}: Props) => {
  const { priceListProducts } = usePriceListProducts(currentPriceListId);
  const [selectedOrderCode, setOrderCode] = useState<any>("");
  const [proposedPrice, setProposedPrice] = useState<Number>(1);

  const { competition } = useGetCompetition();

  useEffect(() => {
    const dataToFill: any = priceListProducts.find(
      (p: any) => p.orderCode === selectedOrderCode
    );

    const categoryDiscount: any = dataToFill?.productDiscounts.find(
        (categoryDiscount: any) => categoryDiscount.category == customerCategory
    );

    setValue("materialDescription", dataToFill?.materialDescription);
    setValue("priceListPrice", dataToFill?.priceListPrice);
    setValue("transferPrice", dataToFill?.transferPrice);
    setValue("baseProductId", dataToFill?.id);
    setValue("salesmanProfitAmount", categoryDiscount?.discountSMMax);
    setValue("allowedProfitForGroup", categoryDiscount?.discountTLMax);

    console.log(categoryDiscount?.discountSMMax);
    console.log(categoryDiscount?.discountTLMax);

    // setValue(
    //   "salesmanProfitAmount",
    //   dataToFill?.productDiscounts?.discountSMMax
    // );
    // setValue(
    //   "allowedProfitForGroup",
    //   dataToFill?.productDiscounts?.discountTLMax
    // );
  }, [selectedOrderCode]);

  const priceListProductsOptions = priceListProducts.map(
    ({ id, orderCode }: any) => {
      return {
        id,
        label: orderCode,
        value: orderCode,
      };
    }
  );

  const { control, setValue, register, watch, handleSubmit } = useForm({
    defaultValues: {
      materialDescription: "",
      baseProductId: 1,
      orderCode: "",
      projectPrice: 0,
      quantity: 0,
      value: 0,
      salesmanProfit: 0,
      profitProjectControl: 0,
      priceListPrice: 0,
      discountFromPL: 0,
      funpriceSalesman: 0,
      funpriceProfitControl: 0,
      competitor: "",
      competitorPrice: "",
      transferPrice: 0,
      marginTransferPrice: 0,
      salesmanProfitAmount: "",
      allowedProfitForGroup: "",
      manualDiscount: 0
    },
  });

  const currentCompetitor = watch("competitor");

  const competitorId = competition.find(
    (c: any) => c.value === currentCompetitor
  )?.id;

  const currentProductId = priceListProducts.find(
    (p: any) => p.orderCode === selectedOrderCode
  )?.id;

  const manualDiscount = watch("manualDiscount");

  useEffect(() => {
    const projectPrice = getProjectPrice(watch("priceListPrice"), manualDiscount)
    setValue("projectPrice", (isNaN(projectPrice)) ? 0 : projectPrice);
  }, [manualDiscount]);

  useEffect(() => {
    if (currentCompetitor && customerId && !isEdit) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/app/discounthistory/getProposal?competitorId=${competitorId}&customerId=${customerId}&discounterType=MEDIAN&priceListId=${currentPriceListId}&productId=${currentProductId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setProposedPrice(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [currentCompetitor, customerId]);

  // const getProductDiscounts = useCallback(
  //   (orderCode: string) => {
  //     const productDiscounts = priceListProducts.find(
  //       (p: any) => p.orderCode === orderCode
  //     );
  //     return productDiscounts?.productDiscounts;
  //   },
  //   [priceListProducts]
  // );
  //
  // const productDiscounts = getProductDiscounts(selectedOrderCode);
  //

  const salesmanProfitAmount = Number(watch("salesmanProfitAmount"));
  const allowedProfitForGroup = Number(watch("allowedProfitForGroup"));

  // const projectPrice = getProjectPrice(
  //     watch("priceListPrice"),
  //     watch("customField")
  // );
  // setValue("projectPrice", Number(projectPrice.toFixed(2)))

  const value = getValue(watch("projectPrice"), watch("quantity"));
  setValue("value", Number(value.toFixed(2)))

  const funpriceProfitControl = getFunPriceProfitControl(
    watch("priceListPrice"),
    allowedProfitForGroup
  );
  setValue("funpriceProfitControl", Number(funpriceProfitControl));

  const funpriceSalesman = getFunPriceSalesman(
    watch("priceListPrice"),
    salesmanProfitAmount
  );
  setValue("funpriceSalesman", Number(funpriceSalesman))

  const salesmanProfit = getSalesmanProfit(
    value,
    watch("priceListPrice"),
    watch("quantity"),
    salesmanProfitAmount
  );
  setValue("salesmanProfit", salesmanProfit)

  const profitProjectControl = getProfitProjectControl(
    value,
    watch("priceListPrice"),
    watch("quantity"),
    allowedProfitForGroup
  );
  setValue("profitProjectControl", Number(profitProjectControl));

  const discountFromPL = getDiscountFromPL(
    watch("priceListPrice"),
    watch("projectPrice"),
  );
  setValue("discountFromPL", Number(discountFromPL))

  const marginTransferPrice = getMarginTransferPrice(
    watch("projectPrice"),
    watch("transferPrice"),
    watch("quantity")
  );
  setValue("marginTransferPrice", Number(marginTransferPrice));

  const auth = useAuthContext();
  const { userInfo } = auth;
  const userRole = userInfo?.role;

  const onSubmit = handleSubmit((data) => {
    let valid = true
    let errorMessage = '';
    if (data.quantity < 1) {
      valid = false;
      errorMessage = 'The quantity of the product cannot be less than 1';
    }
    if (!Number.isInteger(Number(data.quantity))) {
      valid = false;
      errorMessage = 'The quantity must be an integer';
    }
    if (!valid) {
      toast.error(errorMessage);
      return;
    }

    if (isEdit) {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/add?offerId=${offerId}`,
          [
            {
              baseProductId: data.baseProductId,
              competitor: data.competitor,
              competitorPrice: data.competitorPrice,
              discountFromPL: data.discountFromPL,
              funpriceProfitControl: data.funpriceProfitControl,
              funpriceSalesman: data.funpriceSalesman,
              marginTransferPrice: data.marginTransferPrice,
              profitProjectControl: data.profitProjectControl,
              projectPrice: data.projectPrice,
              quantity: data.quantity,
              salesmanProfit: data.salesmanProfit,
              value: data.value,
            },
          ],
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          getData();
          toast.success("Product added successfully");
          setAddProducts(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
          console.log(err);
        });
    } else {
      var lastLocalId = products.length > 0 ? products[products.length - 1].local_id + 1 : 1;
      setProducts([...products, { ...data, local_id: lastLocalId }]);
      setAddProducts(false);
    }
  });

  return (
    <Box sx={{ width: "100%", height: 900, overflowY: "scroll", px: 1 }}>
      <form>
        {currentPriceListId === null ? (
          <Alert severity="info">
            No Price List selected. Please select one from the list. Go step
            back.
          </Alert>
        ) : null}
        <SearchSelect
          control={control}
          name="orderCode"
          label="Order Code"
          options={priceListProductsOptions}
          required
          setSelectedValue={setOrderCode}
        />
        {selectedOrderCode !== "" ? (
          <>
            <Input
              control={control}
              name="materialDescription"
              label="Material description"
              required
            />
            {!customerId && !competitorId && !isEdit && (
              <Alert severity="info">
                To see the proposed price, you must select a Competitor and
                Customer
              </Alert>
            )}
            <Input
              control={control}
              name="projectPrice"
              label="Project Price"
              type="number"
              required
            />
            {customerId && competitorId && !isEdit && (
              <TextField
                sx={{ my: 1 }}
                fullWidth
                variant="standard"
                value={formatValueToPLN(proposedPrice)}
                InputProps={{
                  readOnly: true,
                }}
                label="Proposed Price"
              />
            )}
            <Input
              control={control}
              name="quantity"
              label="Quantity"
              type="number"
              required
            />
            <SelectComponent
              control={control}
              name="competitor"
              label="Competitor"
              options={competition}
            />
            <Input
              control={control}
              name="competitorPrice"
              label="Competitor Price"
              type="number"
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("value")}
              value={formatValueToPLN(value)}
              InputProps={{
                readOnly: true,
              }}
              label="Value"
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("salesmanProfit")}
              value={formatValueToPLN(salesmanProfit)}
              InputProps={{
                readOnly: true,
              }}
              label="Salesman Profit"
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("profitProjectControl")}
              value={formatValueToPLN(profitProjectControl)}
              InputProps={{
                readOnly: true,
              }}
              label="Profit Project Control"
            />
            <Input
              control={control}
              name="priceListPrice"
              label="Pricelist Price"
              type="number"
              readOnly
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("discountFromPL")}
              value={discountFromPL}
              InputProps={{
                readOnly: true,
              }}
              label="Discount from PL (%)"
            />
            <Input
                control={control}
                name="manualDiscount"
                label="Manual discount"
                type="number"
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("funpriceSalesman")}
              value={formatValueToPLN(funpriceSalesman)}
              InputProps={{
                readOnly: true,
              }}
              label="Funprice Salesman"
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("funpriceProfitControl")}
              value={formatValueToPLN(funpriceProfitControl)}
              InputProps={{
                readOnly: true,
              }}
              label="Funprice Profit Control"
            />
            {userRole === "USER" ? (
              (register("transferPrice"),
              setValue("marginTransferPrice", Number(marginTransferPrice)))
            ) : (
              <>
                <Input
                  control={control}
                  name="transferPrice"
                  label="Transfer Price"
                  type="number"
                  readOnly
                />
                <TextField
                  sx={{ my: 1 }}
                  fullWidth
                  variant="standard"
                  {...register("marginTransferPrice")}
                  value={formatValueToPLN(marginTransferPrice)}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Margin Transfer Price"
                />
              </>
            )}
          </>
        ) : null}
      </form>
      <Box display="flex" justifyContent="flex-end" pt={4}>
        <Button onClick={() => setAddProducts(false)}>Cancel</Button>
        <Button onClick={onSubmit}>Save</Button>
      </Box>
    </Box>
  );
};

export default AddProductsModal;
