import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

const useCompetitionShortNames = () => {
    const [loadingCompetitionShortNames, setLoadingCompetitionShortNames] = useState(false);
    const [competitionShortNames, setCompetitionShortNames] = useState<string[]>([]);

    useEffect(() => {
        setLoadingCompetitionShortNames(true);
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/app/competitor/get/comp`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setCompetitionShortNames(res.data as string[]);
            })
            .catch((err) => {
                toast.error('Error during loading competition');
                console.log(err);
            }).finally(() => setLoadingCompetitionShortNames(false));
    }, []);

    return { loadingCompetitionShortNames, competitionShortNames }
}

export default useCompetitionShortNames;