import {TableCell, IconButton, MenuItem, Autocomplete, TextField} from "@mui/material";
import React, { ReactNode } from "react";
import { formatValueToPLN } from "../helpers/currencyFormatter";
import { TableColumnType } from "./types";

import Input from "@material-ui/core/Input";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";
import DoneIcon from "@mui/icons-material/DoneAllTwoTone";
import RevertIcon from "@mui/icons-material/NotInterestedOutlined";
import Select from "@mui/material/Select";

type Option = {
    id: number;
    label: string;
    value: string;
};

interface CellProps<T> {
  children?: ReactNode;
  column: TableColumnType<T>;
  rowData?: T;
  rowIndex: number;
  sx?: object;
  onClick?: () => void;
  onClickEdit?: () => void;
  onClickSave?: () => void;
  onClickCancel?: () => void;
  rowsEditStatus?: Map<number, boolean>;
  competitionData?: Array<any>;
  orderCodeData?: any[];
  changeInputData?: any;
}

/**
 * Array of project's attribute names to reformat and localize
 */
 const projectCurrencyTypeAttributes = [
  "competitorPrice",
  "funpriceProfitControl",
  "funpriceSalesman",
  "marginTransferPrice",
  "offeredPrice",
  "priceListPrice",
  "profitProjectControl",
  "projectPrice",
  "proposedPriceAccOne",
  "proposedPriceAccTwo",
  "salesmanProfit",
  "transferPrice",
  "value",
  "sum",
  "totalityProjectProfitControl",
  "sumMarginTransferPrice"
]

function Cell<T>({
  children: childrenProp,
  column,
  rowData,
  rowIndex,
  sx,
  onClick,
  onClickEdit,
  onClickSave,
  onClickCancel,
  rowsEditStatus,
  competitionData,
  changeInputData,
  orderCodeData
}: CellProps<T>) {
  const { children: render, dataKey, align = "left" as any, width } = column;

  let inputValues = new Map();
  let children;
  let id : any;
  let isEdited: any;
  let isNew: any;
  let orderCode: any;
  let competitorValue: string | null = null;

  if (rowData !== undefined && rowData !== null) {
      id = rowData['id' as keyof typeof rowData];
      isEdited = rowData['isEdited' as keyof typeof rowData];
      isNew = rowData['isNew' as keyof typeof rowData];
      orderCode = rowData['orderCode' as keyof typeof rowData];
  }

  if (childrenProp) {
    children = childrenProp;
  } else if (rowData) {
    if (render) {
      children = render(rowData, rowIndex);
    } else if (dataKey) {
      if (projectCurrencyTypeAttributes.includes(String(dataKey))) {
        children = formatValueToPLN(rowData[dataKey]);
      } else {
        children = rowData[dataKey];
        if (dataKey === 'competitor') {
            competitorValue = String(rowData[dataKey]);
        }
      }
    }
  }

  const onInputChange = (value : any, key: string) => {
      if (key === 'orderCode') {
          changeInputData({
              id: id,
              orderCode: value,
              key: key,
              value: value
          });
      } else {
          changeInputData({
              id: id,
              orderCode: orderCode,
              key: key,
              value: value
          });
      }
  }

  if (column.dataKey === 'editRow') {
      if (childrenProp !== undefined) {
          return (
              <TableCell
                  align={align}
                  sx={sx}
                  style={{ width: width || 100 }}
              >
                  {children}
              </TableCell>
          );
      } else {
          if (rowData !== undefined && rowData !== null && id !== undefined) {
              if (isEdited === true) {
                  return (
                      <TableCell
                          align={align}
                          sx={sx}
                          style={{ width: width || 100 }}
                      >
                          <IconButton
                              aria-label="done"
                              onClick={onClickSave}
                          >
                              <DoneIcon />
                          </IconButton>
                          <IconButton
                              aria-label="revert"
                              onClick={onClickCancel}
                          >
                              <RevertIcon />
                          </IconButton>
                      </TableCell>
                  )
              }
          }
          return (
              <TableCell
                  align={align}
                  sx={sx}
                  style={{ width: width || 100 }}
              >
                  <IconButton
                      aria-label="edit"
                      onClick={onClickEdit}
                  >
                      <EditIcon />
                  </IconButton>
              </TableCell>
          );
      }
  } else {
      //if (rowsEditStatus?.get(id) && column.editable) {
      if (rowData !== undefined && isEdited === true && column.editable) {
          if (column.inputType === 'number') {
              return (
                  <TableCell
                      align={align}
                      sx={sx}
                      style={{ width: width || 100 }}
                      onClick={onClick}
                  >
                      <Input
                          defaultValue={children}
                          //value={children}
                          name={String(dataKey)}
                          type="number"
                          onChange={e => onInputChange(e.target.value, String(dataKey))}
                      />
                  </TableCell>
              );
          }
          if (column.inputType === 'select') {
              return (
                  <TableCell
                      align={align}
                      sx={sx}
                      style={{ width: width || 100 }}
                      onClick={onClick}
                  >
                      <Select variant="standard" defaultValue={competitorValue} onChange={e => onInputChange(e.target.value, String(dataKey))}>
                          {competitionData
                              ? competitionData.map((option: any) => (
                                  <MenuItem
                                      key={option.label}
                                      value={option.value}
                                  >
                                      {option.label}
                                  </MenuItem>
                              ))
                              : []}
                      </Select>
                  </TableCell>
              );
          }
          return (
              <TableCell
                  align={align}
                  sx={sx}
                  style={{ width: width || 100 }}
                  onClick={onClick}
              >
                  <Input
                      defaultValue={children}
                      name={'name'}
                  />
              </TableCell>
          );
      }
      else {
          if (column.inputType === 'autocomplete' && isNew) {
              return (
                  <TableCell
                      align={align}
                      sx={sx}
                      style={{ width: width || 100 }}
                      onClick={onClick}
                  >
                      <Autocomplete
                          onChange={(e, data) => {
                              onInputChange(data?.value, String(dataKey))
                          }}
                          //onChange={e => onInputChange(e.target.value, String(dataKey))}
                          options={orderCodeData!.map((option: Option) => ({
                              id: option.id,
                              label: option.label,
                              value: option.value,
                          }))}
                          // loading={loading}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  //label={'label'}
                                  variant={"standard"}
                                  required={true}
                              />
                          )}
                      />
                  </TableCell>
              );
          }
      }
      return (
          <TableCell
              align={align}
              sx={sx}
              style={{ width: width || 100 }}
              onClick={onClick}
          >
              {children}
          </TableCell>
      );
  }
}

export default Cell;
