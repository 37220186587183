import { useState, useEffect } from 'react';
import { Box, Button, IconButton, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FloatingMenu from '../floatingMenu';
import ModalComponent from '../Modal';
import TableComponent from '../table/Table';
import { TableColumnType } from '../table/types';
import CloseIcon from '@mui/icons-material/Close';
import DrawerComponent from '../DrawerComponent';
import Input from '../inputs/Input';
import DiscountListEditModal from './DiscountListEditModal';
import DiscountListDeleteModal from './DiscountListDeleteModal';
import DiscountListAddModal from './DiscountListAddModal';
import {ca} from "date-fns/locale";

interface Props {
    discounts?: any;
    isOpen: boolean;
    handleModalClose: () => void;
}

const DiscountListModal = ({ discounts, isOpen, handleModalClose }: Props) => {
    const [drawer, setDrawer] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortDirection, setSortDirection] = useState<string>('ASC');
    const [data, setData] = useState<any>({});
    const [closeMenu, setCloseMenu] = useState(false);
    const [productsToEdit, setProductsToEdit] = useState<any>({});
    const [isAddOpen, setAddOpen] = useState(false);
    const [isEditOpen, setEditOpen] = useState(false);
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [updateTable, setUpdateTable] = useState(false);
    const [filters, setFilters] = useState({
        discountGroupFilter: '',
        // maxDiscountISFilter: '',
        // maxDiscountSMFilter: '',
        // maxDiscountTLFilter: '',
        // minDiscountISFilter: '',
        // minDiscountSMFilter: '',
        // minDiscountTLFilter: '',

        categoryCMaxDiscountSMFilter: '',
        categoryCMaxDiscountTLFilter: '',
        categoryCMinDiscountSMFilter: '',
        categoryCMinDiscountTLFilter: '',

        categoryOEMMaxDiscountSMFilter: '',
        categoryOEMMaxDiscountTLFilter: '',
        categoryOEMMinDiscountSMFilter: '',
        categoryOEMMinDiscountTLFilter: '',

        categoryEUMaxDiscountSMFilter: '',
        categoryEUMaxDiscountTLFilter: '',
        categoryEUMinDiscountSMFilter: '',
        categoryEUMinDiscountTLFilter: '',

        productAreaCodeFilter: '',
        productAreaNameFilter: '',
        productGroupCodeFilter: '',
        productGroupNameFilter: '',
    });
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            discountGroupFilter: '',
            // maxDiscountISFilter: '',
            // maxDiscountSMFilter: '',
            // maxDiscountTLFilter: '',
            // minDiscountISFilter: '',
            // minDiscountSMFilter: '',
            // minDiscountTLFilter: '',

            categoryCMaxDiscountSMFilter: '',
            categoryCMaxDiscountTLFilter: '',
            categoryCMinDiscountSMFilter: '',
            categoryCMinDiscountTLFilter: '',

            categoryOEMMaxDiscountSMFilter: '',
            categoryOEMMaxDiscountTLFilter: '',
            categoryOEMMinDiscountSMFilter: '',
            categoryOEMMinDiscountTLFilter: '',

            categoryEUMaxDiscountSMFilter: '',
            categoryEUMaxDiscountTLFilter: '',
            categoryEUMinDiscountSMFilter: '',
            categoryEUMinDiscountTLFilter: '',

            productAreaCodeFilter: '',
            productAreaNameFilter: '',
            productGroupCodeFilter: '',
            productGroupNameFilter: '',
        },
    });

    const formatAsNumber = (dataNumber: any) => {
        const result = `${dataNumber / 100}`;
        return result;
    };

    useEffect(() => {
        if (isOpen) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_API_BASE_URL
                    }/app/discount/get/p/discountlist/sort/filter?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&discountListIdFilter=${
                        discounts?.id
                    }
					${filters.discountGroupFilter !== '' ? `&discountGroupFilter=${filters.discountGroupFilter}` : ''}
					${
                        filters.categoryCMaxDiscountSMFilter !== ''
                            ? `&categoryCMaxDiscountSMFilter=${formatAsNumber(filters.categoryCMaxDiscountSMFilter)}`
                            : ''
                    }
					${
                        filters.categoryCMaxDiscountTLFilter !== ''
                            ? `&categoryCMaxDiscountTLFilter=${formatAsNumber(filters.categoryCMaxDiscountTLFilter)}`
                            : ''
                    }
					${
                        filters.categoryCMinDiscountSMFilter !== ''
                            ? `&categoryCMinDiscountSMFilter=${formatAsNumber(filters.categoryCMinDiscountSMFilter)}`
                            : ''
                    }
					${
                        filters.categoryCMinDiscountTLFilter !== ''
                            ? `&categoryCMinDiscountTLFilter=${formatAsNumber(filters.categoryCMinDiscountTLFilter)}`
                            : ''
                    }
                    
                    
                    
                    
                    ${
                        filters.categoryOEMMaxDiscountSMFilter !== ''
                            ? `&categoryOEMMaxDiscountSMFilter=${formatAsNumber(filters.categoryOEMMaxDiscountSMFilter)}`
                            : ''
                    }
					${
                        filters.categoryOEMMaxDiscountTLFilter !== ''
                            ? `&categoryOEMMaxDiscountTLFilter=${formatAsNumber(filters.categoryOEMMaxDiscountTLFilter)}`
                            : ''
                    }
					${
                        filters.categoryOEMMinDiscountSMFilter !== ''
                            ? `&categoryOEMMinDiscountSMFilter=${formatAsNumber(filters.categoryOEMMinDiscountSMFilter)}`
                            : ''
                    }
					${
                        filters.categoryOEMMinDiscountTLFilter !== ''
                            ? `&categoryOEMMinDiscountTLFilter=${formatAsNumber(filters.categoryOEMMinDiscountTLFilter)}`
                            : ''
                    }
                    
                    
                    
                    ${
                        filters.categoryEUMaxDiscountSMFilter !== ''
                            ? `&categoryEUMaxDiscountSMFilter=${formatAsNumber(filters.categoryEUMaxDiscountSMFilter)}`
                            : ''
                    }
					${
                        filters.categoryEUMaxDiscountTLFilter !== ''
                            ? `&categoryEUMaxDiscountTLFilter=${formatAsNumber(filters.categoryEUMaxDiscountTLFilter)}`
                            : ''
                    }
					${
                        filters.categoryEUMinDiscountSMFilter !== ''
                            ? `&categoryEUMinDiscountSMFilter=${formatAsNumber(filters.categoryEUMinDiscountSMFilter)}`
                            : ''
                    }
					${
                        filters.categoryEUMinDiscountTLFilter !== ''
                            ? `&categoryEUMinDiscountTLFilter=${formatAsNumber(filters.categoryEUMinDiscountTLFilter)}`
                            : ''
                    }
                    
                    
                    
					${
                        filters.productAreaCodeFilter !== ''
                            ? `&productAreaCodeFilter=${filters.productAreaCodeFilter}`
                            : ''
                    }
					${
                        filters.productAreaCodeFilter !== ''
                            ? `&productAreaCodeFilter=${filters.productAreaCodeFilter}`
                            : ''
                    }
					${
                        filters.productAreaNameFilter !== ''
                            ? `&productAreaNameFilter=${filters.productAreaNameFilter}`
                            : ''
                    }
					${
                        filters.productGroupCodeFilter !== ''
                            ? `&productGroupCodeFilter=${filters.productGroupCodeFilter}`
                            : ''
                    }
					${
                        filters.productGroupNameFilter !== ''
                            ? `&productGroupNameFilter=${filters.productGroupNameFilter}`
                            : ''
                    }
					`,

                    {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                        },
                    }
                )
                .then(res => {
                    setData(res.data);
                })
                .catch(err => {
                    toast.error('Error occurred');
                    console.log(err);
                });
        }
    }, [isOpen, filters, sortField, sortDirection, page, rowsPerPage, updateTable]);

    const formatAsPercentage = (dataNumber: any) => `${Math.round(dataNumber * 100)}%`;

    const discountData = data?.discounts?.map((el: any) => {
        let discountSMMaxC = '-';
        let discountTLMaxC = '-';
        let discountSMMaxOEM = '-';
        let discountTLMaxOEM = '-';
        let discountSMMaxEU = '-';
        let discountTLMaxEU = '-';

        el.discountCategories.map((category: any) => {
            if (category.category == 'C') {
                discountSMMaxC = category.discountSMMax;
                discountTLMaxC = category.discountTLMax;
            }
            if (category.category == 'OEM') {
                discountSMMaxOEM = category.discountSMMax;
                discountTLMaxOEM = category.discountTLMax;
            }
            if (category.category == 'EU') {
                discountSMMaxEU = category.discountSMMax;
                discountTLMaxEU = category.discountTLMax;
            }
        });

        return {
            discountGroup: el.discountGroup !== null ? el.discountGroup : '-',
            discountSMMaxC: discountSMMaxC,
            discountTLMaxC: discountTLMaxC,
            discountSMMaxOEM: discountSMMaxOEM,
            discountTLMaxOEM: discountTLMaxOEM,
            discountSMMaxEU: discountSMMaxEU,
            discountTLMaxEU: discountTLMaxEU,
            id: el.id !== null ? el.id : '-',
            productAreaCode: el.productAreaCode !== null ? el.productAreaCode : '-',
            productAreaName: el.productAreaName !== null ? el.productAreaName : '-',
            productGroupCode: el.productGroupCode !== null ? el.productGroupCode : '-',
            productGroupName: el.productGroupName !== null ? el.productGroupName : '-',
        };
    });

    // const discountData = data?.discounts?.map((el: any) => ({
    // 	discountGroup: el.discountGroup !== null ? el.discountGroup : '-',
    // 	discountSMMaxC: '-',
    // 	discountTLMaxC: '-',
    // 	discountSMMaxOEM: '-',
    // 	discountTLMaxOEM: '-',
    // 	discountSMMaxEND: '-',
    // 	discountTLMaxEND: '-',
    // 	// discountISMax: el.discountISMax !== null ? formatAsPercentage(el.discountISMax) : '-',
    // 	// discountSMMax: el.discountSMMax !== null ? formatAsPercentage(el.discountSMMax) : '-',
    // 	// discountTLMax: el.discountTLMax !== null ? formatAsPercentage(el.discountTLMax) : '-',
    //
    // 	id: el.id !== null ? el.id : '-',
    // 	productAreaCode: el.productAreaCode !== null ? el.productAreaCode : '-',
    // 	productAreaName: el.productAreaName !== null ? el.productAreaName : '-',
    // 	productGroupCode: el.productGroupCode !== null ? el.productGroupCode : '-',
    // 	productGroupName: el.productGroupName !== null ? el.productGroupName : '-',
    // }));
    const totalItems = data?.totalItems;

    const handleFilterSubmit = handleSubmit(data => {
        setFilters({
            discountGroupFilter: data.discountGroupFilter,
            // maxDiscountISFilter: data.maxDiscountISFilter,
            // maxDiscountSMFilter: data.maxDiscountSMFilter,
            // maxDiscountTLFilter: data.maxDiscountTLFilter,
            // minDiscountISFilter: data.minDiscountISFilter,
            // minDiscountSMFilter: data.minDiscountSMFilter,
            // minDiscountTLFilter: data.minDiscountTLFilter,

            categoryCMaxDiscountSMFilter: data.categoryCMaxDiscountSMFilter,
            categoryCMaxDiscountTLFilter: data.categoryCMaxDiscountTLFilter,
            categoryCMinDiscountSMFilter: data.categoryCMinDiscountSMFilter,
            categoryCMinDiscountTLFilter: data.categoryCMinDiscountTLFilter,

            categoryOEMMaxDiscountSMFilter: data.categoryOEMMaxDiscountSMFilter,
            categoryOEMMaxDiscountTLFilter: data.categoryOEMMaxDiscountTLFilter,
            categoryOEMMinDiscountSMFilter: data.categoryOEMMinDiscountSMFilter,
            categoryOEMMinDiscountTLFilter: data.categoryOEMMinDiscountTLFilter,

            categoryEUMaxDiscountSMFilter: data.categoryEUMaxDiscountSMFilter,
            categoryEUMaxDiscountTLFilter: data.categoryEUMaxDiscountTLFilter,
            categoryEUMinDiscountSMFilter: data.categoryEUMinDiscountSMFilter,
            categoryEUMinDiscountTLFilter: data.categoryEUMinDiscountTLFilter,

            productAreaCodeFilter: data.productAreaCodeFilter,
            productAreaNameFilter: data.productAreaNameFilter,
            productGroupCodeFilter: data.productGroupCodeFilter,
            productGroupNameFilter: data.productGroupNameFilter,
        });
        setDrawer(false);
    });

    const handleFilterClear = () => {
        reset();
    };

    const handleOpen = (fn: any, row?: any) => {
        setCloseMenu(true);
        fn(true);
        if (row) {
            setProductsToEdit(row);
        }
    };

    const handleProductsModalClose = (fn: any) => {
        setCloseMenu(false);
        fn(false);
    };

    const forceUpdate = () => {
        setUpdateTable(!updateTable);
    };

    const columns: TableColumnType<any>[] = [
        {
            title: 'Discount Group',
            dataKey: 'discountGroup',
            width: 100,
            sort: true,
        },

        {
            title: 'Product Area Code',
            dataKey: 'productAreaCode',
            width: 100,
            sort: true,
        },
        {
            title: 'Product Area Name',
            dataKey: 'productAreaName',
            width: 100,
            sort: true,
        },
        {
            title: 'Product Group Code',
            dataKey: 'productGroupCode',
            width: 100,
            sort: true,
        },
        {
            title: 'Discount Category C',
            dataKey: 'a',
            width: 200,
            sort: false,
            subColumns: [
                {
                    title: 'Salesman Max',
                    dataKey: 'discountSMMaxC',
                    width: 100,
                    sort: false,
                },
                {
                    title: 'Team Leader',
                    dataKey: 'discountTLMaxC',
                    width: 100,
                    sort: false,
                }
            ]
        },
        {
            title: 'Discount Category EU',
            dataKey: 'c',
            width: 200,
            sort: false,
            subColumns: [
                {
                    title: 'Salesman Max',
                    dataKey: 'discountSMMaxEU',
                    width: 100,
                    sort: false,
                },
                {
                    title: 'Team Leader',
                    dataKey: 'discountTLMaxEU',
                    width: 100,
                    sort: false,
                }
            ]
        },
        {
            title: 'Discount Category OEM',
            dataKey: 'b',
            width: 200,
            sort: false,
            subColumns: [
                {
                    title: 'Salesman Max',
                    dataKey: 'discountSMMaxOEM',
                    width: 100,
                    sort: false,
                },
                {
                    title: 'Team Leader',
                    dataKey: 'discountTLMaxOEM',
                    width: 100,
                    sort: false,
                }
            ]
        }
        // {
        // 	title: 'Discount PL [IS Leader]',
        // 	dataKey: 'discountISMax',
        // 	width: 100,
        // 	sort: true,
        // },
    ];

    columns.push({
        title: 'Actions',
        dataKey: 'action',
        align: 'right',
        sort: false,
        width: 50,
        children: row => {
            return (
                <FloatingMenu
                    close={closeMenu}
                    menuItems={[
                        <MenuItem key={1} onClick={() => handleOpen(setEditOpen, row)}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                        </MenuItem>,
                        <MenuItem key={2} onClick={() => handleOpen(setDeleteOpen, row)}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                        </MenuItem>,
                    ]}
                />
            );
        },
    });

    return (
        <>
            <ModalComponent
                isOpen={isOpen}
                handleClose={handleModalClose}
                title="Discount List"
                w="xl"
                dialogActions={<Box display="flex" sx={{ p: 2 }}></Box>}
            >
                <TableComponent
                    columns={columns}
                    data={discountData}
                    onClickButtonVoid={() => handleOpen(setAddOpen)}
                    onClickButtonVoidText="Add"
                    noSearch
                    //pagination
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    page={page}
                    totalItems={totalItems}
                    filter={() => setDrawer(true)}
                    //sorting
                    sortField={sortField}
                    setSortField={setSortField}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                />

                <DrawerComponent drawer={drawer} setDrawer={setDrawer} zIndex={'10000'}>
                    <Box sx={{ mx: 2 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <h3>Filter</h3>
                            <IconButton onClick={() => setDrawer(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box>
                            <form>
                                <Input
                                    name="discountGroupFilter"
                                    control={control}
                                    label="Discount Group"
                                />
                                {/* <Input
									name="discountListIdFilter"
									control={control}
									label="Discount List Id"
								/> */}
                                {/*<Input*/}
                                {/*    name="maxDiscountISFilter"*/}
                                {/*    control={control}*/}
                                {/*    label="Max Discount IS (%)"*/}
                                {/*/>*/}
                                <p>Category C</p>
                                <Input
                                    name="categoryCMaxDiscountSMFilter"
                                    control={control}
                                    label="Max Discount Salesman (%)"
                                />
                                <Input
                                    name="categoryCMaxDiscountTLFilter"
                                    control={control}
                                    label="Max Discount Team Leader (%)"
                                />
                                {/*<Input*/}
                                {/*    name="minDiscountISFilter"*/}
                                {/*    control={control}*/}
                                {/*    label="Min Discount IS (%)"*/}
                                {/*/>*/}
                                <Input
                                    name="categoryCMinDiscountSMFilter"
                                    control={control}
                                    label="Min Discount Salesman (%)"
                                />
                                <Input
                                    name="categoryCMinDiscountTLFilter"
                                    control={control}
                                    label="Min Discount Team Leader (%)"
                                />

                                <p>Category OEM</p>
                                <Input
                                    name="categoryOEMMaxDiscountSMFilter"
                                    control={control}
                                    label="Max Discount Salesman (%)"
                                />
                                <Input
                                    name="categoryOEMMaxDiscountTLFilter"
                                    control={control}
                                    label="Max Discount Team Leader (%)"
                                />
                                <Input
                                    name="categoryOEMMinDiscountSMFilter"
                                    control={control}
                                    label="Min Discount Salesman (%)"
                                />
                                <Input
                                    name="categoryOEMMinDiscountTLFilter"
                                    control={control}
                                    label="Min Discount Team Leader (%)"
                                />

                                <p>Category EU</p>
                                <Input
                                    name="categoryEUMaxDiscountSMFilter"
                                    control={control}
                                    label="Max Discount Salesman (%)"
                                />
                                <Input
                                    name="categoryEUMaxDiscountTLFilter"
                                    control={control}
                                    label="Max Discount Team Leader (%)"
                                />
                                <Input
                                    name="categoryEUMinDiscountSMFilter"
                                    control={control}
                                    label="Min Discount Salesman (%)"
                                />
                                <Input
                                    name="categoryEUMinDiscountTLFilter"
                                    control={control}
                                    label="Min Discount Team Leader (%)"
                                />


                                <Input
                                    name="productAreaCodeFilter"
                                    control={control}
                                    label="Product Area Code"
                                />
                                <Input
                                    name="productAreaNameFilter"
                                    control={control}
                                    label="Product Area Name"
                                />
                                <Input
                                    name="productGroupCodeFilter"
                                    control={control}
                                    label="Product Group Code"
                                />
                                <Input
                                    name="productGroupNameFilter"
                                    control={control}
                                    label="Product Group Name"
                                />
                            </form>
                        </Box>
                        <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                            <Button color="primary" onClick={handleFilterClear}>
                                Clear
                            </Button>
                            <Button color="primary" onClick={handleFilterSubmit}>
                                Confirm
                            </Button>
                        </Box>
                    </Box>
                </DrawerComponent>
            </ModalComponent>
            <DiscountListEditModal
                isOpen={isEditOpen}
                handleModalClose={() => handleProductsModalClose(setEditOpen)}
                rowData={productsToEdit}
                forceUpdate={forceUpdate}
                pickedDiscountList={discounts}
            />
            <DiscountListDeleteModal
                isOpen={isDeleteOpen}
                handleModalClose={() => handleProductsModalClose(setDeleteOpen)}
                rowData={productsToEdit}
                forceUpdate={forceUpdate}
            />
            <DiscountListAddModal
                isOpen={isAddOpen}
                handleModalClose={() => handleProductsModalClose(setAddOpen)}
                forceUpdate={forceUpdate}
                pickedDiscountList={discounts}
            />
        </>
    );
};

export default DiscountListModal;
