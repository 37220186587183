import { TableRow } from "@mui/material";
import React from "react";
import Cell from "./cell";
import { getColumnKey } from "./getColumnKey";
import { TableColumnType } from "./types";

type Option = {
    id: number;
    label: string;
    value: string;
};

export interface BodyRowProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  rowData: T;
  rowIndex: number;
  setRowsEditStatus?: (id: number) => void;
  rowsEditStatus?: Map<number, boolean>;
  saveRowData?: any;
  competitionData?: Array<any>;
  changeInputData?: any;
  cancelEdit?: any;
  orderCodeData: Option[];
}

function bodyRow<T>(
    {
      columns,
      rowData,
      rowIndex,
      setRowsEditStatus,
      rowsEditStatus,
      saveRowData,
      competitionData,
      changeInputData,
      cancelEdit,
      orderCodeData
}: BodyRowProps<T>) {
  const rowEdit = (el: any) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    if (setRowsEditStatus) {
      //rowsEditStatus!.set(el.id, !rowsEditStatus!.get(el.id));
      //setRowsEditStatus(new Map(rowsEditStatus!));
      setRowsEditStatus(el.id);
    }
  };

  const saveRow = (el: any) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    saveRowData(el);
  };

  return (
    <TableRow>
      {columns.map((column, index) => {
        return (
          <Cell
            key={getColumnKey(column, index)}
            column={column}
            rowIndex={rowIndex}
            rowData={rowData}
            onClickEdit={() => rowEdit(rowData)}
            onClickSave={() => saveRow(rowData)}
            onClickCancel={() => cancelEdit(rowData)}
            rowsEditStatus={rowsEditStatus}
            competitionData={competitionData}
            changeInputData={changeInputData}
            orderCodeData={orderCodeData}
          />
        );
      })}
    </TableRow>
  );
}

export default bodyRow;
