import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import ModalComponent from '../Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, {useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility, VisibilityOff} from "@mui/icons-material";

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	user?: any;
	getData: () => void;
}

const ChangePasswordModal = ({ isOpen, handleModalClose, user, getData }: Props) => {
	const formSchema = Yup.object().shape({
		currentPassword: Yup.string()
			.required('Current Password is required')
			.min(1, 'Password length should be at least 4 characters')
			.max(50, 'Password length should not exceed 50 characters'),
		password: Yup.string()
			.required('Password is required')
			.min(1, 'Password length should be at least 4 characters')
			.max(50, 'Password length should not exceed 50 characters'),
		confirmPassword: Yup.string()
			.required('Confirm Password is required')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	});

	const validationOpt = { resolver: yupResolver(formSchema) };
	const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
	const [passwordShown, setPasswordShown] = useState(false);
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<any>(validationOpt);

	const onSubmit = (data: any) => {
		axios
			.put(
				`${process.env.REACT_APP_API_BASE_URL}/app/user/change/password
				`,
				{
					currentPassword: data.currentPassword,
					newPassword: data.password
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Password changed successfully');
				handleModalClose();
				reset();
			})
			.catch(err => {
				if (err.response.status == 422) {
					toast.error('Current password is not correct');
				} else {
					toast.error('Error occurred');
				}
				console.log(err);
			});
	};

	const toggleCurrentPassword = () => {
		setCurrentPasswordShown(!currentPasswordShown);
	};
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};
	const toggleConfirmPassword = () => {
		setConfirmPasswordShown(!confirmPasswordShown);
	};

	return (
		<form>
			<ModalComponent
				isOpen={isOpen}
				handleClose={handleModalClose}
				title="Change Password"
				dialogActions={
					<Box display="flex" sx={{ p: 2 }}>
						<Button onClick={handleModalClose}>Cancel</Button>
						<Button onClick={handleSubmit(onSubmit)}>Save</Button>
					</Box>
				}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<TextField
							fullWidth
							id="currentPassword"
							label="Current Password"
							variant="standard"
							type={currentPasswordShown ? "text" : "password"}
							{...register('currentPassword')}
							required
							error={errors.currentPassword ? true : false}
						/>
						<Typography variant="body1" sx={{ color: 'red' }}>
							{errors.currentPassword?.message}
						</Typography>
						<IconButton onClick={toggleCurrentPassword} color="primary" sx={{ marginTop: '15px', marginRight: '10px'}}>
							{currentPasswordShown ? (<VisibilityOff />) : (<Visibility />)}
						</IconButton>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row'}}>
						<TextField
							fullWidth
							id="password"
							label="Password"
							variant="standard"
							type={passwordShown ? "text" : "password"}
							{...register('password')}
							required
							error={errors.password ? true : false}
						/>
						<Typography variant="body1" sx={{ color: 'red' }}>
							{errors.password?.message}
						</Typography>
						<IconButton onClick={togglePassword} color="primary" sx={{ marginTop: '15px', marginRight: '10px'}}>
							{passwordShown ? (<VisibilityOff />) : (<Visibility />)}
						</IconButton>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row'}}>
						<TextField
							fullWidth
							label="Confirm Password"
							id="confirmPassword"
							variant="standard"
							type={confirmPasswordShown ? "text" : "password"}
							{...register('confirmPassword')}
							required
							error={errors.confirmPassword ? true : false}
						/>
						<Typography variant="body1" sx={{ color: 'red' }}>
							{errors.confirmPassword?.message}
						</Typography>
						<IconButton onClick={toggleConfirmPassword} color="primary" sx={{ marginTop: '15px', marginRight: '10px'}}>
							{confirmPasswordShown ? (<VisibilityOff />) : (<Visibility />)}
						</IconButton>
					</Box>
				</Box>
			</ModalComponent>
		</form>
	);
};

export default ChangePasswordModal;
