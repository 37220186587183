import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  open: boolean;
}

const OverlayLoading = ({ open }: Props) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export default OverlayLoading;
