import { TableColumnType } from "./types";
import Cell from "./cell";
import {IconButton, TableRow} from "@mui/material";
import { getColumnKey } from "./getColumnKey";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

export interface HeaderRowProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  sortField?: any;
  setSortField?: (value: any) => void;
  sortDirection?: any;
  setSortDirection?: (value: any) => void;
  saveRowData?: any;
  addRow?: any;
}

function HeaderRow<T>({
  columns,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
  addRow
}: HeaderRowProps<T>) {
  const handleDataSort = (el: any, sortOrder: any) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setSortField(el.dataKey);
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setSortDirection(sortOrder);
  };

  return (
    <TableRow>
      {columns &&
        sortField &&
        columns.map((column, index) => (
          // TODO: fix issue with unique "key" prop
          <>
            {column.dataKey === 'editRow' && (
                <Cell
                    key={getColumnKey(column, index)}
                    column={column}
                    rowIndex={NaN}
                    sx={{
                      color: "secondary.dark",
                    }}
                >
                  <IconButton
                      aria-label="add"
                      onClick={addRow}
                  >
                    <AddIcon />
                  </IconButton>
                </Cell>
            )}
            {column.dataKey !== 'editRow' && column.sort && sortField !== column.dataKey && (
              <Cell
                key={getColumnKey(column, index)}
                column={column}
                rowIndex={NaN}
                sx={{
                  color: "secondary.dark",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => handleDataSort(column, "ASC")}
              >
                {column.title} <div></div>
              </Cell>
            )}

            {column.dataKey !== 'editRow' && column.sort &&
              sortField === column.dataKey &&
              sortDirection === "ASC" && (
                <Cell
                  key={getColumnKey(column, index)}
                  column={column}
                  rowIndex={NaN}
                  sx={{
                    color: "secondary",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={() => handleDataSort(column, "DESC")}
                >
                  {column.title}
                  <ArrowDropUpIcon
                    sx={{
                      position: "absolute",
                    }}
                  />
                </Cell>
              )}

            {column.dataKey !== 'editRow' && column.sort &&
              sortField === column.dataKey &&
              sortDirection === "DESC" && (
                <Cell
                  key={getColumnKey(column, index)}
                  column={column}
                  rowIndex={NaN}
                  sx={{
                    color: "secondary",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={() => handleDataSort(column, "ASC")}
                >
                  {column.title}
                  <ArrowDropDownIcon
                    sx={{
                      position: "absolute",
                    }}
                  />
                </Cell>
              )}

            {column.dataKey !== 'editRow' && !column.sort && (
              <Cell
                key={getColumnKey(column, index)}
                column={column}
                rowIndex={NaN}
                sx={{
                  color: "secondary.dark",
                }}
              >
                {column.title}
              </Cell>
            )}
          </>
        ))}
    </TableRow>
  );
}

export default HeaderRow;
