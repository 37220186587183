import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import TableComponent from "../table/Table";
import { productTableColumns } from "./configuration/productTableColumns";
import useTablePagination from "../table/hooks/useTablePagination";
import useTableSorting from "../table/hooks/useTableSorting";
import useAddProductsFromCSV from "./hooks/useAddProductsFromCSV";
import { TableColumnType } from "../table/types";

type AddProductsFromCSVModalProps = {
  close(): void;
  currentPriceListId?: Number | null;
  save(products: any[]): void | Promise<void>;
  userRole?: string;
  lastLocalId? : Number;
  customerCategory: string | null;
};

const AddProductsFromCSVModal = ({
  close,
  currentPriceListId,
  save,
  userRole,
  lastLocalId,
  customerCategory
}: AddProductsFromCSVModalProps): JSX.Element => {
  const tablePagination = useTablePagination();
  const tableSorting = useTableSorting("id");
  const {
    isProcessing,
    fileName,
    handleFileAdded,
    products,
    onConfirm,
    onClose,
  } = useAddProductsFromCSV(close, save, customerCategory, currentPriceListId, lastLocalId);

  const additionalColumns: TableColumnType<any>[] = [
    {
      title: "Salesman Profit",
      dataKey: "salesmanProfit",
      align: "center",
      sort: true,
      width: 150,
    },
    {
      title: "Profit Project Control",
      children: ({ profitProjectControl }) => profitProjectControl.toFixed(2),
      align: "center",
      sort: true,
      width: 150,
    },
    {
      title: "Fun Price Salesman",
      children: ({ funpriceSalesman }) => funpriceSalesman.toFixed(2),
      align: "center",
      sort: true,
      width: 30,
    },
    {
      title: "Fun Price Profit Control",
      children: ({ funpriceProfitControl }) => funpriceProfitControl.toFixed(2),
      align: "center",
      sort: true,
      width: 100,
    },
    {
      title: "Margin transfer price",
      children: ({ marginTransferPrice }) =>
        userRole !== "USER" ? marginTransferPrice.toFixed(2) : "-",
      align: "center",
      sort: true,
      width: 100,
    },
  ];

  return (
    <Box sx={{ width: "99%", height: "auto", overflowX: "scroll" }}>
      {isProcessing ? (
        <CircularProgress />
      ) : (
        <>
          <Button variant="contained" component="label">
            Select file
            <input
              type={"file"}
              accept={".csv"}
              onChange={(e) => handleFileAdded(e)}
              hidden
            />
          </Button>
          {fileName && <span>&nbsp;Loaded file: {fileName}</span>}
          {products.length > 0 && (
            <TableComponent
              data={products}
              columns={[...productTableColumns, ...additionalColumns]}
              totalItems={products.length}
              noSearch
              {...tablePagination}
              {...tableSorting}
            />
          )}
          <Box display="flex" justifyContent="flex-end" pt={4}>
            <Button onClick={onClose}>Cancel</Button>
            <Button disabled={products.length === 0} onClick={onConfirm}>
              Confirm
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddProductsFromCSVModal;
