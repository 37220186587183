import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import ComponentCard from "../ComponentCard";
import ProjectsRaport from "./ProjectsReport";
import ProductsRaport from "./ProductsReport";
import ProjectSumReport from "./ProjectsSumReport";
import ProductsSumReport from "./ProductsSumReport";

const Reports = () => {
  const [tab, setTab] = useState(0);

  const checkStatus = (status: string) => {
    if (status === "sukces") {
      return "success";
    }
    if (status === "brak statusu") {
      return "default";
    }
    if (status === "niepowodzenie") {
      return "error";
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  return (
    <ComponentCard>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Projects report" {...a11yProps(0)} />
          <Tab label="Projects sum report" {...a11yProps(1)} />
          <Tab label="Products report" {...a11yProps(2)} />
          <Tab label="Products sum report" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <ProjectsRaport />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ProjectSumReport />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ProductsRaport />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <ProductsSumReport />
      </TabPanel>
    </ComponentCard>
  );
};

export default Reports;
