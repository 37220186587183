import { curry, flow, trim } from "lodash";

/**
 * Provides a function which converts value to number
 */
const toNumber = curry((val: any) => Number(val) || 0);

/**
 * Provides a function which converts provided number to localized currency
 * and returns an array of objects containing the locale-specific tokens
 * for future formatting
 */
const toLocalizedParts = curry((value: number) =>
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  /**
   * Why locales: "de"? 
   * In "pl" single thousands are not separated, 
   * e.g. 1000 is converted to "1000,00", not "1 000.00" as we need 
   * (this works "fine" with higher numbers e.g. 14000 -> "14 000.00")
   * "de" provides thousand separation in all cases
   * 
   * Why currencyDisplay: "symbol"? 
   * With locales: "de" we will get currency symbol "PLN" in from of value. 
   * It's nicer to have "zł" suffix instead (replaced in adjustSeparators)
   */
  new Intl.NumberFormat("de", {
    style: "currency",
    currency: "pln",
    currencyDisplay: "symbol",
    maximumFractionDigits: 2
  }).formatToParts(value)
);

/**
 * Provides a function which adjusts group and decimal separators
 */
const adjustSeparators = curry((parts: Array<Intl.NumberFormatPart>) =>
  parts.map((part) => {
    if (part.type === "group") {
      // make sure digit groups are separated with ','
      part.value = ",";
    } else if (part.type === "decimal") {
      // make sure decimal part is separated with '.'
      part.value = ".";
    } else if (part.type === "currency") {
      // replace "PLN" with nicer "zł"
      part.value = "";
    }
    return part;
  })
);

/**
 * Joins values of provided array of objects containing the locale-specific tokens
 */
const joinValues = curry((parts: Array<Intl.NumberFormatPart>): string =>
  parts.map((part) => part.value).join("")
);

/**
 * Formats provided value to localized value in PLN
 * @param value value to convert
 * @returns formatted and localized value in PLN
 */
export const formatValueToPLN = (value: any): string => {
  return flow(toNumber, toLocalizedParts, adjustSeparators, joinValues, trim)(value);
};
