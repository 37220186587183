import { Button, Table, Box, TablePagination, IconButton } from "@mui/material";
import SearchInput from "../inputs/SearchInput";
import Body from "./body";
import Header from "./header";
import { TableColumnType, TableGetRowKey, TableSize } from "./types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export type AdditionalAction = {
  text: string;
  action(): void;
  disabled: boolean;
};

type Option = {
  id: number;
  label: string;
  value: string;
};

export interface TableProps<T> {
  columns?: ReadonlyArray<TableColumnType<T>>;
  data?: ReadonlyArray<T>;
  competitionData?: Array<any>;
  disableHeader?: boolean;
  size?: TableSize;
  getRowKey?: TableGetRowKey<T>;
  onClickButtonVoid?: () => void;
  onClickButtonVoidText?: string;
  onClickRemoveFilter?: (data: any) => void;
  filter?: () => void;
  filters?: any;
  noSearch?: boolean;
  noPagination?: boolean;
  setRowsPerPage?: (value: any) => void;
  rowsPerPage?: any;
  page?: any;
  setPage?: (value: any) => void;
  totalItems?: any;
  sortField?: any;
  setSortField?: (value: any) => void;
  sortDirection?: any;
  setSortDirection?: (value: any) => void;
  additionalActions?: AdditionalAction[];
  rowsEditStatus?: Map<number, boolean>;
  //setRowsEditStatus?: (value: Map<number, boolean>) => void;
  setRowsEditStatus?: (id: number) => void;
  saveRowData?: any;
  cancelEdit?: any;
  changeInputData?: any;
  addRow?: any;
  orderCodeData: Option[];
}

function TableComponent<T>({
  columns,
  data,
  competitionData,
  getRowKey,
  onClickButtonVoid,
  onClickButtonVoidText,
  filter,
  filters,
  onClickRemoveFilter,
  noSearch,
  noPagination,
  setRowsPerPage,
  rowsPerPage,
  page,
  setPage,
  totalItems,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
  additionalActions,
  setRowsEditStatus,
  rowsEditStatus,
  saveRowData,
  cancelEdit,
  changeInputData,
  addRow,
  orderCodeData
}: TableProps<T>) {
  const handleChangePage = (event: unknown, newPage: number) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setRowsPerPage(+event.target.value);
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setPage(0);
  };

  const removeCurrentFilter = (data : any) => {
    if (onClickRemoveFilter) {
      onClickRemoveFilter(data);
    }
  };

  const anyFilterActive = () => {
    let emptyFilters = false;
    if (filters !== undefined) {
      Object.keys(filters).map(function(key) {
        if (filters[key].length > 0) {
          emptyFilters = true;
        }
      });
    }
    return emptyFilters;
  }

  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: noSearch ? "flex-end" : "space-between",
          p: 1,
          mb: 5,
        }}
      >
        {noSearch ? null : <SearchInput />}
        <Box>
          {filters && filters.customerInnerId.length > 0 && (
              <Button
                  key="customerInnerId"
                  variant="contained"
                  onClick={() => removeCurrentFilter("customerInnerId")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Customer Number: {filters.customerInnerId}
              </Button>
          )
          }
          {filters && filters.customerName.length > 0 && (
                  <Button
                      key="customerName"
                      variant="contained"
                      onClick={() => removeCurrentFilter("customerName")}
                      style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
                  >
                    Customer Name: {filters.customerName}
                  </Button>
              )
          }
          {filters && filters.offerNumber.length > 0 && (
              <Button
                  key="offerNumber"
                  variant="contained"
                  onClick={() => removeCurrentFilter("offerNumber")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Project Number: {filters.offerNumber}
              </Button>
          )
          }
          {filters && filters.updateDateFrom.length > 0 && (
              <Button
                  key="updateDateFrom"
                  variant="contained"
                  onClick={() => removeCurrentFilter("updateDateFrom")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Last updated from: {filters.updateDateFrom}
              </Button>
          )
          }
          {filters && filters.updateDateTo.length > 0 && (
              <Button
                  key="updateDateTo"
                  variant="contained"
                  onClick={() => removeCurrentFilter("updateDateTo")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Last updated to: {filters.updateDateTo}
              </Button>
          )
          }
          {filters && filters.offerStatus.length > 0 && (
              <Button
                  key="offerStatus"
                  variant="contained"
                  onClick={() => removeCurrentFilter("offerStatus")}
                  style={{ marginRight: "5px", backgroundColor: "darkslategrey" }}
              >
                Status: {filters.offerStatus}
              </Button>
          )
          }
          {filter && (
            <IconButton onClick={filter} sx={{ mx: 2, ...(anyFilterActive() && { backgroundColor: "#e0e0e0" }) }}>
              <FilterAltIcon />
            </IconButton>
          )}
          {additionalActions &&
            additionalActions.map((el) => (
              <Button
                key={`${el.text}`}
                variant="contained"
                disabled={!el.disabled}
                onClick={el.action}
                style={{ marginRight: "5px" }}
              >
                {el.text}
              </Button>
            ))}
          {onClickButtonVoid ? (
            <Button variant="contained" onClick={onClickButtonVoid}>
              {onClickButtonVoidText || "Add"}
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          {columns && setSortField && (
            <>
              <Header
                columns={columns}
                sortField={sortField}
                setSortField={setSortField}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                addRow={addRow}
              />
              <Body
                  columns={columns}
                  data={data}
                  competitionData={competitionData}
                  getRowKey={getRowKey}
                  setRowsEditStatus={setRowsEditStatus}
                  rowsEditStatus={rowsEditStatus}
                  saveRowData={saveRowData}
                  cancelEdit={cancelEdit}
                  changeInputData={changeInputData}
                  orderCodeData={orderCodeData}
              />
            </>
          )}
        </Table>
      </Box>
      {noPagination ? null : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={totalItems ? totalItems : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default TableComponent;
