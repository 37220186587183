import { StateInterface, ActionType } from "../types";

const Reducer = (state: StateInterface, { payload, type }: ActionType): any => {
  switch (type) {
    case "SET_USER_DATA":
      return {
        ...state,
        userData: [...state.userData, payload],
      };
    default:
      return state;
  }
};

export default Reducer;
