import { Box, Button, Checkbox } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectComponent from "../inputs/Select";
import ModalComponent from "../Modal";
import axios from "axios";
import { toast } from "react-toastify";

type Props = {
  handleModalClose: () => void;
  isOpen: boolean;
  project: any;
  getData: () => void;
};

const AcceptLvlOneModal = ({
  handleModalClose,
  isOpen,
  project,
  getData,
}: Props) => {
  const options = [
    {
      id: 1,
      label: "ACCEPT OFFER",
      value: "ACCEPT",
    },
    {
      id: 2,
      label: "DECLINE OFFER",
      value: "DECLINE",
    },
    {
      id: 3,
      label: "SEND TO ACCEPT2RQ",
      value: "SEND_TO_ACCEPT2RQ",
    },
  ];

  const { id, projectNumber } = project;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      offerStatus: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    switch (data.offerStatus) {
      case "ACCEPT":
        axios
          .put(
            `${process.env.REACT_APP_API_BASE_URL}/app/offer/accept1?acceptTwoReq=false&isAccepted=true&offerId=${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            handleModalClose();
            console.log(res);
            toast.success("Offer accepted");
            getData();
          })
          .catch((err) => {
            toast.error("Offer has not been accepted");
            console.log(err);
          });
        break;
      case "DECLINE":
        axios
          .put(
            `${process.env.REACT_APP_API_BASE_URL}/app/offer/accept1?acceptTwoReq=false&isAccepted=false&offerId=${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            handleModalClose();
            console.log(res);
            toast.success("Offer declined");
            getData();
          })
          .catch((err) => {
            toast.error("Offer has not been declined");
            console.log(err);
          });
        break;
      case "SEND_TO_ACCEPT2RQ":
        axios
          .put(
            `${process.env.REACT_APP_API_BASE_URL}/app/offer/accept1?acceptTwoReq=true&isAccepted=false&offerId=${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            handleModalClose();
            console.log(res);
            toast.success("Offer sent to accept2rq");
            getData();
          })
          .catch((err) => {
            toast.error("Offer has not been sent to accept2rq");
            console.log(err);
          });
        break;
      default:
        break;
    }
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title={`Accept Level One for ${projectNumber}`}
      dialogActions={
        <Box display="flex" sx={{ p: 2 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={onSubmit}>Save</Button>
        </Box>
      }
    >
      <Box sx={{ width: "100%" }}>
        <SelectComponent
          control={control}
          name="offerStatus"
          label="status"
          options={options}
        />
      </Box>
    </ModalComponent>
  );
};

export default AcceptLvlOneModal;
