import { TableHead } from "@mui/material";
import HeaderRow from "./headerRow";
import { TableColumnType } from "./types";

interface HeaderProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  sortField?: any;
  setSortField?: (value: any) => void;
  sortDirection?: any;
  setSortDirection?: (value: any) => void;
}

function Header<T>({
  columns,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
}: HeaderProps<T>) {
  return (
    <TableHead>
      <HeaderRow
        columns={columns}
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
    </TableHead>
  );
}

export default Header;
