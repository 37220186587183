import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import FloatingMenu from "../floatingMenu";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ComponentCard from "../ComponentCard";
import DrawerComponent from "../DrawerComponent";
import Input from "../inputs/Input";
import CloseIcon from "@mui/icons-material/Close";
import useFetch from "../../hooks/useFetch";
import DeleteClientModal from "./ClientsDeleteModal";
import ClientsImportModal from "./ClientsImportModal";
import ClientsEditModal from "./ClientsEditModal";

interface Props {
  userRole: string;
}
const ClientsTable = ({ userRole }: Props) => {
  const [drawer, setDrawer] = React.useState<boolean>(false);
  // pagination
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(5);
  const [page, setPage] = React.useState<any>(0);
  // sorting
  const [sortField, setSortField] = React.useState<any>("customerName");
  const [sortDirection, setSortDirection] = React.useState<any>("ASC");

  // edit and delete
  const [userToEdit, setUserToEdit] = React.useState<any>({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteOpen, setDeleteOpen] = React.useState(false);
  const [isEditOpen, setEditOpen] = React.useState(false);

  // handle closign FloatingMenu after MenuItem click
  const [closeMenu, setCloseMenu] = React.useState(false);
  const [filters, setFilters] = React.useState({
    cityFilter: "",
    categoryFilter: "",
    customerNameFilter: "",
    innerIdCodeFilter: "",
    regionUnitFilter: "",
    responsibleUserNameFilter: "",
  });

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      cityFilter: "",
      categoryFilter: "",
      customerNameFilter: "",
      innerIdCodeFilter: "",
      regionUnitFilter: "",
      responsibleUserNameFilter: "",
    },
  });

  const handleOpen = (fn: any, row?: any) => {
    setCloseMenu(true);
    fn(true);
    if (row) {
      setUserToEdit(row);
    }
  };

  const handleModalClose = (fn: any) => {
    setCloseMenu(false);
    fn(false);
  };

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/customer/get/p/extended/sort/filter?activeFilter=true&cityFilter=${filters.cityFilter}&customerNameFilter=${filters.customerNameFilter}&innerIdCodeFilter=${filters.innerIdCodeFilter}&p=${page}&regionUnitFilter=${filters.regionUnitFilter}&categoryFilter=${filters.categoryFilter}&responsibleUserNameFilter=${filters.responsibleUserNameFilter}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}`
  );

  const customerData = data?.customers.map((el: any) => ({
    id: el.id !== null ? el.id : "-",
    innerIdCode: el.innerIdCode !== null ? el.innerIdCode : "-",
    customerName: el.customerName !== null ? el.customerName : "-",
    city: el.city !== null ? el.city : "-",
    responsibleUser: el.responsibleUser !== null ? el.responsibleUser : "-",
    region: el.region !== null ? el.region : "-",
    category: el.category !== null ? el.category: '-'
  }));
  const totalItems = data?.totalItems;

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      cityFilter: data.cityFilter,
      categoryFilter: data.categoryFilter,
      customerNameFilter: data.customerNameFilter,
      innerIdCodeFilter: data.innerIdCodeFilter,
      regionUnitFilter: data.regionUnitFilter,
      responsibleUserNameFilter: data.responsibleUserNameFilter,
    });
    getData();
    setDrawer(false);
  });

  const handleFilterClear = () => {
    reset();
  };

  const columns: TableColumnType<any>[] = [
    {
      title: "Customer Name",
      dataKey: "customerName",
      sort: true,
      width: 200,
    },
    {
      title: "City",
      dataKey: "city",
      align: "left",
      sort: true,
      width: 100,
    },
    {
      title: "Region",
      dataKey: "region",
      align: "left",
      sort: true,
      width: 100,
    },
    {
      title: "Category",
      dataKey: "category",
      align: "left",
      sort: true,
      width: 100,
    },
    {
      title: "Customer Number",
      dataKey: "innerIdCode",
      align: "left",
      sort: true,
      width: 100,
    },
    {
      title: "Responsible User",
      dataKey: "responsibleUser",
      align: "left",
      sort: true,
      width: 100,
    },
  ];

  if (userRole === "ADMIN") {
    columns.push({
      title: "Actions",
      dataKey: "action",
      align: "right",
      sort: false,
      width: 50,
      children: (row) => {
        return (
          <FloatingMenu
            close={closeMenu}
            menuItems={[
              <MenuItem key={1} onClick={() => handleOpen(setEditOpen, row)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </MenuItem>,
              <MenuItem key={2} onClick={() => handleOpen(setDeleteOpen, row)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary="Delete" />
              </MenuItem>,
            ]}
          />
        );
      },
    });
  }
  return (
    <ComponentCard>
      {userRole === "ADMIN" ? (
        <>
          <TableComponent
            data={customerData}
            columns={columns}
            noSearch={true}
            onClickButtonVoid={() => handleOpen(setIsOpen)}
            // data for pagination
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            page={page}
            totalItems={totalItems}
            filter={() => setDrawer(true)}
            // data for sorting
            sortField={sortField}
            setSortField={setSortField}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
          <DrawerComponent drawer={drawer} setDrawer={setDrawer}>
            <Box sx={{ mx: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>Filter</h3>
                <IconButton onClick={() => setDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                <form>
                  <Input name="cityFilter" control={control} label="City" />
                  <Input
                    name="customerNameFilter"
                    control={control}
                    label="Customer Name"
                  />
                  <Input
                    name="innerIdCodeFilter"
                    control={control}
                    label="Inner Id Code"
                  />
                  <Input
                    name="regionUnitFilter"
                    control={control}
                    label="Region Unit"
                  />
                  <Input
                      name="categoryFilter"
                      control={control}
                      label="Category"
                  />
                  <Input
                    name="responsibleUserNameFilter"
                    control={control}
                    label="Responsible User"
                  />
                </form>
              </Box>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" onClick={handleFilterClear}>
                  Clear
                </Button>
                <Button color="primary" onClick={handleFilterSubmit}>
                  Confirm
                </Button>
              </Box>
            </Box>
          </DrawerComponent>
        </>
      ) : (
        <TableComponent
          data={customerData}
          columns={columns}
          noSearch={true}
          // data for pagination
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          page={page}
          totalItems={totalItems}
          // data for sorting
          sortField={sortField}
          setSortField={setSortField}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      )}

      <ClientsImportModal
        getData={getData}
        isOpen={isOpen}
        handleModalClose={() => handleModalClose(setIsOpen)}
      />
      <DeleteClientModal
        getData={getData}
        userId={userToEdit.id}
        isOpen={isDeleteOpen}
        handleModalClose={() => handleModalClose(setDeleteOpen)}
      />
      <ClientsEditModal
        getData={getData}
        client={userToEdit}
        isOpen={isEditOpen}
        handleModalClose={() => handleModalClose(setEditOpen)}
      />
    </ComponentCard>
  );
};

export default ClientsTable;
