import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react';
import FloatingMenu from '../floatingMenu';
import TableComponent from '../table/Table';
import { TableColumnType } from '../table/types';
import EditIcon from '@mui/icons-material/Edit';
import ComponentCard from '../ComponentCard';
import useFetch from '../../hooks/useFetch';
import PriceListsEditModal from './PriceListsEditModal';

interface Props {
	userRole: string;
}

const PriceListsTable = ({ userRole }: Props) => {
	const [rowsPerPage, setRowsPerPage] = useState<any>(5);
	const [page, setPage] = useState<any>(0);
	const [sortField, setSortField] = useState<any>('id');
	const [sortDirection, setSortDirection] = useState<any>('ASC');
	const [userToEdit, setUserToEdit] = useState<any>({});
	const [isEditOpen, setEditOpen] = useState(false);
	const [closeMenu, setCloseMenu] = useState(false);

	const handleOpen = (fn: any, row?: any) => {
		setCloseMenu(true);
		fn(true);
		if (row) {
			setUserToEdit(row);
		}
	};

	const handleModalClose = (fn: any) => {
		setCloseMenu(false);
		fn(false);
	};

	const { data, getData } = useFetch(
		`${process.env.REACT_APP_API_BASE_URL}/app/pricelist/get/p/sort?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}`
	);

	const priceListsData = data?.priceLists?.map((el: any) => ({
		discountListName: el.discountListName !== null ? el.discountListName : '-',
		id: el.id !== null ? el.id : '-',
		importedAt: el.importedAt !== null ? el.importedAt : '-',
		lastUpdate: el.lastUpdate !== null ? el.lastUpdate : '-',
		name: el.name !== null ? el.name : '-',
		status: el.status ? 'Active' : 'Archived',
		validFrom: el.validFrom !== null ? el.validFrom : '-',
		validTo: el.validTo !== null ? el.validTo : '-',
	}));
	const totalItems = data?.totalItems;

	const columns: TableColumnType<any>[] = [
		{
			title: 'Name',
			dataKey: 'name',
			sort: true,
			width: 200,
		},
		{
			title: 'Discount List Name',
			dataKey: 'discountListName',
			sort: true,
			width: 200,
		},
		{
			title: 'Imported At',
			dataKey: 'importedAt',
			align: 'left',
			sort: true,
			width: 100,
		},
		{
			title: 'Last Update',
			dataKey: 'lastUpdate',
			align: 'left',
			sort: true,
			width: 100,
		},
		{
			title: 'Status',
			dataKey: 'status',
			align: 'left',
			sort: true,
			width: 100,
		},
		{
			title: 'Valid From',
			dataKey: 'validFrom',
			align: 'left',
			sort: true,
			width: 100,
		},
		{
			title: 'Valid To',
			dataKey: 'validTo',
			align: 'left',
			sort: true,
			width: 100,
		},
	];

	if (userRole === 'ADMIN') {
		columns.push({
			title: 'Actions',
			dataKey: 'action',
			align: 'right',
			sort: false,
			width: 50,
			children: row => {
				return (
					<FloatingMenu
						close={closeMenu}
						menuItems={[
							<MenuItem key={1} onClick={() => handleOpen(setEditOpen, row)}>
								<ListItemIcon>
									<EditIcon />
								</ListItemIcon>
								<ListItemText primary="Edit" />
							</MenuItem>,
						]}
					/>
				);
			},
		});
	}

	return (
		<ComponentCard>
			<TableComponent
				data={priceListsData}
				columns={columns}
				noSearch={true}
				// data for pagination
				setRowsPerPage={setRowsPerPage}
				rowsPerPage={rowsPerPage}
				setPage={setPage}
				page={page}
				totalItems={totalItems}
				// data for sorting
				sortField={sortField}
				setSortField={setSortField}
				sortDirection={sortDirection}
				setSortDirection={setSortDirection}
			/>

			<PriceListsEditModal
				getData={getData}
				rowData={userToEdit}
				isOpen={isEditOpen}
				handleModalClose={() => handleModalClose(setEditOpen)}
			/>
		</ComponentCard>
	);
};

export default PriceListsTable;
