import { Drawer, Toolbar } from "@mui/material";
import React from "react";

interface Props {
  drawer: boolean;
  setDrawer: (drawer: boolean) => void;
  children: React.ReactNode;
  anchor?: "left" | "top" | "right" | "bottom";
  zIndex?: string;
}

const DrawerComponent = ({
  drawer,
  setDrawer,
  children,
  anchor,
  zIndex,
}: Props) => {
  return (
    <Drawer
      anchor={anchor || "right"}
      variant="temporary"
      open={drawer}
      sx={{
        zIndex: { zIndex },
        [`& .MuiDrawer-paper`]: {
          width: 400,
          boxSizing: "border-box",
        },
      }}
      onClose={() => setDrawer(false)}
    >
      <Toolbar />
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
