import { InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";

interface Props {
  control: any;
  name: string;
  label: string;
  variant?: "standard" | "outlined" | "filled";
  required?: boolean;
  disabled?: boolean;
  type?: string;
  readOnly?: boolean;
  defaultValue?: string;
  rows: number;
}

const InputMultiline = ({
    name,
    control,
    label,
    variant = "standard",
    required,
    disabled,
    readOnly,
    type = "text",
    rows,
    defaultValue,
}: Props) => {
  return (
      <Box sx={{ my: 2 }}>
        <Controller
            render={({ field, fieldState: { error } }) => (
                <TextField
                    fullWidth
                    label={label}
                    variant={variant}
                    type={type}
                    required={required}
                    disabled={disabled}
                    error={error && true}
                    helperText={error && error.message}
                    defaultValue=""
                    multiline
                    rows={rows}
                    InputProps={{
                      readOnly: readOnly
                    }}
                    {...field}
                />
            )}
            name={name}
            control={control}
            rules={{
              required: { value: required || false, message: "Required field" },
              pattern:
                  name === "email"
                      ? {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      }
                      : undefined,
            }}
        />
      </Box>
  );
};

export default InputMultiline;
