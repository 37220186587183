import { Typography, Box } from "@mui/material";
import ClientsTable from "./ClientsTable";
import CompetitorsTable from "./CompetitorsTable";
import PriceLists from "./PriceLists";
import Discounts from "./Discounts";
import PriceListsTable from "./PriceListsTable";
import { useAuthContext } from "../../hooks/useAuth";

const Dictionary = () => {
  const auth = useAuthContext();
  const { userInfo } = auth;
  const userRole = userInfo?.role;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <PriceLists userRole={userRole} />
        </Box>
        <Box sx={{ width: "50%" }}>
          <Discounts userRole={userRole} />
        </Box>
      </Box>
      <Box sx={{ width: "100%", mx: 1, mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Competitors
        </Typography>
        <CompetitorsTable userRole={userRole} />
      </Box>
      <Box sx={{ width: "100%", mx: 1, mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Clients
        </Typography>
        <ClientsTable userRole={userRole} />
      </Box>
      <Box sx={{ width: "100%", mx: 1, mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Price Lists
        </Typography>
        <PriceListsTable userRole={userRole} />
      </Box>
    </>
  );
};

export default Dictionary;
