import React, { useState } from 'react';
import { AppBar, Box, Drawer, List, Toolbar, Typography, Avatar, Button } from '@mui/material';
import MenuItem from './MenuItem';
import { listItems } from './MenuNavigations';
import { useLocation, useHistory } from 'react-router';
import Logo from '../assets/balluf_logo.png';
import { Role } from '../hooks/useAuth';
import LogoutModal from './LogoutModal';

interface Props {
	children?: React.ReactNode;
	userName?: string;
	firstName?: string;
	lastName?: string;
	currentRole?: Role;
}

const Menu = ({ children, userName, currentRole, firstName, lastName }: Props) => {
	const [logoutModalOpen, setLogoutModalOpen] = useState(false);
	const drawerWidth = 250;
	const location = useLocation();
	const history = useHistory();

	const handleNavigation = () => {
		history.push('/');
	};

	const filteredListItems = listItems.filter(item =>
		currentRole ? item.role.includes(currentRole) : undefined
	);

	const initials =
		firstName && lastName ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` : '';

	const handleLogout = () => {
		setLogoutModalOpen(true);
	};

	const handleCloseModal = (fn: any) => {
		fn(false);
	};

	return (
		<Box sx={{ display: 'grid', gridTemplateColumns: '240px 1fr' }}>
			<AppBar
				position="fixed"
				sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
				elevation={0}
			>
				<Toolbar>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						<img src={Logo} alt="Balluf Logo" />
					</Typography>
					<Avatar sx={{ cursor: 'pointer' }} onClick={handleNavigation}>
						{initials}
					</Avatar>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				sx={{
					[`& .MuiDrawer-paper`]: {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
			>
				<Toolbar />
				<Box
					sx={{
						bgcolor: 'primary.main',
						height: '100%',
					}}
				>
					<List>
						{filteredListItems.map(({ name, link, icon }, i) => {
							return <MenuItem key={i} name={name} link={link} icon={icon} />;
						})}
					</List>
				</Box>
			</Drawer>
			<Box
				component="main"
				sx={{
					bgcolor: 'secondary.main',
					height: 'auto',
					minHeight: '100vh',
				}}
			>
				<Toolbar />
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						bgcolor: 'white',
						px: 4,
						py: 1,
					}}
				>
					<Typography variant="h5" component="div">
						{location.pathname === '/'
							? userName
							: listItems.find(item => item.link === location.pathname)?.name}
					</Typography>
					{location.pathname === '/' ? (
						<Button variant="contained" onClick={handleLogout}>
							Logout
						</Button>
					) : null}
				</Box>
				<Box sx={{ px: 4, py: 3 }}>{children}</Box>
			</Box>
			<LogoutModal
				isOpen={logoutModalOpen}
				handleModalClose={() => handleCloseModal(setLogoutModalOpen)}
			/>
		</Box>
	);
};

export default Menu;
