import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import Input from '../inputs/Input';
import ModalComponent from '../Modal';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	forceUpdate: () => void;
	pickedDiscountList?: any;
}

const DiscountListAddModal = ({
	isOpen,
	handleModalClose,
	forceUpdate,
	pickedDiscountList,
}: Props) => {
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			discountGroup: '',
			discountISMax: '',
			discountSMMax: '',
			discountTLMax: '',
			productAreaCode: '',
			productAreaName: '',
			productGroupCode: '',
			productGroupName: '',
		},
	});

	const formatAsPercentage = (dataNumber: any) => {
		const result = `${dataNumber / 100}`;
		return result;
	};

	const onSubmit = handleSubmit((data: any) => {
		axios
			.post(
				`${process.env.REACT_APP_API_BASE_URL}/app/discount/create?discountListId=${pickedDiscountList.id}
				`,
				{
					discountGroup: data.discountGroup,
					discountISMax: formatAsPercentage(data.discountISMax),
					discountSMMax: formatAsPercentage(data.discountSMMax),
					discountTLMax: formatAsPercentage(data.discountTLMax),
					productAreaCode: data.productAreaCode,
					productAreaName: data.productAreaName,
					productGroupCode: data.productGroupCode,
					productGroupName: data.productGroupName,
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Added successfully');
				forceUpdate();
				handleModalClose();
				reset();
			})
			.catch(err => {
				toast.error('Error occurred');
				console.log(err);
			});
	});

	return (
		<ModalComponent
			isOpen={isOpen}
			handleClose={handleModalClose}
			title="Add discount in discount list"
			w="xl"
		>
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="discountGroup" label="Discount Group" control={control} />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productAreaCode" control={control} label="Product Area Code" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productAreaName" control={control} label="Product Area Name" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productGroupCode" control={control} label="Product Group Code" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productGroupName" control={control} label="Product Group Name" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input
						name="discountSMMax"
						control={control}
						label="Discount PL [Salesman Max] (%)"
					/>
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input
						name="discountTLMax"
						control={control}
						label="Discount PL [Team Leader] (%)"
					/>
				</Box>

				<Box sx={{ width: '100%' }}>
					<Input
						name="discountISMax"
						label="Discount PL [IS Leader] (%)"
						control={control}
					/>
				</Box>
				<Box></Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>

					<Button onClick={onSubmit}>Save</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default DiscountListAddModal;
