import React, { useState } from "react";
import { IconButton, InputBase, Divider } from "@mui/material";
import { Box } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import axios from 'axios';

type MessageInputProps = {
  ticketId: string;
  onMessageSent(): void;
};

const MessageInput = ({ ticketId, onMessageSent }: MessageInputProps): JSX.Element => {
  const [message, setMessage] = useState<string>();

  const sendMessage = async (): Promise<void> => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/app/ticketmessage/new?ticketId=${ticketId}`, { message },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }
      });
    setMessage('');
    onMessageSent();
  }

  return (
    <>
      <hr />
      <Box display="flex" alignItems="center">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Message"
          inputProps={{ "aria-label": "search google maps" }}
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
        />
        <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions" onClick={sendMessage} disabled={!message}>
          <SendIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default MessageInput;
