import { Box } from "@mui/system";
import React from "react";
import ComponentCard from "../ComponentCard";
import useGetAllTickets from "./hooks/useGetAllTickets";
import MessageBox from "./MessageBox";
import UserList from "./UserList";

type MessagesContext = {
  loadTickets(): void;
};

export const MessagesContext = React.createContext<MessagesContext>({
  loadTickets: () => {},
});

const Messages = (): JSX.Element => {
  const [id, setId] = React.useState<number | undefined>(undefined);
  const { visibleData, showClosed, setShowClosed, setSearchString, getData } =
    useGetAllTickets();
  return (
    <MessagesContext.Provider value={{ loadTickets: getData }}>
      <Box display="flex" width="100%" height="85vh" gap={3}>
        <ComponentCard w="35%">
          <UserList
            setId={setId}
            showClosed={showClosed}
            visibleData={visibleData}
            changeShowClosed={() => setShowClosed(!showClosed)}
            setSearchString={(str: string) => setSearchString(str)}
          />
        </ComponentCard>
        <ComponentCard w="65%">
          <MessageBox id={id} />
        </ComponentCard>
      </Box>
    </MessagesContext.Provider>
  );
};

export default Messages;
