import { Button, Typography, Box } from "@mui/material";
import ModalComponent from "./Modal";
import useAuth from "../hooks/useAuth";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
}

const LogoutModal = ({ isOpen, handleModalClose }: Props) => {
  const { onLogout } = useAuth();

  return (
    <ModalComponent
      title="Logout Confirmation"
      isOpen={isOpen}
      handleClose={handleModalClose}
      isSmall
      dialogActions={
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ p: 2, width: 180 }}
        >
          <Button variant="outlined" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onLogout}>
            Logout
          </Button>
        </Box>
      }
    >
      <div>
        <Typography variant="h4">Are you sure you want to logout?</Typography>
      </div>
    </ModalComponent>
  );
};

export default LogoutModal;
