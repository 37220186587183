import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "../inputs/DatePicker";
import Input from "../inputs/Input";
import ModalComponent from "../Modal";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  discounts?: any;
  getData: () => void;
}

const DictionaryEditDiscountsModal = ({
  isOpen,
  handleModalClose,
  discounts,
  getData,
}: Props) => {
  const { id, name, validFrom, validTo } = discounts;

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      id,
      priceListName: name,
      validFrom,
      validTo,
    },
  });

  useEffect(() => {
    reset({
      id,
      priceListName: name,
      validFrom,
      validTo,
    });
  }, [discounts]);

  const onSubmit = handleSubmit((data) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/discountlist/updateByName?discountListName=${name}`,
        {
          name: data.priceListName,
          status: true,
          validFrom: dayjs(data.validFrom).format("YYYY-MM-DD"),
          validTo: dayjs(data.validTo).format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Edited successfully");
        getData();
        handleModalClose();
      })
      .catch((err) => {
        toast.error("Error occur");
        console.log(err);
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title="Discounts"
    >
      <form style={{ width: "100%", marginTop: -20 }}>
        <Box sx={{ width: "100%" }}>
          <Input name="priceListName" label="Name" control={control} required />
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <DatePicker
              fullWidth
              name="validFrom"
              control={control}
              label="Valid from: "
            />
          </Box>
          <Box sx={{ width: "100%", ml: 1 }}>
            <DatePicker
              fullWidth
              name="validTo"
              control={control}
              label="Valid to: "
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={onSubmit}>Save</Button>
        </Box>
      </form>
    </ModalComponent>
  );
};

export default DictionaryEditDiscountsModal;
