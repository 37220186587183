import { useState } from "react";
import { Box, IconButton, Button } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import ComponentCard from "../ComponentCard";
import TableComponent from "../table/Table";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../DrawerComponent";
import Input from "../inputs/Input";
import DatePicker from "../inputs/DatePicker";
import SearchSelect from "../inputs/SearchSelect";
import GenerateProductsSumReports from "./GenerateProductsSumReports";

const ProductsSumReport = () => {
  const sumOptions = [
    {
      id: 0,
      value: "ORDER_CODE",
      label: "ORDER CODE",
    },
    {
      id: 1,
      value: "PAPG",
      label: "PAPG",
    },
    {
      id: 2,
      value: "COMPETITOR",
      label: "COMPETITOR",
    },
  ];
  const [drawer, setDrawer] = useState<boolean>(false);
  const [generateReport, setGenerateReport] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("orderCode");
  const [sortDirection, setSortDirection] = useState<string>("ASC");

  const [filters, setFilters] = useState({
    closedAtDateFrom: "",
    closedAtDateTo: "",
    competitorFilter: "",
    maxMarginTransferPriceFilter: "",
    maxProfitProjectControlFilter: "",
    maxQuantityFilter: "",
    minMarginTransferPriceFilter: "",
    minProfitProjectControlFilter: "",
    minQuantityFilter: "",
    offerNumberFilter: "",
    orderCodeFilter: "",
    papgFilter: "",
    sumBy: sumOptions[0].value,
  });

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      closedAtDateFrom: "",
      closedAtDateTo: "",
      competitorFilter: "",
      maxMarginTransferPriceFilter: "",
      maxProfitProjectControlFilter: "",
      maxQuantityFilter: "",
      minMarginTransferPriceFilter: "",
      minProfitProjectControlFilter: "",
      minQuantityFilter: "",
      offerNumberFilter: "",
      orderCodeFilter: "",
      papgFilter: "",
      sumBy: "",
    },
  });

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/report/get/reportProductSum/sort/filter?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&sumBy=${filters.sumBy}&closedAtDateFrom=${filters.closedAtDateFrom}&closedAtDateTo=${filters.closedAtDateTo}&competitorFilter=${filters.competitorFilter}&maxMarginTransferPriceFilter=${filters.maxMarginTransferPriceFilter}&maxProfitProjectControlFilter=${filters.maxProfitProjectControlFilter}&maxQuantityFilter=${filters.maxQuantityFilter}&minMarginTransferPriceFilter=${filters.minMarginTransferPriceFilter}&minProfitProjectControlFilter=${filters.minProfitProjectControlFilter}&minQuantityFilter=${filters.minQuantityFilter}&offerNumberFilter=${filters.offerNumberFilter}&orderCodeFilter=${filters.orderCodeFilter}&papgFilter=${filters.papgFilter}`
  );

  const productsSumReportData = data?.map((el: any) => ({
    competitor: el.competitor !== null ? el.competitor : "-",
    marginTransferPrice:
      el.marginTransferPrice !== null ? el.marginTransferPrice : "-",
    orderCode: el.orderCode !== null ? el.orderCode : "-",
    papg: el.papg !== null ? el.papg : "-",
    profitProjectControl:
      el.profitProjectControl !== null ? el.profitProjectControl : "-",
    projectNumber: el.projectNumber !== null ? el.projectNumber : "-",
    quantity: el.quantity !== null ? el.quantity : "-",
  }));
  const totalItems = data?.length;

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      closedAtDateFrom:
        data.closedAtDateFrom !== ""
          ? dayjs(data.closedAtDateFrom).format("YYYY-MM-DD")
          : "",
      closedAtDateTo:
        data.closedAtDateTo !== ""
          ? dayjs(data.closedAtDateTo).format("YYYY-MM-DD")
          : "",
      competitorFilter: data.competitorFilter,
      maxMarginTransferPriceFilter: data.maxMarginTransferPriceFilter,
      maxProfitProjectControlFilter: data.maxProfitProjectControlFilter,
      maxQuantityFilter: data.maxQuantityFilter,
      minMarginTransferPriceFilter: data.minMarginTransferPriceFilter,
      minProfitProjectControlFilter: data.minProfitProjectControlFilter,
      minQuantityFilter: data.minQuantityFilter,
      offerNumberFilter: data.offerNumberFilter,
      orderCodeFilter: data.orderCodeFilter,
      papgFilter: data.papgFilter,
      sumBy: data.sumBy,
    });
    getData();
    setDrawer(false);
  });

  const handleFilterClear = () => {
    reset();
  };
  const columnsToChoose: {
    title: string;
    dataKey: string;
    width: number;
    sort: boolean;
  }[] = [
    {
      title: "Order Code",
      dataKey: "orderCode",
      width: 100,
      sort: true,
    },
    {
      title: "PAPG",
      dataKey: "papg",
      width: 100,
      sort: true,
    },
    {
      title: "Competitor",
      dataKey: "competitor",
      width: 100,
      sort: true,
    },
  ];

  const columns: {
    title: string;
    dataKey: string;
    width: number;
    sort: boolean;
  }[] = [
    {
      title: "Quantity",
      dataKey: "quantity",
      width: 100,
      sort: true,
    },
    {
      title: "Profit Project Control",
      dataKey: "profitProjectControl",
      width: 100,
      sort: true,
    },
    {
      title: "Margin Transfer Price",
      dataKey: "marginTransferPrice",
      width: 100,
      sort: true,
    },
  ];

  const pickedSumBy = sumOptions?.find((el) => el.value === filters.sumBy);

  const filteredColumns = () => {
    const pickedColumn = columnsToChoose.filter(
      (el) => el.title.toLowerCase() === pickedSumBy?.label.toLowerCase()
    );
    const columnsToDisplay = [...pickedColumn, ...columns];

    return columnsToDisplay;
  };

  const handleGenerateReport = () => {
    setGenerateReport(true);
  };

  const handleCloseModal = (fn: any) => {
    fn(false);
  };

  return (
    <ComponentCard>
      <TableComponent
        columns={filteredColumns()}
        data={productsSumReportData}
        onClickButtonVoid={handleGenerateReport}
        onClickButtonVoidText="Generate Report"
        noSearch
        //pagination
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        page={page}
        totalItems={totalItems}
        filter={() => setDrawer(true)}
        //sorting
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <GenerateProductsSumReports
        isOpen={generateReport}
        handleModalClose={() => handleCloseModal(setGenerateReport)}
      />
      <DrawerComponent drawer={drawer} setDrawer={setDrawer}>
        <Box sx={{ mx: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Filter</h3>
            <IconButton onClick={() => setDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <form>
              <SearchSelect
                options={sumOptions}
                name="sumBy"
                label="Sum By"
                control={control}
                required
              />
              <DatePicker
                fullWidth
                name="closedAtDateFrom"
                control={control}
                label="Closed At Date From: "
              />
              <DatePicker
                fullWidth
                name="closedAtDateTo"
                control={control}
                label="Closed At Date To: "
              />
              <Input
                name="competitorFilter"
                control={control}
                label="Competitor"
              />
              <Input
                name="maxMarginTransferPriceFilter"
                control={control}
                label="Max Margin Transfer Price"
              />
              <Input
                name="maxProfitProjectControlFilter"
                control={control}
                label="Max Profit Project Control"
              />
              <Input
                name="maxQuantityFilter"
                control={control}
                label="Max Quantity"
              />
              <Input
                name="minMarginTransferPriceFilter"
                control={control}
                label="Min Margin Tranfer Price"
              />
              <Input
                name="minProfitProjectControlFilter"
                control={control}
                label="Min Profit Project Control"
              />
              <Input
                name="minQuantityFilter"
                control={control}
                label="Min Quantity"
              />
              {/* <Input
                name="offerNumberFilter"
                control={control}
                label="Offer Number"
              /> */}
              <Input
                name="orderCodeFilter"
                control={control}
                label="Order Code"
              />
              <Input name="papgFilter" control={control} label="PAPG" />
            </form>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button color="primary" onClick={handleFilterClear}>
              Clear
            </Button>
            <Button color="primary" onClick={handleFilterSubmit}>
              Confirm
            </Button>
          </Box>
        </Box>
      </DrawerComponent>
    </ComponentCard>
  );
};

export default ProductsSumReport;
