import axios from "axios";
import { useEffect, useState } from "react";

const useGetCompetition = () => {
  const [competition, setCompetition] = useState<any>([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/app/competitor/get/p/base/sort/filter?activeFilter=true&p=0&s=1000&sortBy=id&sortDirection=ASC`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const comp = res.data.competition.map(
          ({ shortName, fullName, id }: any) => {
            return {
              id,
              label: shortName,
              value: fullName,
            };
          }
        );
        setCompetition(comp);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return {
    competition,
  };
};

export default useGetCompetition;
