import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button, Typography } from '@mui/material';
import ModalComponent from '../Modal';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	rowData?: any;
	forceUpdate: () => void;
}

const PriceListsDeleteProductsModal = ({
	isOpen,
	handleModalClose,
	rowData,
	forceUpdate,
}: Props) => {
	const handleDeleteProduct = () => {
		axios
			.delete(
				`${process.env.REACT_APP_API_BASE_URL}/app/product/delete?productId=${rowData.id}`,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Deleted successfully');
				forceUpdate();
				handleModalClose();
			})
			.catch(err => {
				toast.error('Error occurred');
				console.log(err);
			});
	};
	return (
		<ModalComponent
			isOpen={isOpen}
			handleClose={handleModalClose}
			title="Price List"
			w="xl"
			dialogActions={
				<Box display="flex" justifyContent="space-between" sx={{ p: 2, width: 180 }}>
					<Button variant="outlined" onClick={handleModalClose}>
						Cancel
					</Button>
					<Button variant="contained" onClick={handleDeleteProduct}>
						Delete
					</Button>
				</Box>
			}
		>
			<div>
				<Typography variant="h4">Are you sure you want to delete this product?</Typography>
			</div>
		</ModalComponent>
	);
};

export default PriceListsDeleteProductsModal;
