import { Box, TextField, Button, Typography } from "@mui/material";
import React, {useCallback, useEffect, useRef} from "react";
import { useForm } from "react-hook-form";
import SelectComponent from "../inputs/Select";
import Input from "../inputs/Input";
import useGetCompetition from "./hooks/useGetCompetition";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthContext } from "../../hooks/useAuth";
import usePriceListProducts from "./hooks/usePriceListProducts";
import {
  getFunPriceProfitControl,
  getValue,
  getFunPriceSalesman,
  getSalesmanProfit,
  getProfitProjectControl,
  getDiscountFromPL,
  getMarginTransferPrice,
  getProjectPrice,
} from "./tools/productCalculator";
import { formatValueToPLN } from "../helpers/currencyFormatter";

type Props = {
  editData: any;
  setEditProduct: (value: boolean) => void;
  getEditData: () => void;
  isAddProject?: boolean;
  addProjectData?: any[];
  currentPriceListId?: Number | null;
  customerCategory: string | null;
};

const EditProductModal = ({
  editData,
  setEditProduct,
  getEditData,
  isAddProject,
  addProjectData,
  currentPriceListId,
  customerCategory
}: Props) => {
  const { competition } = useGetCompetition();

  const {
    id,
    local_id,
    orderCode,
    materialDescription,
    baseProductId,
    projectPrice,
    quantity,
    priceListPrice,
    competitor,
    competitorPrice,
    transferPrice
  } = editData;

  const auth = useAuthContext();
  const { userInfo } = auth;
  const userRole = userInfo?.role;

  const { control, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      orderCode,
      materialDescription,
      baseProductId,
      projectPrice,
      quantity,
      value: 0,
      salesmanProfit: 0,
      profitProjectControl: 0,
      priceListPrice,
      discountFromPL: 0,
      funpriceSalesman: 0,
      funpriceProfitControl: 0,
      competitor,
      competitorPrice,
      transferPrice,
      //salesmanProfitAmount,
      //allowedProfitForGroup,
      marginTransferPrice: 0,
      manualDiscount: 0
    },
  });

  const manualDiscount = watch("manualDiscount");

  useEffect(() => {
    const projectPrice = getProjectPrice(watch("priceListPrice"), (manualDiscount === 0) ? discountFromPL : manualDiscount)
    setValue("projectPrice", (isNaN(projectPrice)) ? 0 : projectPrice);
  }, [manualDiscount]);

  const { priceListProducts } = usePriceListProducts(currentPriceListId);

  const getProductDiscounts = useCallback((orderCode: string) => {
      const productDiscounts = priceListProducts.find(
        (p: any) => p.orderCode === orderCode
      );

      if (!productDiscounts) {
        return null;
      }

      return productDiscounts.productDiscounts.find(
        (categoryDiscount: any) => categoryDiscount.category == customerCategory
      );


      // return categoryDiscount;

      // return productDiscounts?.productDiscounts;
    },
    [priceListProducts]
  );

  const productDiscounts = getProductDiscounts(orderCode);

  const salesmanProfitAmount = Number(productDiscounts?.discountSMMax);
  const allowedProfitForGroup = Number(productDiscounts?.discountTLMax);

  console.log("===== EDIT DISCOUNTS ====");
  console.log(salesmanProfitAmount);
  console.log(allowedProfitForGroup);

  const value = getValue(watch("projectPrice"), watch("quantity"));
  setValue("value", Number(value.toFixed(2)))

  const funpriceProfitControl = getFunPriceProfitControl(
    priceListPrice,
    allowedProfitForGroup
  );
  setValue("funpriceProfitControl", Number(funpriceProfitControl))

  const funpriceSalesman = getFunPriceSalesman(
    priceListPrice,
    salesmanProfitAmount
  );
  setValue("funpriceSalesman", Number(funpriceSalesman))

  const salesmanProfit = getSalesmanProfit(
    value,
    priceListPrice,
    watch("quantity"),
    salesmanProfitAmount
  );
  setValue("salesmanProfit", salesmanProfit)

  const profitProjectControl = getProfitProjectControl(
    value,
    priceListPrice,
    watch("quantity"),
    allowedProfitForGroup
  );
  setValue("profitProjectControl", Number(profitProjectControl))

  const discountFromPL = getDiscountFromPL(
    priceListPrice,
    watch("projectPrice")
  );

  const marginTransferPrice = getMarginTransferPrice(
    watch("projectPrice"),
    transferPrice,
    watch("quantity")
  );
  setValue("marginTransferPrice", Number(marginTransferPrice))

  const submit = handleSubmit((data) => {
    let valid = true
    let errorMessage = '';
    if (data.quantity < 1) {
      valid = false;
      errorMessage = 'The quantity of the product cannot be less than 1';
    }
    if (!Number.isInteger(Number(data.quantity))) {
      valid = false;
      errorMessage = 'The quantity must be an integer';
    }
    if (!valid) {
      toast.error(errorMessage);
      return;
    }

    if (isAddProject) {
      if (addProjectData) {
        addProjectData.map((item) => {
          if (item.local_id === local_id) {
            item.orderCode = data.orderCode;
            item.value = data.value;
            item.materialDescription = data.materialDescription;
            item.baseProductId = data.baseProductId;
            item.projectPrice = data.projectPrice;
            item.quantity = data.quantity;
            item.priceListPrice = data.priceListPrice;
            item.competitor = data.competitor;
            item.competitorPrice = data.competitorPrice;
            item.transferPrice = data.transferPrice;
            item.salesmanProfit = data.salesmanProfit;
            item.profitProjectControl = data.profitProjectControl;
            item.discountFromPL = data.discountFromPL;
            item.funpriceSalesman = data.funpriceSalesman;
            item.funpriceProfitControl = data.funpriceProfitControl;
            item.marginTransferPrice = data.marginTransferPrice;
          }
        });
        setEditProduct(false);
      }
    } else {
      const edit = {
        ...data,
        quantity: Number(data.quantity),
        projectPrice: Number(data.projectPrice),
      };
      axios
        .put(
          `${process.env.REACT_APP_API_BASE_URL}/app/offeredproduct/update?offeredProductId=${id}`,
          edit,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Product has been edited successfully");
          setEditProduct(false);
          getEditData();
        })
        .catch((err) => {
          toast.error("Product has not been edited");
          console.log(err);
        });
    }
  });

  return (
    <Box sx={{ width: "100%", height: 900, overflowY: "scroll", px: 1 }}>
      <form>
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("orderCode")}
          InputProps={{
            readOnly: true,
          }}
          label="Order Code"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("materialDescription")}
          InputProps={{
            readOnly: true,
          }}
          label="Material Description"
        />
        <Input
          control={control}
          name="projectPrice"
          label="Project Price"
          type="number"
          required
        />
        <Input
          control={control}
          name="quantity"
          label="Quantity"
          type="number"
          required
        />
        <SelectComponent
          control={control}
          name="competitor"
          label="Competitor"
          options={competition}
        />
        <Input
          control={control}
          name="competitorPrice"
          label="Competitor Price"
          type="number"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("value")}
          value={formatValueToPLN(value)}
          InputProps={{
            readOnly: true,
          }}
          label="Value"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("salesmanProfit")}
          value={formatValueToPLN(salesmanProfit)}
          InputProps={{
            readOnly: true,
          }}
          label="Salesman Profit"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("profitProjectControl")}
          value={formatValueToPLN(profitProjectControl)}
          InputProps={{
            readOnly: true,
          }}
          label="Profit Project Control"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("priceListPrice")}
          InputProps={{
            readOnly: true,
          }}
          value={formatValueToPLN(priceListPrice)}
          label="Price List Price"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("discountFromPL")}
          value={setValue("discountFromPL", Number(discountFromPL))}
          InputProps={{
            readOnly: true,
          }}
          label="Discount from PL (%)"
        />
        <Input
            control={control}
            name="manualDiscount"
            label="Manual discount"
            type="number"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("funpriceSalesman")}
          value={formatValueToPLN(funpriceSalesman)}
          InputProps={{
            readOnly: true,
          }}
          label="Funprice"
        />
        <TextField
          sx={{ my: 1 }}
          fullWidth
          variant="standard"
          {...register("funpriceProfitControl")}
          value={formatValueToPLN(funpriceProfitControl)}
          InputProps={{
            readOnly: true,
          }}
          label="Funprice Profit Control"
        />
        {userRole === "USER" ? (
          (register("transferPrice"),
          setValue("marginTransferPrice", Number(marginTransferPrice)))
        ) : (
          <>
            <Input
              control={control}
              name="transferPrice"
              label="Transfer Price"
              type="number"
              readOnly
            />
            <TextField
              sx={{ my: 1 }}
              fullWidth
              variant="standard"
              {...register("marginTransferPrice")}
              value={formatValueToPLN(marginTransferPrice)}
              InputProps={{
                readOnly: true,
              }}
              label="Margin Transfer Price"
            />
          </>
        )}
      </form>
      <Box display="flex" justifyContent="flex-end" pt={4}>
        <Button onClick={() => setEditProduct(false)}>Cancel</Button>
        <Button onClick={submit}>Save</Button>
      </Box>
    </Box>
  );
};

export default EditProductModal;
