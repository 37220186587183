import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import Menu from "./components/Menu";
import UserProfile from "./components/UserProfile";
import Projects from "./components/projects/Projects";
import Login from "./components/Login";
import Users from "./components/users/Users";
import { Store } from "./components/store/Store";
import Variables from "./components/Variables";
import NewAdminTicket from "./components/NewAdminTicket";
import Raports from "./components/Reports/Reports";
import Dictionary from "./components/dictionary/Dictionary";
import Messages from "./components/messages/Messages";
import { AuthContext, Role, useAuthContext } from "./hooks/useAuth";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectInfo from "./components/projects/ProjectInfo";

const App = () => {
  const auth = useAuthContext();

  const { authenticated, userInfo } = auth;

  const userRole = userInfo?.role;

  return (
    <Store>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          transition={Slide}
        />
        <AuthContext.Provider value={auth}>
          <div className="App">
            {authenticated ? (
              <Router basename="/balluff">
                <Menu
                  userName={userInfo?.userName}
                  firstName={userInfo?.firstName}
                  lastName={userInfo?.lastName}
                  currentRole={userRole}
                >
                  <Switch>
                    <ProtectedRoute
                      path="/"
                      exact
                      component={UserProfile}
                      hasAccess={[
                        Role.ADMIN,
                        Role.ACCEPT2,
                        Role.ACCEPT1,
                        Role.USER,
                      ]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/projects"
                      exact
                      component={Projects}
                      hasAccess={[
                        Role.ADMIN,
                        Role.ACCEPT2,
                        Role.ACCEPT1,
                        Role.USER,
                      ]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/projects/info/:id"
                      exact
                      component={ProjectInfo}
                      hasAccess={[
                        Role.ADMIN,
                        Role.ACCEPT2,
                        Role.ACCEPT1,
                        Role.USER,
                      ]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/users"
                      component={Users}
                      hasAccess={[Role.ADMIN]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/messages"
                      exact
                      component={Messages}
                      hasAccess={[
                        Role.ADMIN,
                        Role.ACCEPT2,
                        Role.ACCEPT1,
                        Role.USER,
                      ]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/dictionary"
                      exact
                      component={Dictionary}
                      hasAccess={[Role.ADMIN, Role.ACCEPT2, Role.ACCEPT1]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/raports"
                      component={Raports}
                      hasAccess={[Role.ADMIN, Role.ACCEPT2, Role.ACCEPT1]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/variables"
                      component={Variables}
                      hasAccess={[Role.ADMIN]}
                      role={userRole}
                      auth={authenticated}
                    />
                    <ProtectedRoute
                      path="/newAdminTicket"
                      component={NewAdminTicket}
                      hasAccess={[
                        Role.ADMIN,
                        Role.ACCEPT2,
                        Role.ACCEPT1,
                        Role.USER,
                      ]}
                      role={userRole}
                      auth={authenticated}
                    />
                  </Switch>
                </Menu>
              </Router>
            ) : (
              <Login />
            )}
          </div>
        </AuthContext.Provider>
      </ThemeProvider>
    </Store>
  );
};

export default App;
