import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import DatePicker from '../inputs/DatePicker';
import Input from '../inputs/Input';
import ModalComponent from '../Modal';
import dayjs from 'dayjs';
import FileInput from '../inputs/FileInput';
import { toast } from 'react-toastify';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	priceList?: any;
	getData: () => void;
}

const DictionaryReplacePriceModal = ({ isOpen, handleModalClose, priceList, getData }: Props) => {
	const { id } = priceList;

	const { control, handleSubmit } = useForm({
		defaultValues: {
			id,
			name: '',
			validFrom: '',
			validTo: '',
		},
	});

	const infoMsg = (total_rows: number, saved_rows: number, wrong_rows: number, duplicated_rows: number) => (
		<div>
			<p>
				Total rows: {total_rows}
			</p>
			<p>
				Saved rows: {saved_rows}
			</p>
			<p>
				Wrong rows: {wrong_rows}
			</p>
			<p>
				Duplicated rows: {duplicated_rows}
			</p>
		</div>
	);

	const onSubmit = handleSubmit((data: any) => {
		const validFrom = dayjs(data.validFrom).format('YYYY-MM-DD');
		const validTo = dayjs(data.validTo).format('YYYY-MM-DD');

		var myHeaders = new Headers();
		myHeaders.append('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
		var formdata = new FormData();
		formdata.append('file', data.file[0], data.priceListName);

		var requestOptions = {
			method: 'PUT',
			headers: myHeaders,
			body: formdata,
		};

		fetch(
			`${process.env.REACT_APP_API_BASE_URL}/app/pricelist/import_file?activeFrom=${validFrom}&activeTo=${validTo}&fileName=${data.name}`,
			requestOptions
		)
			//.then(response => response.text())
			.then(response => {
				if (response.status === 200) {
					response.json().then(json => {
						toast.success('Imported successfully');
						toast.info(infoMsg(json.total_rows, json.saved_rows, json.wrong_rows, json.duplicated_rows));
						getData();
						handleModalClose();
					});
					return;
				}
				toast.error('Import error');
			})
			.catch(error => {
				toast.error('Error occur');
				console.log('error', error);
			});
	});

	return (
		<ModalComponent
			isOpen={isOpen}
			handleClose={handleModalClose}
			title="Import new price list"
		>
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="name" label="Name" control={control} required />
				</Box>
				<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Box sx={{ width: '100%', mr: 1 }}>
						<DatePicker
							control={control}
							label="Valid from: "
							name="validFrom"
							fullWidth
						/>
					</Box>
					<Box sx={{ width: '100%', ml: 1 }}>
						<DatePicker fullWidth name="validTo" control={control} label="Valid to: " />
					</Box>
				</Box>
				<Box>
					<FileInput name="file" control={control} label="Select file" />
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>
					<Button onClick={onSubmit}>Import</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default DictionaryReplacePriceModal;
