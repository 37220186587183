import { TableColumnType } from "./types";
import Cell from "./cell";
import {TableCell, TableRow} from "@mui/material";
import { getColumnKey } from "./getColumnKey";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export interface HeaderRowProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  sortField?: any;
  setSortField?: (value: any) => void;
  sortDirection?: any;
  setSortDirection?: (value: any) => void;
}

function HeaderRow<T>({
  columns,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
}: HeaderRowProps<T>) {
  const handleDataSort = (el: any, sortOrder: any) => {
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setSortField(el.dataKey);
    // ts-ignore - only until pagination will be implemented for all pages
    // @ts-ignore
    setSortDirection(sortOrder);
  };

    // const renderColumn = (column: any, index: any) => (
    //     <Cell
    //         key={getColumnKey(column, index)}
    //         column={column}
    //         rowIndex={NaN}
    //         sx={{
    //             color: column.sort && (sortField === column.dataKey ? "secondary" : "secondary.dark"),
    //             cursor: column.sort ? "pointer" : "default",
    //             position: "relative",
    //         }}
    //         onClick={() => column.sort && handleDataSort(column, sortField === column.dataKey && sortDirection === "ASC" ? "DESC" : "ASC")}
    //     >
    //         {column.title}
    //         {column.sort && sortField === column.dataKey && (
    //             sortDirection === "ASC" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
    //         )}
    //     </Cell>
    // );

  // const renderColumn = (column: any, index: any) => (
  //     <>
  //       {column.sort && sortField !== column.dataKey && (
  //           <Cell
  //               key={getColumnKey(column, index)}
  //               column={column}
  //               rowIndex={NaN}
  //               sx={{
  //                 color: "secondary.dark",
  //                 cursor: "pointer",
  //                 position: "relative",
  //               }}
  //               onClick={() => handleDataSort(column, "ASC")}
  //           >
  //             {column.title}
  //           </Cell>
  //       )}
  //
  //       {column.sort &&
  //           sortField === column.dataKey &&
  //           sortDirection === "ASC" && (
  //               <Cell
  //                   key={getColumnKey(column, index)}
  //                   column={column}
  //                   rowIndex={NaN}
  //                   sx={{
  //                     color: "secondary",
  //                     cursor: "pointer",
  //                     position: "relative",
  //                   }}
  //                   onClick={() => handleDataSort(column, "DESC")}
  //               >
  //                 {column.title}
  //                 <ArrowDropUpIcon />
  //               </Cell>
  //           )}
  //
  //       {column.sort &&
  //           sortField === column.dataKey &&
  //           sortDirection === "DESC" && (
  //               <Cell
  //                   key={getColumnKey(column, index)}
  //                   column={column}
  //                   rowIndex={NaN}
  //                   sx={{
  //                     color: "secondary",
  //                     cursor: "pointer",
  //                     position: "relative",
  //                   }}
  //                   onClick={() => handleDataSort(column, "ASC")}
  //               >
  //                 {column.title}
  //                 <ArrowDropDownIcon />
  //               </Cell>
  //           )}
  //
  //       {!column.sort && (
  //           <Cell
  //               key={getColumnKey(column, index)}
  //               column={column}
  //               rowIndex={NaN}
  //               sx={{
  //                 color: "secondary.dark",
  //               }}
  //           >
  //             {column.title}
  //           </Cell>
  //       )}
  //     </>
  // );

    return (
        <>
            <TableRow>
                {columns.map((column, index) => (
                    <TableCell
                        key={index}
                        colSpan={column.subColumns ? column.subColumns.length : 1}
                        rowSpan={column.subColumns ? 1 : 2}
                        sx={{
                            cursor: column.sort ? 'pointer' : 'default',
                            color: (column.sort && sortField === column.dataKey) ? 'secondary' : 'secondary.dark',
                            position: 'relative', // W przypadku potrzeby pozycjonowania ikon
                            // fontWeight: 'bold', // Jeśli nagłówki mają być wytłuszczone
                            // Dalsze style można dodawać tutaj
                        }}
                        onClick={column.sort ? () => handleDataSort(column, sortField === column.dataKey && sortDirection === "ASC" ? "DESC" : "ASC") : undefined}
                    >
                        {column.title}
                        {column.sort && sortField === column.dataKey && (
                            sortDirection === "ASC" ? <ArrowDropUpIcon sx={{marginBottom: '-6px'}} /> : <ArrowDropDownIcon sx={{marginBottom: '-6px'}} />
                        )}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow>
                {columns.flatMap((column, index) =>
                    column.subColumns ? column.subColumns.map((subColumn, subIndex) => (
                        <TableCell
                            key={`${index}_${subIndex}`}
                            sx={{
                                cursor: subColumn.sort ? 'pointer' : 'default',
                                color: (subColumn.sort && sortField === subColumn.dataKey) ? 'secondary' : 'secondary.dark',
                                position: 'relative', // W przypadku potrzeby pozycjonowania ikon
                                // fontWeight: 'bold', // Jeśli nagłówki mają być wytłuszczone
                                // Dalsze style można dodawać tutaj
                            }}
                            onClick={subColumn.sort ? () => handleDataSort(subColumn, sortField === subColumn.dataKey && sortDirection === "ASC" ? "DESC" : "ASC") : undefined}
                        >
                            {subColumn.title}
                            {subColumn.sort && sortField === subColumn.dataKey && (
                                sortDirection === "ASC" ? <ArrowDropUpIcon sx={{marginBottom: '-6px'}} /> : <ArrowDropDownIcon sx={{marginBottom: '-6px'}} />
                            )}
                        </TableCell>
                    )) : []
                )}
            </TableRow>
        </>
    );

  // return (
  //     <>
  //         <TableRow>
  //             {columns.map((column, index) => (
  //                 <TableCell
  //                     key={index}
  //                     colSpan={column.subColumns ? column.subColumns.length : 1}
  //                     rowSpan={column.subColumns ? 1 : 2}
  //                 >
  //                     {column.title}
  //                 </TableCell>
  //             ))}
  //         </TableRow>
  //         <TableRow>
  //             {columns.flatMap((column, index) =>  // Użycie flatMap pozwala uniknąć dodawania zbędnych elementów.
  //                 column.subColumns ? column.subColumns.map((subColumn, subIndex) => (
  //                     <TableCell key={`${index}_${subIndex}`}>
  //                         {subColumn.title}
  //                     </TableCell>
  //                 )) : []
  //             )}
  //         </TableRow>
  //     </>
  // );

  // return (
  //   <TableRow>
  //     {columns &&
  //       sortField &&
  //       columns.map((column, index) => (
  //         // TODO: fix issue with unique "key" prop
  //         <>
  //           {column.sort && sortField !== column.dataKey && (
  //             <Cell
  //               key={getColumnKey(column, index)}
  //               column={column}
  //               rowIndex={NaN}
  //               sx={{
  //                 color: "secondary.dark",
  //                 cursor: "pointer",
  //                 position: "relative",
  //               }}
  //               onClick={() => handleDataSort(column, "ASC")}
  //             >
  //               {column.title} <div></div>
  //             </Cell>
  //           )}
  //
  //           {column.sort &&
  //             sortField === column.dataKey &&
  //             sortDirection === "ASC" && (
  //               <Cell
  //                 key={getColumnKey(column, index)}
  //                 column={column}
  //                 rowIndex={NaN}
  //                 sx={{
  //                   color: "secondary",
  //                   cursor: "pointer",
  //                   position: "relative",
  //                 }}
  //                 onClick={() => handleDataSort(column, "DESC")}
  //               >
  //                 {column.title}
  //                 <ArrowDropUpIcon
  //                   sx={{
  //                     position: "absolute",
  //                   }}
  //                 />
  //               </Cell>
  //             )}
  //
  //           {column.sort &&
  //             sortField === column.dataKey &&
  //             sortDirection === "DESC" && (
  //               <Cell
  //                 key={getColumnKey(column, index)}
  //                 column={column}
  //                 rowIndex={NaN}
  //                 sx={{
  //                   color: "secondary",
  //                   cursor: "pointer",
  //                   position: "relative",
  //                 }}
  //                 onClick={() => handleDataSort(column, "ASC")}
  //               >
  //                 {column.title}
  //                 <ArrowDropDownIcon
  //                   sx={{
  //                     position: "absolute",
  //                   }}
  //                 />
  //               </Cell>
  //             )}
  //
  //           {!column.sort && (
  //             <Cell
  //               key={getColumnKey(column, index)}
  //               column={column}
  //               rowIndex={NaN}
  //               sx={{
  //                 color: "secondary.dark",
  //               }}
  //             >
  //               {column.title}
  //             </Cell>
  //           )}
  //         </>
  //       ))}
  //   </TableRow>
  // );
}

export default HeaderRow;
