import { TableColumnType } from "../../table/types";

export const productTableColumns: TableColumnType<any>[] = [
  {
    title: "Order Code",
    dataKey: "orderCode",
    align: "left",
    sort: true,
    width: 150,
  },
  {
    title: "Project Price",
    dataKey: "projectPrice",
    align: "center",
    sort: true,
    width: 210,
  },
  {
    title: "Quantity",
    dataKey: "quantity",
    align: "center",
    sort: true,
    width: 30,
  },
  {
    title: "Value",
    dataKey: "value",
    align: "center",
    sort: true,
    width: 100,
  },
  {
    title: "Discount from PL",
    dataKey: "discountFromPL",
    align: "center",
    sort: true,
    width: 300,
  },
  {
    title: "Competitor",
    dataKey: "competitor",
    align: "center",
    sort: true,
    width: 40,
  },
  {
    title: "Competitor Price",
    dataKey: "competitorPrice",
    align: "center",
    sort: true,
    width: 230,
  },
];
