import { useEffect, useState } from "react";
import { Typography, Box, Button, Checkbox } from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ComponentCard from "./ComponentCard";
import Input from "./inputs/Input";

const Variables = () => {
  const [autoAcceptSet, setAutoAcceptSet] = useState(false);
  const [acceptLvlOneSet, setAcceptLvlOneSet] = useState(false);
  const [update, setUpdate] = useState(false);

  const [variables, setVariables] = useState({
    emailReminderDays: "",
    marginPerProduct: "",
    marginValue: "",
    offerAutoFailDays: "",
    valueForAlgorithm: "",
    valueX: "",
  });
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      emailReminderDays: variables.emailReminderDays,
      marginPerProduct: variables.marginPerProduct,
      marginValue: variables.marginValue,
      offerAutoFailDays: variables.offerAutoFailDays,
      valueForAlgorithm: variables.valueForAlgorithm,
      valueX: variables.valueX,
    },
  });

  const updatePage = () => {
    setUpdate(!update);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/config/get`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setVariables({
          emailReminderDays: res.data.emailReminderDays,
          marginPerProduct: res.data.marginPerProduct,
          marginValue: res.data.marginValue,
          offerAutoFailDays: res.data.offerAutoFailDays,
          valueForAlgorithm: res.data.valueForAlgorithm,
          valueX: res.data.valueX,
        });
        setAutoAcceptSet(res.data.autoAcceptSet);
        setAcceptLvlOneSet(res.data.autoAcceptSet);
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
  }, [update]);

  useEffect(() => {
    reset({
      emailReminderDays: variables.emailReminderDays,
      marginPerProduct: variables.marginPerProduct,
      marginValue: variables.marginValue,
      offerAutoFailDays: variables.offerAutoFailDays,
      valueForAlgorithm: variables.valueForAlgorithm,
      valueX: variables.valueX,
    });
  }, [variables]);

  const resetValues = () => {
    reset({
      emailReminderDays: variables.emailReminderDays,
      marginPerProduct: variables.marginPerProduct,
      marginValue: variables.marginValue,
      offerAutoFailDays: variables.offerAutoFailDays,
      valueForAlgorithm: variables.valueForAlgorithm,
      valueX: variables.valueX,
    });
    setAcceptLvlOneSet(!acceptLvlOneSet);
    setAutoAcceptSet(!autoAcceptSet);
  };

  const handleVariableSubmit = handleSubmit((data) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/config/edit`,
        {
          acceptLvlOneSet: acceptLvlOneSet,
          autoAcceptSet: autoAcceptSet,
          configType: process.env.REACT_APP_ENV,
          emailReminderDays: parseInt(data.emailReminderDays, 10),
          marginPerProduct: parseInt(data.marginPerProduct, 10),
          marginValue: parseInt(data.marginValue, 10),
          offerAutoFailDays: parseInt(data.offerAutoFailDays, 10),
          valueForAlgorithm: parseInt(data.valueForAlgorithm, 10),
          valueX: parseInt(data.valueX, 10),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Edited successfully");
        } else {
          toast.error("Error occur");
        }
        updatePage();
      })
      .catch((err) => {
        toast.error("Error occur");
        console.log(err);
      });
  });

  return (
    <>
      <ComponentCard>
        <form style={{ padding: "16px" }}>
          <Typography variant="h6">Time</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "8px", width: "90%" }}>
              Time from the moment of acceptance to sending an e-mail
              notification to seller about the change in the status of the
              project.
            </p>
            <Box
              sx={{
                width: "10%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input name="emailReminderDays" label="" control={control} />
              <Typography
                sx={{
                  marginLeft: "3px",
                }}
                variant="body2"
              >
                days
              </Typography>
            </Box>
          </Box>
          <hr
            style={{
              height: "1px",
              backgroundColor: "#d7d7d7",
              border: "none",
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "8px", width: "90%" }}>
              Time from the moment of acceptance, after which the project
              automatically stays assigned as no sale <br />
              (If no project status is indicated)
            </p>
            <Box sx={{ width: "10%", display: "flex", alignItems: "center" }}>
              <Input name="offerAutoFailDays" label="" control={control} />
              <Typography
                sx={{
                  marginLeft: "3px",
                }}
                variant="body2"
              >
                days
              </Typography>
            </Box>
          </Box>
          <Typography variant="h6" sx={{ marginTop: 10 }}>
            Margins
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "8px", width: "75%" }}>
              Project value to the decision algorithm
            </p>
            <Box sx={{ width: "25%", display: "flex", alignItems: "center" }}>
              <Input control={control} name="valueForAlgorithm" label="" />
              <Typography
                sx={{
                  marginLeft: "3px",
                }}
                variant="body2"
              >
                value in PLN
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "8px", width: "75%" }}>
              Margin value
            </p>
            <Box sx={{ width: "25%", display: "flex", alignItems: "center" }}>
              <Input control={control} name="marginValue" label="" />
              <Typography
                sx={{
                  marginLeft: "3px",
                }}
                variant="body2"
              >
                value in PLN
              </Typography>
            </Box>
          </Box>
          <hr
            style={{
              height: "1px",
              backgroundColor: "#d7d7d7",
              border: "none",
            }}
          />
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p style={{ fontSize: "14px", marginRight: "8px", width: "80%" }}>
                {`Automatic acceptance in the overall project (project value below ${variables.valueForAlgorithm} PLN
								 and a margin greater than ${variables.marginValue} PLN)`}
              </p>
              <Checkbox
                name="autoAcceptSet"
                checked={autoAcceptSet}
                onChange={() => setAutoAcceptSet(!autoAcceptSet)}
              />
            </Box>
            {/* <Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<p style={{ fontSize: '14px', marginRight: '8px', width: '80%' }}>
								Acceptance Level I (project value over PLN 100,000 and margin
								smaller than)
							</p>
							<Checkbox
								name="acceptLvlOneSet"
								checked={acceptLvlOneSet}
								onChange={() => setAcceptLvlOneSet(!acceptLvlOneSet)}
							/>
						</Box> */}
          </Box>
          <hr
            style={{
              height: "1px",
              backgroundColor: "#d7d7d7",
              border: "none",
            }}
          />
          {""}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "8px", width: "75%" }}>
              Margin level per product
            </p>
            <Box sx={{ width: "25%", display: "flex", alignItems: "center" }}>
              <Input name="marginPerProduct" label="" control={control} />
              <Typography
                sx={{
                  marginLeft: "3px",
                }}
                variant="body2"
              >
                value in PLN
              </Typography>
            </Box>
          </Box>
          <hr
            style={{
              height: "1px",
              backgroundColor: "#d7d7d7",
              border: "none",
            }}
          />{" "}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "8px", width: "75%" }}>
              The value of the amount X
            </p>
            <Box sx={{ width: "25%", display: "flex", alignItems: "center" }}>
              <Input name="valueX" label="" control={control} />
              <Typography
                sx={{
                  marginLeft: "3px",
                }}
                variant="body2"
              >
                value in PLN
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" sx={{ p: 2, mt: 2 }}>
            <Button onClick={resetValues}>Reset to default values</Button>
            <Button onClick={handleVariableSubmit}>Save</Button>
          </Box>
        </form>
      </ComponentCard>
    </>
  );
};

export default Variables;
