import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  dialogActions?: React.ReactNode;
  w?: "sm" | "md" | "xl";
  isSmall?: boolean;
}

const ModalComponent = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
  dialogActions,
  w,
  isSmall,
}: Props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={w || "md"}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog"
      fullScreen={!isSmall}
    >
      <Box
        id="dialog"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <IconButton onClick={handleClose} color="primary">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", justifyContent: "center", px: 5 }}>
        <DialogContentText>{subtitle}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};

export default ModalComponent;
