import { Box, Button, Checkbox } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import SelectComponent from "../inputs/Select";
import ModalComponent from "../Modal";
import axios from "axios";
import { toast } from "react-toastify";

type Props = {
  handleModalClose: () => void;
  isOpen: boolean;
  project: any;
  getData: () => void;
};

const AcceptLvlOneModal = ({
  handleModalClose,
  isOpen,
  project,
  getData,
}: Props) => {
  const options = [
    {
      id: 1,
      label: "SUCCESS",
      value: "SUCCESS",
    },
    {
      id: 2,
      label: "FAIL",
      value: "FAIL",
    },
  ];

  const { id, projectNumber } = project;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      offerStatus: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    const chceckSuccessOrFail = () => {
      if (data.offerStatus === "SUCCESS") {
        return true;
      } else {
        return false;
      }
    };
    axios
      .put(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/app/offer/successOrFail?isSuccess=${chceckSuccessOrFail()}&offerId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        getData();
        handleModalClose();
        toast.success("Offer has been accepted");
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title={`Change status for your offer with number ${projectNumber}`}
      dialogActions={
        <Box display="flex" sx={{ p: 2 }}>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={onSubmit}>Save</Button>
        </Box>
      }
    >
      <Box sx={{ width: "100%" }}>
        <SelectComponent
          control={control}
          name="offerStatus"
          label="status"
          options={options}
        />
      </Box>
    </ModalComponent>
  );
};

export default AcceptLvlOneModal;
