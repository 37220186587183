export const getValue = (projectPrice: number, quantity: number): number =>
    Number((projectPrice * quantity).toFixed(2));

export const getFunPriceProfitControl = (pricelistPrice: number, allowedProfitForGroup: number): number =>
    Number((pricelistPrice - (pricelistPrice * allowedProfitForGroup)).toFixed(2));

export const getFunPriceSalesman = (pricelistPrice: number, salesmanProfitAmount:number): number =>
    Number((pricelistPrice - (pricelistPrice * salesmanProfitAmount)).toFixed(2));

export const getSalesmanProfit = (value: number, pricelistPrice: number, quantity: number , salesmanProfitAmount:number): number =>
    Number(((value) - (pricelistPrice - (pricelistPrice * salesmanProfitAmount)) * (quantity)).toFixed(2));

export const getProfitProjectControl = (value: number, pricelistPrice: number, quantity: number, allowedProfitForGroup: number): number =>
    Number((value - (pricelistPrice - (pricelistPrice * allowedProfitForGroup)) * quantity).toFixed(2));

export const getDiscountFromPL = (pricelistPrice: number, projectPrice: number): number =>
    Number((((pricelistPrice - projectPrice) / pricelistPrice) * 100).toFixed(2));

export const getMarginTransferPrice = (projectPrice: number, transferPrice: number, quantity: number): number =>
    Number(((projectPrice - transferPrice) * quantity).toFixed(2));

export const getProjectPrice = (pricelistPrice: number, discountFromPL: number): number =>
    Number((pricelistPrice - (pricelistPrice * (discountFromPL/100))).toFixed(2));
