import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

import React from "react";

type Option = {
  id: number;
  label: string;
  value: string;
};

interface Props {
  options: Option[];
  name: string;
  label: string;
  fullWidth?: boolean;
  control?: any;
  value?: string;
  required?: boolean;
  variant?: "standard" | "outlined" | "filled";
  // selectedOrderCode?: any;
  selectedValue?: any;
  // setOrderCode?: (value: any) => void;
  setSelectedValue?: (value: any) => void;
  loading?: boolean;
}

//todo fix undefined value after select
const SearchSelect = React.forwardRef(
  (
    {
      options,
      name,
      label,
      control,
      required,
      variant,
      selectedValue,
      setSelectedValue,
      loading,
    }: Props,
    ref
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, onChange, value, ...rest } }) => (
          <Autocomplete
            value={selectedValue}
            onChange={(e, data) => {
              if (setSelectedValue) {
                setSelectedValue(data?.value);
              }
              onChange(data?.value);
            }}
            options={options.map((option: Option) => ({
              id: option.id,
              label: option.label,
              value: option.value,
            }))}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={variant || "standard"}
                required={required || false}
              />
            )}
          />
        )}
        rules={{
          required: { value: required || false, message: "Required field" },
        }}
      />
    );
  }
);

export default SearchSelect;
