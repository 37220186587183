import { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FloatingMenu from "../floatingMenu";
import ModalComponent from "../Modal";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import CloseIcon from "@mui/icons-material/Close";
import DrawerComponent from "../DrawerComponent";
import Input from "../inputs/Input";
import DatePicker from "../inputs/DatePicker";
import PriceListsEditProductsModal from "./PriceListsEditProductsModal";
import PriceListsDeleteProductsModal from "./PriceListsDeleteProductsModal";
import PriceListsAddProductsModal from "./PriceListsAddProductsModal";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  pickedPriceList?: any;
}

const PriceListsProductsModal = ({
  isOpen,
  handleClose,
  pickedPriceList,
}: Props) => {
  const [drawer, setDrawer] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("id");
  const [sortDirection, setSortDirection] = useState<string>("ASC");
  const [data, setData] = useState<any>({});
  const [closeMenu, setCloseMenu] = useState(false);
  const [productsToEdit, setProductsToEdit] = useState<any>({});
  const [isAddOpen, setAddOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [filters, setFilters] = useState({
    activeFromFilter: "",
    activeToFilter: "",
    currencyFilter: "",
    materialDescriptionFilter: "",
    maxPriceListPriceFilter: "",
    maxTransferPriceFilter: "",
    minPriceListPriceFilter: "",
    minTransferPriceFilter: "",
    orderCodeFilter: "",
    papgFilter: "",
    pricingUnitFilter: "",
    productAreaFilter: "",
    productGroupFilter: "",
    unitOfMeasureFilter: "",
  });
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      activeFromFilter: "",
      activeToFilter: "",
      currencyFilter: "",
      materialDescriptionFilter: "",
      maxPriceListPriceFilter: "",
      maxTransferPriceFilter: "",
      minPriceListPriceFilter: "",
      minTransferPriceFilter: "",
      orderCodeFilter: "",
      papgFilter: "",
      pricingUnitFilter: "",
      productAreaFilter: "",
      productGroupFilter: "",
      unitOfMeasureFilter: "",
    },
  });

  useEffect(() => {
    if (isOpen) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/app/product/get/p/pricelist/sort/filter?activeFilter=true&p=${page}&priceListIdFilter=${
            pickedPriceList?.id
          }&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}
					${
            filters.activeFromFilter !== ""
              ? `&activeFromFilter=${filters.activeFromFilter}`
              : ""
          }
					${
            filters.activeToFilter !== ""
              ? `&activeToFilter=${filters.activeToFilter}`
              : ""
          }
					${
            filters.currencyFilter !== ""
              ? `&currencyFilter=${filters.currencyFilter}`
              : ""
          }
					${
            filters.materialDescriptionFilter !== ""
              ? `&materialDescriptionFilter=${filters.materialDescriptionFilter}`
              : ""
          }
					${
            filters.maxPriceListPriceFilter !== ""
              ? `&maxPriceListPriceFilter=${filters.maxPriceListPriceFilter}`
              : ""
          }
					${
            filters.maxTransferPriceFilter !== ""
              ? `&maxTransferPriceFilter=${filters.maxTransferPriceFilter}`
              : ""
          }
					${
            filters.minPriceListPriceFilter !== ""
              ? `&minPriceListPriceFilter=${filters.minPriceListPriceFilter}`
              : ""
          }
					${
            filters.minTransferPriceFilter !== ""
              ? `&minTransferPriceFilter=${filters.minTransferPriceFilter}`
              : ""
          }
					${
            filters.orderCodeFilter !== ""
              ? `&orderCodeFilter=${filters.orderCodeFilter}`
              : ""
          }
					${filters.papgFilter !== "" ? `&papgFilter=${filters.papgFilter}` : ""}
					${
            filters.pricingUnitFilter !== ""
              ? `&pricingUnitFilter=${filters.pricingUnitFilter}`
              : ""
          }
					${
            filters.productAreaFilter !== ""
              ? `&productAreaFilter=${filters.productAreaFilter}`
              : ""
          }
					${
            filters.productGroupFilter !== ""
              ? `&productGroupFilter=${filters.productGroupFilter}`
              : ""
          }
					${
            filters.unitOfMeasureFilter !== ""
              ? `&unitOfMeasureFilter=${filters.unitOfMeasureFilter}`
              : ""
          }
					`,

          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          toast.error("Error occurred");
          console.log(err);
        });
    }
  }, [
    isOpen,
    filters,
    sortField,
    sortDirection,
    page,
    rowsPerPage,
    updateTable,
  ]);

  const productsData = data?.products?.map((el: any) => ({
    activeFrom: el.activeFrom !== null ? el.activeFrom : "-",
    activeTo: el.activeTo !== null ? el.activeTo : "-",
    currency: el.currency !== null ? el.currency : "-",
    id: el.id !== null ? el.id : "-",
    materialDescription:
      el.materialDescription !== null ? el.materialDescription : "-",
    orderCode: el.orderCode !== null ? el.orderCode : "-",
    papg: el.papg !== null ? el.papg : "-",
    priceListName: el.priceListName !== null ? el.priceListName : "-",
    priceListPrice: el.priceListPrice !== null ? el.priceListPrice : "-",
    pricingUnit: el.pricingUnit !== null ? el.pricingUnit : "-",
    productArea: el.productArea !== null ? el.productArea : "-",
    productGroup: el.productGroup !== null ? el.productGroup : "-",
    transferPrice: el.transferPrice !== null ? el.transferPrice : "-",
    unitOfMeasure: el.unitOfMeasure !== null ? el.unitOfMeasure : "-",
  }));
  const totalItems = data?.totalItems;

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      activeFromFilter:
        data.activeFromFilter !== ""
          ? dayjs(data.activeFromFilter).format("YYYY-MM-DD")
          : "",
      activeToFilter:
        data.activeToFilter !== ""
          ? dayjs(data.activeToFilter).format("YYYY-MM-DD")
          : "",
      currencyFilter: data.currencyFilter,
      materialDescriptionFilter: data.materialDescriptionFilter,
      maxPriceListPriceFilter: data.maxPriceListPriceFilter,
      maxTransferPriceFilter: data.maxTransferPriceFilter,
      minPriceListPriceFilter: data.minPriceListPriceFilter,
      minTransferPriceFilter: data.minTransferPriceFilter,
      orderCodeFilter: data.orderCodeFilter,
      papgFilter: data.papgFilter,
      pricingUnitFilter: data.pricingUnitFilter,
      productAreaFilter: data.productAreaFilter,
      productGroupFilter: data.productGroupFilter,
      unitOfMeasureFilter: data.unitOfMeasureFilter,
    });
    setDrawer(false);
  });

  const handleFilterClear = () => {
    reset();
  };

  const handleOpen = (fn: any, row?: any) => {
    setCloseMenu(true);
    fn(true);
    if (row) {
      setProductsToEdit(row);
    }
  };

  const handleProductsModalClose = (fn: any) => {
    setCloseMenu(false);
    fn(false);
  };

  const forceUpdate = () => {
    setUpdateTable(!updateTable);
  };

  const columns: TableColumnType<any>[] = [
    {
      title: "Order Code",
      dataKey: "orderCode",
      width: 90,
      sort: true,
    },
    {
      title: "Product Area",
      dataKey: "productArea",
      width: 90,
      sort: true,
    },
    {
      title: "Product Group",
      dataKey: "productGroup",
      width: 90,
      sort: true,
    },
    {
      title: "PAPG",
      dataKey: "papg",
      width: 90,
      sort: true,
    },
    {
      title: "Material Description",
      dataKey: "materialDescription",
      width: 250,
      sort: true,
    },
    {
      title: "TPL GP Global Pricelist",
      dataKey: "priceListPrice",
      width: 90,
      sort: true,
    },
    {
      title: "Transfer Price",
      dataKey: "transferPrice",
      width: 90,
      sort: true,
    },
    {
      title: "Currency",
      dataKey: "currency",
      width: 90,
      sort: true,
    },
    {
      title: "Pricing Unit",
      dataKey: "pricingUnit",
      width: 90,
      sort: true,
    },
    {
      title: "Unit Of Measure",
      dataKey: "unitOfMeasure",
      width: 90,
      sort: true,
    },
  ];

  columns.push({
    title: "Actions",
    dataKey: "action",
    align: "right",
    sort: false,
    width: 50,
    children: (row) => {
      return (
        <FloatingMenu
          close={closeMenu}
          menuItems={[
            <MenuItem key={1} onClick={() => handleOpen(setEditOpen, row)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>,
            <MenuItem key={2} onClick={() => handleOpen(setDeleteOpen, row)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>,
          ]}
        />
      );
    },
  });

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        handleClose={handleClose}
        title="Products List"
        w="xl"
        dialogActions={<Box display="flex" sx={{ p: 2 }}></Box>}
      >
        <TableComponent
          columns={columns}
          data={productsData}
          onClickButtonVoid={() => handleOpen(setAddOpen, pickedPriceList)}
          onClickButtonVoidText="Add"
          noSearch
          //pagination
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          page={page}
          totalItems={totalItems}
          filter={() => setDrawer(true)}
          //sorting
          sortField={sortField}
          setSortField={setSortField}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />

        <DrawerComponent drawer={drawer} setDrawer={setDrawer} zIndex={"10000"}>
          <Box sx={{ mx: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Filter</h3>
              <IconButton onClick={() => setDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <form>
                <DatePicker
                  fullWidth
                  name="activeFromFilter"
                  control={control}
                  label="Active From: "
                />
                <DatePicker
                  fullWidth
                  name="activeToFilter"
                  control={control}
                  label="Active To: "
                />
                <Input
                  name="currencyFilter"
                  control={control}
                  label="Currency"
                />
                <Input
                  name="materialDescriptionFilter"
                  control={control}
                  label="Material Description"
                />
                <Input
                  name="maxPriceListPriceFilter"
                  control={control}
                  label="Max Price"
                />
                <Input
                  name="maxTransferPriceFilter"
                  control={control}
                  label="Max Transfer Price"
                />
                <Input
                  name="minPriceListPriceFilter"
                  control={control}
                  label="Min price"
                />
                <Input
                  name="minTransferPriceFilter"
                  control={control}
                  label="Min Transfer Price"
                />
                <Input
                  name="orderCodeFilter"
                  control={control}
                  label="Order Code"
                />
                <Input name="papgFilter" control={control} label="PAPG" />
                <Input
                  name="pricingUnitFilter"
                  control={control}
                  label="Pricing Unit"
                />
                <Input
                  name="productAreaFilter"
                  control={control}
                  label="Product Area"
                />
                <Input
                  name="productGroupFilter"
                  control={control}
                  label="Product Group"
                />
                <Input
                  name="unitOfMeasureFilter"
                  control={control}
                  label="Unit Of Measure"
                />
              </form>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button color="primary" onClick={handleFilterClear}>
                Clear
              </Button>
              <Button color="primary" onClick={handleFilterSubmit}>
                Confirm
              </Button>
            </Box>
          </Box>
        </DrawerComponent>
      </ModalComponent>
      <PriceListsEditProductsModal
        isOpen={isEditOpen}
        handleModalClose={() => handleProductsModalClose(setEditOpen)}
        rowData={productsToEdit}
        pickedPriceList={pickedPriceList}
        forceUpdate={forceUpdate}
      />
      <PriceListsDeleteProductsModal
        isOpen={isDeleteOpen}
        handleModalClose={() => handleProductsModalClose(setDeleteOpen)}
        rowData={productsToEdit}
        forceUpdate={forceUpdate}
      />
      <PriceListsAddProductsModal
        isOpen={isAddOpen}
        handleModalClose={() => handleProductsModalClose(setAddOpen)}
        pickedPriceList={pickedPriceList}
        forceUpdate={forceUpdate}
      />
    </>
  );
};

export default PriceListsProductsModal;
