import { Box, Typography, Button } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import Input from "./inputs/Input";
import ChangePasswordModal from "./users/ChangePasswordModal";

interface UserData {
  firstName: string;
  lastName: string;
  userName: string;
  role: string;
  password: string;
  showPassword: boolean;
}

interface Props {
  userData: UserData;
  setEdit: (edit: boolean) => void;
}

const ShowUserInfo = ({ userData, setEdit }: Props) => {
  const { firstName, lastName, userName, role, password } = userData;
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const { control, reset } = useForm({
    defaultValues: {
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      userName: userName ? userName : "",
      role: role ? role : "",
      password: password ? password : "",
    },
  });

  const [closeMenu, setCloseMenu] = useState(false);

  useEffect(() => {
    reset({
      firstName,
      lastName,
      userName,
      role,
      password,
    });
  }, [userData]);

  const handleOpen = (fn: any, row?: any) => {
    setCloseMenu(true);

    fn(true);
  };

  const handleModalClose = (fn: any) => {
    setCloseMenu(false);
    fn(false);
  };

  return (
    <>
      <Box sx={{ display: "inline-block", justifyContent: "space-between", px: 2, py: 1 }}>
        <Typography variant="h6">Profile</Typography>
      </Box>
      <Box sx={{ display: "inline-block", float: "right"}}>
        <Button sx={{ marginRight: "1em" }} variant="outlined" onClick={() => handleOpen(setChangePasswordOpen)}>
          Change password
        </Button>
        {role === "ADMIN" ? (
            <Button variant="outlined" onClick={() => setEdit(true)}>
              Edit
            </Button>
        ) : null}
      </Box>
      <Box sx={{ display: "flex", px: 2, py: 1 }}>
        <form style={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", width: "48%", marginRight: 2 }}>
              <Box sx={{ width: "100%" }}>
                <Input
                  control={control}
                  name="firstName"
                  label="First name"
                  disabled
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", width: "48%" }}>
              <Box sx={{ width: "100%" }}>
                <Input
                  control={control}
                  name="lastName"
                  label="Last name"
                  disabled
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", width: "48%", marginRight: 2 }}>
              <Box sx={{ width: "100%" }}>
                <Input
                  control={control}
                  name="userName"
                  label="User name (e-mail)"
                  disabled
                />{" "}
              </Box>
            </Box>
            <Box sx={{ display: "flex", width: "48%" }}>
              <Box sx={{ width: "100%" }}>
                <Input control={control} name="role" label="Role" disabled />
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
      <ChangePasswordModal
          getData={() => null}
          isOpen={changePasswordOpen}
          user=""
          handleModalClose={() => handleModalClose(setChangePasswordOpen)}
      />
    </>
  );
};

export default ShowUserInfo;
