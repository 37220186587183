import { Box, Button, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import ModalComponent from '../Modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	user?: any;
	getData: () => void;
}

const ResetPasswordModal = ({ isOpen, handleModalClose, user, getData }: Props) => {
	const formSchema = Yup.object().shape({
		password: Yup.string()
			.required('Password is required')
			.min(1, 'Password length should be at least 4 characters')
			.max(50, 'Password length should not exceed 50 characters'),
		confirmPassword: Yup.string()
			.required('Confirm Password is required')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	});

	const validationOpt = { resolver: yupResolver(formSchema) };

	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<any>(validationOpt);

	const onSubmit = (data: any) => {
		axios
			.put(
				`${process.env.REACT_APP_API_BASE_URL}/app/user/reset/password?userName=${user.userName}
				`,
				{ newPassword: data.password },
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Password changed successfully');
				handleModalClose();
				getData();
				reset();
			})
			.catch(err => {
				toast.error('Error occurred');
				console.log(err);
			});
	};

	return (
		<form>
			<ModalComponent
				isOpen={isOpen}
				handleClose={handleModalClose}
				title="Reset Password"
				dialogActions={
					<Box display="flex" sx={{ p: 2 }}>
						<Button onClick={handleModalClose}>Cancel</Button>
						<Button onClick={handleSubmit(onSubmit)}>Save</Button>
					</Box>
				}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
					<Box>
						<TextField
							fullWidth
							id="password"
							label="Password"
							variant="standard"
							type="password"
							{...register('password')}
							required
							error={errors.password ? true : false}
						/>
						<Typography variant="body1" sx={{ color: 'red' }}>
							{errors.password?.message}
						</Typography>
					</Box>
					<Box>
						<TextField
							fullWidth
							label="Confirm Password"
							id="confirmPassword"
							variant="standard"
							type="password"
							{...register('confirmPassword')}
							required
							error={errors.confirmPassword ? true : false}
						/>
						<Typography variant="body1" sx={{ color: 'red' }}>
							{errors.confirmPassword?.message}
						</Typography>
					</Box>
				</Box>
			</ModalComponent>
		</form>
	);
};

export default ResetPasswordModal;
