import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import FloatingMenu from "../floatingMenu";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ComponentCard from "../ComponentCard";
import useFetch from "../../hooks/useFetch";
import DrawerComponent from "../DrawerComponent";
import Input from "../inputs/Input";
import CloseIcon from "@mui/icons-material/Close";
import CompetitorsImportModal from "./CompetitorsImportModal";
import CompetitorsDeleteModal from "./CompetitorsDeleteModal";
import CompetitorsEditModal from "./CompetitorsEditModal";

interface Props {
  userRole: string;
}
const CompetitorsTable = ({ userRole }: Props) => {
  const [drawer, setDrawer] = React.useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(5);
  const [page, setPage] = React.useState<any>(0);
  const [sortField, setSortField] = React.useState<any>("fullName");
  const [sortDirection, setSortDirection] = React.useState<any>("ASC");

  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeleteOpen, setDeleteOpen] = React.useState(false);
  const [editData, setEditData] = React.useState<any>({});
  const [edit, setEdit] = React.useState(Boolean);

  // handle closign FloatingMenu after MenuItem click
  const [closeMenu, setCloseMenu] = React.useState(false);
  const [filters, setFilters] = React.useState({
    fullNameFilter: "",
    shortNameFilter: "",
  });

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      fullNameFilter: "",
      shortNameFilter: "",
    },
  });

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/competitor/get/p/extended/sort/filter?active=true&fullNameFilter=${filters.fullNameFilter}&p=${page}&s=${rowsPerPage}&shortNameFilter=${filters.shortNameFilter}&sortBy=${sortField}&sortDirection=${sortDirection}`
  );

  const totalItems = data?.totalItems;

  const handleFilterSubmit = handleSubmit((data) => {
    setFilters({
      fullNameFilter: data.fullNameFilter,
      shortNameFilter: data.shortNameFilter,
    });
    getData();
    setDrawer(false);
  });

  const handleFilterClear = () => {
    reset();
  };

  const handleOpen = (fn: any, row?: any) => {
    setCloseMenu(true);
    fn(true);
    if (row) {
      setEditData(row);
    }
  };

  const handleModalClose = (fn: any) => {
    setCloseMenu(false);
    fn(false);
  };

  const columns: TableColumnType<any>[] = [
    {
      title: "Full Name",
      dataKey: "fullName",
      sort: true,
      width: 200,
    },
    {
      title: "Short name",
      dataKey: "shortName",
      align: "left",
      sort: true,
      width: 100,
    },
  ];

  if (userRole === "ADMIN") {
    columns.push({
      title: "Actions",
      dataKey: "action",
      align: "right",
      sort: false,
      width: 50,
      children: (row) => {
        return (
          <FloatingMenu
            close={closeMenu}
            menuItems={[
              <MenuItem key={1} onClick={() => handleOpen(setEdit, row)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" />
              </MenuItem>,
              <MenuItem key={2} onClick={() => handleOpen(setDeleteOpen, row)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary="Delete" />
              </MenuItem>,
            ]}
          />
        );
      },
    });
  }

  return (
    <ComponentCard>
      {userRole === "ADMIN" ? (
        <>
          <TableComponent
            noSearch={true}
            data={data?.competition}
            columns={columns}
            onClickButtonVoid={() => handleOpen(setIsOpen)}
            // data for pagination
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            page={page}
            totalItems={totalItems}
            filter={() => setDrawer(true)}
            // data for sorting
            sortField={sortField}
            setSortField={setSortField}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
          <DrawerComponent drawer={drawer} setDrawer={setDrawer}>
            <Box sx={{ mx: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>Filter</h3>
                <IconButton onClick={() => setDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                <form>
                  <Input
                    name="fullNameFilter"
                    control={control}
                    label="Full Name"
                  />
                  <Input
                    name="shortNameFilter"
                    control={control}
                    label="Short Name"
                  />
                </form>
              </Box>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button color="primary" onClick={handleFilterClear}>
                  Clear
                </Button>
                <Button color="primary" onClick={handleFilterSubmit}>
                  Confirm
                </Button>
              </Box>
            </Box>
          </DrawerComponent>
        </>
      ) : (
        <TableComponent
          noSearch={true}
          data={data?.competition}
          columns={columns}
          // data for pagination
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          page={page}
          totalItems={totalItems}
          // data for sorting
          sortField={sortField}
          setSortField={setSortField}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      )}

      <CompetitorsImportModal
        getData={getData}
        isOpen={isOpen}
        handleModalClose={() => handleModalClose(setIsOpen)}
      />
      <CompetitorsDeleteModal
        getData={getData}
        userId={editData.id}
        isOpen={isDeleteOpen}
        handleModalClose={() => handleModalClose(setDeleteOpen)}
      />
      <CompetitorsEditModal
        getData={getData}
        competitor={editData}
        isOpen={edit}
        handleModalClose={() => handleModalClose(setEdit)}
      />
    </ComponentCard>
  );
};

export default CompetitorsTable;
