import React, { useContext } from "react";
import { Box } from "@mui/system";
import SearchInput from "../inputs/SearchInput";
import MessageUserCard from "./MessageUserCard";
import { FormControlLabel, Switch } from "@mui/material";

type Props = {
  visibleData?: any[];
  setId: (id: number) => void;
  setSearchString: (str: string) => void;
  showClosed: boolean;
  changeShowClosed(): void;
};

const UserList = ({ visibleData, setId, setSearchString, showClosed, changeShowClosed }: Props) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <SearchInput onSearch={(input) => setSearchString(input.toLowerCase())} />
        <FormControlLabel control={<Switch checked={showClosed} onChange={changeShowClosed} />} label='Show closed' />
      </Box>
      <Box
        sx={{ overflowY: "scroll", overflowX: "hidden" }}
        height="75vh"
        width="100%"
      >
        {visibleData
          ? visibleData.map(
              ({ creator, createdAt, topic, id, ticketStatus }: any) => (
                <MessageUserCard
                  setId={setId}
                  id={id}
                  key={id}
                  fullName={topic}
                  email={`${creator.firstName} ${creator.lastName} <${creator.userName}>`}
                  messageTime={createdAt}
                  unreadMessages={0}
                  status={ticketStatus}
                />
              )
            )
          : null}
      </Box>
    </Box>
  );
};

export default UserList;
