import { Box, Button, Typography } from "@mui/material";
import ModalComponent from "../Modal";
import SelectComponent from "../inputs/Select";
import { useForm } from "react-hook-form";
import Input from "../inputs/Input";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

type EditUserFields = {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  region: string;
  role: string;
};

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  user: EditUserFields;
  getData: () => void;
  regionOptions: any;
  roleOptions: any;
}

const EditUserModal = ({
  isOpen,
  handleModalClose,
  user,
  getData,
  regionOptions,
  roleOptions,
}: Props) => {
  const { firstName, lastName, userName, region, role } = user;
  const [confirm, setConfirm] = useState(false);

  const { control, handleSubmit, reset } = useForm<EditUserFields>({
    defaultValues: {
      firstName,
      lastName,
      userName,
      region,
      role,
    },
  });

  useEffect(() => {
    reset({
      firstName,
      lastName,
      userName,
      region,
      role,
    });
  }, [user]);

  const onSubmit = handleSubmit((data: EditUserFields) => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/app/user/edit?userName=${userName}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          region: data.region,
          role: data.role,
          activeTo: "2999-12-12",
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          getData();
          setConfirm(false);
          handleModalClose();
          toast.success("Edited successfully");
        } else {
          toast.error("Error occurred");
        }
      })
      .catch((err) => {
        toast.error("Error occurred");
        console.log(err);
      });
  });

  const titleName = 'Edit User - ' + userName;

  return (
    <form onSubmit={onSubmit}>
      {!confirm ? (
        <ModalComponent
          isOpen={isOpen}
          handleClose={handleModalClose}
          title= {titleName}
          dialogActions={
            <Box display='flex' sx={{ p: 2 }}>
              <Button onClick={handleModalClose}>Cancel</Button>
              <Button onClick={() => setConfirm(true)}>Save</Button>
            </Box>
          }
        >
          <Box sx={{ width: "100%" }}>
            <Input
              name='firstName'
              label='First Name'
              control={control}
              required
            />
            <Input
              name='lastName'
              label='Last Name'
              control={control}
              required
            />
            <SelectComponent
              options={regionOptions}
              name='region'
              label='Region'
              fullWidth
              control={control}
            />
            <SelectComponent
              options={roleOptions}
              name='role'
              label='Role'
              fullWidth
              control={control}
            />
          </Box>
        </ModalComponent>
      ) : (
        <ModalComponent
          title= {titleName}
          isOpen={isOpen}
          handleClose={handleModalClose}
          dialogActions={
            <Box
              display='flex'
              justifyContent='space-between'
              sx={{ p: 2, width: 180 }}
            >
              <Button variant='outlined' onClick={handleModalClose}>
                Cancel
              </Button>
              <Button variant='contained' onClick={onSubmit}>
                Save
              </Button>
            </Box>
          }
        >
          <div>
            <Typography variant='h4'>
              Are you sure you want to change user data?
            </Typography>
          </div>
        </ModalComponent>
      )}
    </form>
  );
};

export default EditUserModal;
