import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

type Props = {
  id: number;
  fullName: string;
  email: string;
  messageTime: string;
  status: string;
  unreadMessages?: number;
  setId: (id: number) => void;
};

const MessageUserCard = ({
  id,
  fullName,
  email,
  messageTime,
  status,
  unreadMessages,
  setId,
}: Props) => {
  return (
    <Box
      py={2}
      my={1}
      px={3}
      mx={-2}
      sx={{
        ":hover": {
          backgroundColor: "secondary.main",
          cursor: "pointer",
        },
      }}
      onClick={() => setId(id)}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{fullName}</Typography>
        <Typography variant="body2">
          {dayjs(messageTime).format("DD.MM.YYYY HH:mm")}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Typography variant="body1">{email}</Typography>
        <Typography variant="body1">{status}</Typography>
        {unreadMessages === 0 ? null : (
          <Chip label={unreadMessages} color="error" size="small" />
        )}
      </Box>
    </Box>
  );
};

export default MessageUserCard;
