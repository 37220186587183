import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../inputs/Input";
import ModalComponent from "../Modal";
import PriceListTable from "./PriceListTable";
import axios from "axios";
import SelectComponent from "../inputs/Select";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  client?: any;
  getData: () => void;
}

const ClientsEditModal = ({
  isOpen,
  handleModalClose,
  client,
  getData,
}: Props) => {
  const { id, customerName, city, region, innerIdCode, responsibleUser } =
    client;
  const [table, setTable] = useState(false);
  const [regions, setRegions] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/app/regionunit/get/ru`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const regionsOptions = res.data.map((data: string, index: Number) => {
          return {
            id: index,
            label: data,
            value: data,
          };
        });

        setRegions(regionsOptions);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/app/user/get/un`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const userOptions = res.data.map((data: string, index: Number) => {
          return {
            id: index,
            label: data,
            value: data,
          };
        });

        setUsers(userOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      id,
      customerName,
      city,
      region,
      innerIdCode,
      responsibleUser,
    },
  });

  useEffect(() => {
    reset({
      id,
      customerName,
      city,
      region,
      innerIdCode,
      responsibleUser,
    });
  }, [client]);

  const onSubmit = handleSubmit((data: any) => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/customer/edit?innerIdCode=${innerIdCode}`,
        {
          city: data.city,
          customerName: data.customerName,
          id,
          innerIdCode: data.innerIdCode,
          region: data.region,
          responsibleUser: data.responsibleUser,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Edited successfully");
        getData();
        handleModalClose();
      })
      .catch((err) => {
        toast.error("Error occur");
        console.log(err);
      });
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleModalClose}
      title="Customer"
      w="xl"
    >
      {!table ? (
        <form style={{ width: "100%", marginTop: -20 }}>
          <Box sx={{ width: "100%" }}>
            <Input
              name="customerName"
              label="Customer Name"
              control={control}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Input name="city" label="City" control={control} />
          </Box>

          <Box sx={{ width: "100%" }}>
            <SelectComponent
              control={control}
              name="region"
              label="Region"
              options={regions}
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            <Input
              name="innerIdCode"
              label="Customer Number"
              control={control}
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            <SelectComponent
              control={control}
              name="responsibleUser"
              label="Responsible user"
              options={users}
            />
          </Box>

          <Box></Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={onSubmit}>Save</Button>
          </Box>
        </form>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 2.5 }}>
            <Button variant="outlined" onClick={() => setTable(false)}>
              Go back
            </Button>
          </Box>
          <PriceListTable />
        </Box>
      )}
    </ModalComponent>
  );
};

export default ClientsEditModal;
