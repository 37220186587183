import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import Input from '../inputs/Input';
import ModalComponent from '../Modal';

interface Props {
	isOpen: boolean;
	handleModalClose: () => void;
	pickedPriceList?: any;
	forceUpdate: () => void;
}

const PriceListsAddProductsModal = ({
	isOpen,
	handleModalClose,
	pickedPriceList,
	forceUpdate,
}: Props) => {
	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			currency: '',
			materialDescription: '',
			orderCode: '',
			papg: '',
			priceListPrice: '',
			pricingUnit: '',
			productArea: '',
			productGroup: '',
			transferPrice: '',
			unitOfMeasure: '',
		},
	});

	const onSubmit = handleSubmit((data: any) => {
		axios
			.post(
				`${process.env.REACT_APP_API_BASE_URL}/app/product/add?priceListId=${pickedPriceList.id}
				`,
				{
					active: true,
					activeFrom: pickedPriceList?.validFrom,
					activeTo: pickedPriceList?.validTo,
					currency: data.currency,
					materialDescription: data.materialDescription,
					orderCode: data.orderCode,
					papg: data.papg,
					priceListPrice: data.priceListPrice,
					pricingUnit: data.pricingUnit,
					productArea: data.productArea,
					productGroup: data.productGroup,
					transferPrice: data.transferPrice,
					unitOfMeasure: data.unitOfMeasure,
				},
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					},
				}
			)
			.then(res => {
				toast.success('Added successfully');
				forceUpdate();
				handleModalClose();
				reset();
			})
			.catch(err => {
				toast.error('Error occurred');
				console.log(err);
			});
	});

	return (
		<ModalComponent
			isOpen={isOpen}
			handleClose={handleModalClose}
			title="Add Price List Product"
			w="xl"
		>
			<form style={{ width: '100%', marginTop: -20 }}>
				<Box sx={{ width: '100%' }}>
					<Input name="currency" label="Currency" control={control} />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input
						name="materialDescription"
						label="Material Description"
						control={control}
					/>
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="orderCode" control={control} label="Order Code" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="papg" control={control} label="PAPG" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="priceListPrice" control={control} label="Price" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="pricingUnit" control={control} label="Pricig Unit" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productArea" control={control} label="Product Area" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="productGroup" control={control} label="Product Group" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="transferPrice" control={control} label="Transfer Price" />
				</Box>
				<Box sx={{ width: '100%' }}>
					<Input name="unitOfMeasure" control={control} label="Unit Of Measure" />
				</Box>
				<Box></Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
					<Button onClick={handleModalClose}>Cancel</Button>

					<Button onClick={onSubmit}>Save</Button>
				</Box>
			</form>
		</ModalComponent>
	);
};

export default PriceListsAddProductsModal;
