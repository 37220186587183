import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {Typography, Box, Button, Checkbox, TextareaAutosize} from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ComponentCard from "./ComponentCard";
import Input from "./inputs/Input";
import Textarea from "./inputs/InputMultiline";
import InputMultiline from "./inputs/InputMultiline";

const NewAdminTicket = (): JSX.Element => {
  const history = useHistory();
  const [data, setData] = useState<any>({});

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      topic: '',
      ticketMessage: ''
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/app/ticket/new`, data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          }
        });
      toast.success('Ticket created succesfully!');
      reset();
      history.push('/messages')
    } catch {
      toast.error('Error occured during saving.');
    }
  });

  return (
    <ComponentCard>
      <form>
        <Input
          control={control}
          name="topic"
          label="Topic"
          required
        />
        {/*<Textarea*/}
        {/*    control={control}*/}
        {/*    name="ticketMessage"*/}
        {/*    label="Message"*/}
        {/*    required*/}
        {/*/>*/}
        <InputMultiline
          control={control}
          name="ticketMessage"
          label="Message"
          rows={6}
          required
        />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onSubmit}>Save</Button>
        </div>
      </form>
    </ComponentCard >
  );
};

export default NewAdminTicket;
