import { Key } from "react";
import { TableColumnType, TableGetRowKey } from "./types";
import BodyRow from "./bodyRow";
import { TableBody } from "@mui/material";

type Option = {
    id: number;
    label: string;
    value: string;
};

export interface BodyProps<T> {
  columns: ReadonlyArray<TableColumnType<T>>;
  data?: ReadonlyArray<T>;
  competitionData?: Array<any>;
  getRowKey?: TableGetRowKey<T>;
  setRowsEditStatus?: (id: number) => void;
  rowsEditStatus?: Map<number, boolean>;
  saveRowData?: any;
  changeInputData?: any;
  cancelEdit?: any;
  orderCodeData: Option[];
}

function Body<T>(
    {
      columns,
      data,
      competitionData,
      getRowKey,
      setRowsEditStatus,
      rowsEditStatus,
      saveRowData,
      changeInputData,
      cancelEdit,
      orderCodeData
}: BodyProps<T>) {
  // console.log(" columns", columns);
  // console.log(" data", data);
  // console.log(" getRowKey", getRowKey);

  return (
    <TableBody>
      {data && data.length ? (
        data.map((rowData, index) => {
          let key: Key = index;

          if (typeof getRowKey === "function") {
            key = getRowKey(rowData, index);
          } else if (getRowKey) {
            key = rowData[getRowKey] as any;
          }
          return (
            <BodyRow
              key={index}
              columns={columns}
              rowData={rowData}
              rowIndex={index}
              competitionData={competitionData}
              setRowsEditStatus={setRowsEditStatus}
              rowsEditStatus={rowsEditStatus}
              saveRowData={saveRowData}
              changeInputData={changeInputData}
              cancelEdit={cancelEdit}
              orderCodeData={orderCodeData}
            />
          );
        })
      ) : (
        <tr>
          <td>no data</td>
        </tr>
      )}
    </TableBody>
  );
}

export default Body;
