import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
  IconButton,
  Button, SelectChangeEvent,
} from "@mui/material";
import Input from "../inputs/Input";
import CloseIcon from "@mui/icons-material/Close";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import React, {useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import ComponentCard from "../ComponentCard";
import FloatingMenu from "../floatingMenu/index";
import TableComponent from "../table/Table";
import { TableColumnType } from "../table/types";
import EditIcon from "@mui/icons-material/Edit";
import AddProjectModal from "./AddProjectModal";
import EditProjectModal from "./EditProjectsModal";
import EditStatusModal from "./EditStatusModal";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import DrawerComponent from "../DrawerComponent";
import SelectComponent from "../inputs/Select";
import DatePicker from "../inputs/DatePicker";
import { useAuthContext } from "../../hooks/useAuth";
import SendOfferTicketModal from "./SendOfferTIcketModal";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import axios from "axios";
import { toast } from "react-toastify";
import AcceptLvlOneModal from "./AcceptLvlOneModal";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SuccessOrFailModal from "./SuccessOrFailModal";
import AcceptLvlTwoModal from "./AcceptLvlTwoModal";
import { useHistory } from "react-router-dom";
import ProposePriceModal from "./ProposePriceModal";
import {Download, ImportExport} from "@mui/icons-material";
import fileDownload from "js-file-download";
import SelectMultipleComponent from "../inputs/SelectMultiple";

export const checkStatus = (status: string) => {
  switch (status) {
    case "SUCCESS":
      return "success";
    case "DRAFT":
      return "default";
    case "FAIL":
      return "error";
    case "ACCEPT1RQ":
      return "warning";
    case "ACCEPT2RQ":
      return "warning";
    case "DECLINED":
      return "error";
    case "ACCEPTED":
      return "success";
    case "ARCHIVED":
      return "default";
    default:
      return "default";
  }
};

const Projects = () => {
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState<any>([]);
  const [editStatus, setEditStatus] = useState(false);
  const [offerMessage, setOfferMessage] = useState(false);
  const [acceptLvlOne, setAcceptLvlOne] = useState(false);
  const [acceptLvlTwo, setAcceptLvlTwo] = useState(false);
  const [successOrFail, setSuccessOrFail] = useState(false);
  const [proposePriceModal, setProposePrice] = useState(false);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      creatorUserName: "",
      customerInnerId: "",
      customerName: "",
      creatorName: "",
      offerNumber: "",
      offerStatus: "",
      updateDateFrom: "",
      updateDateTo: "",
    },
  });

  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [page, setPage] = useState<number>(0);
  const [sortField, setSortField] = useState<any>("updatedAt");
  const [sortDirection, setSortDirection] = useState<any>("DESC");
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<string[]>(() => {
    let filterData = localStorage.getItem('filterData');
    if (filterData !== null) {
      let filterDataObj = JSON.parse(filterData);
      if (filterDataObj.offerStatus.length > 0) {
       return filterDataObj.offerStatus.split(',');
      }
    }
    return [];
  });

  const [drawer, setDrawer] = useState(false);
  const [filters, setFilters] = useState(() => {
    let filterData = localStorage.getItem('filterData');
    if (filterData !== null) {
      let filterDataObj = JSON.parse(filterData);
      reset(filterDataObj);
      return {
        creatorUserName: filterDataObj.creatorUserName,
        customerInnerId: filterDataObj.customerInnerId,
        customerName: filterDataObj.customerName,
        creatorName: filterDataObj.creatorName,
        offerNumber: filterDataObj.offerNumber,
        offerStatus: filterDataObj.offerStatus,
        updateDateFrom: filterDataObj.updateDateFrom,
        updateDateTo: filterDataObj.updateDateTo,
      };
    }
    return {
      creatorUserName: "",
      customerInnerId: "",
      customerName: "",
      creatorName: "",
      offerNumber: "",
      offerStatus: "",
      updateDateFrom: "",
      updateDateTo: "",
    };
  });

  const auth = useAuthContext();
  const { userInfo } = auth;
  const userRole = userInfo?.role;

  const history = useHistory();

  const statusOpts = [
    {
      id: 1,
      value: "DRAFT",
      label: "DRAFT",
    },
    {
      id: 2,
      value: "ACCEPT1RQ",
      label: "ACCEPT1RQ",
    },
    {
      id: 3,
      value: "ACCEPT2RQ",
      label: "ACCEPT2RQ",
    },
    {
      id: 4,
      value: "DECLINED",
      label: "DECLINED",
    },
    {
      id: 5,
      value: "ACCEPTED",
      label: "ACCEPTED",
    },
    {
      id: 6,
      value: "SUCCESS",
      label: "SUCCESS",
    },
    {
      id: 7,
      value: "FAIL",
      label: "FAIL",
    },
  ];



  // const getData = (data? : any) => {
  //   let API_URL = `${process.env.REACT_APP_API_BASE_URL}/app/offer/get/p/base/sort/filter?p=${filters.page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&creatorUserName=${filters.creatorUserName}&customerInnerId=${filters.customerInnerId}&customerName=${filters.customerName}&creatorName=${filters.creatorName}&offerNumber=${filters.offerNumber}&offerStatuses=${filters.offerStatus}&updateDateFrom=${filters.updateDateFrom}&updateDateTo=${filters.updateDateTo}`;
  //   if (data !== undefined) {
  //     API_URL = `${process.env.REACT_APP_API_BASE_URL}/app/offer/get/p/base/sort/filter?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&creatorUserName=${data.creatorUserName}&customerInnerId=${data.customerInnerId}&customerName=${data.customerName}&creatorName=${data.creatorName}&offerNumber=${data.offerNumber}&offerStatuses=${data.offerStatus}&updateDateFrom=${data.updateDateFrom}&updateDateTo=${data.updateDateTo}`;
  //   }
  //
  //   axios
  //       .get(API_URL, {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       })
  //       .then(response => setData(response.data))
  //       .catch(error => console.error(error));
  // }

  const { data, getData } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/app/offer/get/p/base/sort/filter?p=${page}&s=${rowsPerPage}&sortBy=${sortField}&sortDirection=${sortDirection}&creatorUserName=${filters.creatorUserName}&customerInnerId=${filters.customerInnerId}&customerName=${filters.customerName}&creatorName=${filters.creatorName}&offerNumber=${filters.offerNumber}&offerStatuses=${filters.offerStatus}&updateDateFrom=${filters.updateDateFrom}&updateDateTo=${filters.updateDateTo}`);

  // const projectData = data?.projects;
  // const totalItems = data?.totalItems;

  const handleOpen = (fn: any, data?: any) => {
    fn(true);
    if (data) {
      setEditData(data);
    }
  };

  const handleClose = (fn: any) => {
    fn(false);
  };

  const handleFilterSubmit = handleSubmit((data) => {
    let filterData = {
      creatorUserName: data.creatorUserName,
      customerInnerId: data.customerInnerId,
      customerName: data.customerName,
      creatorName: data.creatorName,
      offerNumber: data.offerNumber,
      page: 0,
      rowsPerPage: 10,
      offerStatus: selectedOrderStatuses.join(','),
      updateDateFrom:
          data.updateDateFrom !== ""
              ? dayjs(data.updateDateFrom).format("YYYY-MM-DD")
              : "",
      updateDateTo:
          data.updateDateTo !== ""
              ? dayjs(data.updateDateTo).format("YYYY-MM-DD")
              : "",
    }
    reset(filterData);
    localStorage.setItem('filterData', JSON.stringify(filterData));
    setFilters(filterData);
    setDrawer(false);
  });

  const changeStatusItem = (item: any) => {
    if (userRole === "ADMIN") {
      return (
        <MenuItem key={2} onClick={() => handleOpen(setEditStatus, item)}>
          <ListItemIcon>
            <PriorityHighIcon />
          </ListItemIcon>
          <ListItemText primary="Edit Status" />
        </MenuItem>
      );
    }
    return null;
  };

  const FirstAcceptSend = (item: any) => {
    if (userRole === "ACCEPT1" && item.status === "ACCEPT1RQ") {
      return (
        <MenuItem key={21} onClick={() => handleOpen(setAcceptLvlOne, item)}>
          <ListItemIcon>
            <ShortcutIcon />
          </ListItemIcon>
          <ListItemText primary="Change status from ACCEPT1RQ" />
        </MenuItem>
      );
    }
    return null;
  };

  const SendForApproval = (item: any) => {
    if (userRole === "USER" && item.status === "DRAFT") {
      return (
        <MenuItem key={24} onClick={() => sendOfferForApproval(item)}>
          <ListItemIcon>
            <ShortcutIcon />
          </ListItemIcon>
          <ListItemText primary="Send for approval" />
        </MenuItem>
      );
    }
    return null;
  };

  const SecondAcceptSend = (item: any) => {
    if (userRole === "ACCEPT2" && item.status === "ACCEPT2RQ") {
      return (
        <MenuItem key={22} onClick={() => handleOpen(setAcceptLvlTwo, item)}>
          <ListItemIcon>
            <ShortcutIcon />
          </ListItemIcon>
          <ListItemText primary="Change status from ACCEPT2RQ" />
        </MenuItem>
      );
    }
    return null;
  };

  const proposePrice = (item: any) => {
    if (
      (userRole === "ACCEPT1" && item.status === "ACCEPT1RQ") ||
      (userRole === "ACCEPT2" && item.status === "ACCEPT2RQ")
    ) {
      return (
        <MenuItem key={23} onClick={() => handleOpen(setProposePrice, item)}>
          <ListItemIcon>
            <PriceChangeIcon />
          </ListItemIcon>
          <ListItemText primary="Propose Price" />
        </MenuItem>
      );
    }
    return null;
  };

  const editProject = (item: any) => {
    if (
      (item.status !== "SUCCESS" &&
        item.status !== "FAIL" &&
        userInfo.userName === item.offerCreatorInfo.userName) ||
      userRole === "ADMIN"
    ) {
      return (
        <MenuItem key={1} onClick={() => handleOpen(setEditOpen, item)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit Project" />
        </MenuItem>
      );
    }
    return null;
  };

  const successOrFailStatus = (item: any) => {
    if (
      item.status === "ACCEPTED" &&
      item.offerCreatorInfo.userName === userInfo?.userName
    ) {
      return (
        <MenuItem key={23} onClick={() => handleOpen(setSuccessOrFail, item)}>
          <ListItemIcon>
            <ThumbUpAltIcon />
          </ListItemIcon>
          <ListItemText primary="Change status of your offer" />
        </MenuItem>
      );
    }
    return null;
  };
  const sendOfferForApproval = (item: any) => {
    const toastId = toast.loading(`offer ${item.projectNumber} sending for approval. Please wait...`);
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/app/offer/send?offerId=${item.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.update(toastId, {render: `offer ${item.projectNumber} sent for approval`, type: "success", isLoading: false, autoClose: 5000});
        //toast.success(`offer ${item.projectNumber} sent for approval`);
        getData();
      })
      .catch((err) => {
        console.log(err);
        toast.update(toastId, {render: `offer ${item.projectNumber} not sent for approval`, type: "error", isLoading: false, autoClose: 5000});
        //toast.error(`offer ${item.projectNumber} not sent for approval`);
      });
  };

  const exportToExcel = (item: any) => {
    return (
      <MenuItem key={5} onClick={() => exportToExcelRequest(item)}>
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        <ListItemText primary="Export to Excel" />
      </MenuItem>
    );
  };

  const exportToExcelRequest = (item: any) => {
    axios
        .get(
            `${process.env.REACT_APP_API_BASE_URL}/app/offer/export?offerId=${item.id}`,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              responseType: "blob",
            }
        )
        .then((res) => {
          console.log(item);
          fileDownload(res.data, "Report_" + item.projectNumber.replace('/','_') + ".xlsx");
        })
        .catch((err) => {
          toast.error("Error occurred");
          console.log(err);
        });
  };

  const moreInfo = (item: any) => {
    const handleRedirect = () => {
      history.push(`/projects/info/${item.id}`);
    };

    return (
        <MenuItem key={5} onClick={handleRedirect}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="More Info" />
        </MenuItem>
    );
  };

  let columns: TableColumnType<any>[] = [];
  columns.push(
      {
        title: "Project Number",
        dataKey: "projectNumber",
        width: 100,
        sort: true,
      },
      {
        title: "Customer Number",
        dataKey: "contractorNumber",
        width: 100,
        sort: true,
      },
      {
        title: "Customer Name",
        dataKey: "contractorName",

        width: 150,
        sort: true,
      }
  );
  if (userRole === "ACCEPT1" || userRole === "ACCEPT2" || userRole === "ADMIN") {
    columns.push({
      title: "Creator Name",
      dataKey: "creatorName",

      width: 150,
      sort: true,
    });
  }
  columns.push(
      {
      title: "Last Update",
      dataKey: "updatedAt",
      children: ({ offerDateInfo }: any) => (
          <p>{dayjs(offerDateInfo.updatedAt).format("YYYY-MM-DD")}</p>
      ),
      width: 150,
      sort: true,
    },
    {
      title: "Status",
      dataKey: "status",
      width: 200,
      align: "center",
      sort: true,
      children: ({ status }) => (
          <Chip label={status} color={status ? checkStatus(status) : "primary"} />
      ),
    },
    {
      title: "Actions",
      width: 10,
      align: "center",
      sort: false,
      children: (item) => (
          <FloatingMenu
              menuItems={[
                // <MenuItem key={1} onClick={() => handleOpen(setEditOpen, item)}>
                //   <ListItemIcon>
                //     <EditIcon />
                //   </ListItemIcon>
                //   <ListItemText primary="Edit Project" />
                // </MenuItem>,
                editProject(item),
                changeStatusItem(item),
                <MenuItem key={3} onClick={() => handleOpen(setOfferMessage, item)}>
                  <ListItemIcon>
                    <LocalPostOfficeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Send message" />
                </MenuItem>,
                FirstAcceptSend(item),
                SecondAcceptSend(item),
                SendForApproval(item),
                successOrFailStatus(item),
                proposePrice(item),
                moreInfo(item),
                exportToExcel(item)
                // <MenuItem key={5} onClick={}>
                //   <ListItemIcon>
                //     <InfoIcon />
                //   </ListItemIcon>
                //   <Link
                //     style={{ textDecoration: "none", color: "black" }}
                //     to={`/projects/info/${item.id}`}
                //   >
                //     <ListItemText primary="More Info" />
                //   </Link>
                // </MenuItem>,
              ]}
          />
      ),
    },
  );



  const handleChange = (event: SelectChangeEvent<typeof selectedOrderStatuses>) => {
    if (typeof event.target.value !== "string") {
      setSelectedOrderStatuses(event.target.value);
    }
  };

  const resetFormData = () => {
    setSelectedOrderStatuses([]);
    reset({
      creatorUserName: "",
      customerInnerId: "",
      customerName: "",
      creatorName: "",
      offerNumber: "",
      offerStatus: "",
      updateDateFrom: "",
      updateDateTo: ""
    });
    setFilters({
      creatorUserName: "",
      customerInnerId: "",
      customerName: "",
      creatorName: "",
      offerNumber: "",
      offerStatus: "",
      updateDateFrom: "",
      updateDateTo: "",
      // page: 0,
      // rowsPerPage: 10
    });
  };

  const removeFilter = (data: any) => {
    let formData = {
      creatorUserName: filters.creatorUserName,
      customerInnerId: filters.customerInnerId,
      customerName: filters.customerName,
      creatorName: filters.creatorName,
      offerNumber: filters.offerNumber,
      offerStatus: filters.offerStatus,
      updateDateFrom: filters.updateDateFrom,
      updateDateTo: filters.updateDateTo,
    }

    switch (data) {
      case "creatorUserName":
        formData.creatorUserName = "";
        break;
      case "customerInnerId":
        formData.customerInnerId = "";
        break;
      case "customerName":
        formData.customerName = "";
        break;
      case "creatorName":
        formData.creatorName = "";
        break;
      case "offerNumber":
        formData.offerNumber = "";
        break;
      case "updateDateFrom":
        formData.updateDateFrom = "";
        break;
      case "updateDateTo":
        formData.updateDateTo = "";
        break;
      case "offerStatus":
        setSelectedOrderStatuses([]);
        formData.offerStatus = "";
        // filters.offerStatus = "";
        break;
    }
    reset(formData);
    setFilters(formData);
    localStorage.setItem('filterData', JSON.stringify(formData));
  }

  return (
    <ComponentCard>
      <TableComponent
        columns={columns}
        data={data?.projects}
        onClickButtonVoid={() => handleOpen(setAddOpen)}
        onClickButtonVoidText="Add Project"
        noSearch
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        page={page}
        totalItems={data?.totalItems}
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        filter={() => setDrawer(true)}
        filters={filters}
        onClickRemoveFilter={removeFilter}
      />
      <DrawerComponent drawer={drawer} setDrawer={setDrawer}>
        <form>
          <Box sx={{ mx: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Filter</h3>
              <IconButton onClick={() => setDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              <form>
                <Input
                  control={control}
                  name="customerInnerId"
                  label="Customer Number"
                />
                <Input
                  control={control}
                  name="customerName"
                  label="Customer Name"
                />
                { (userRole === "ACCEPT1" || userRole === "ACCEPT2" || userRole === "ADMIN") && (
                    <Input
                    control={control}
                    name="creatorName"
                    label="Creator Name"
                    />
                )}
                <Input
                  control={control}
                  name="offerNumber"
                  label="Project Number"
                />
                <Box sx={{ width: "100%", ml: 1 }}>
                  <DatePicker
                    fullWidth
                    name="updateDateFrom"
                    control={control}
                    label="Last updated from: "
                  />
                </Box>
                <Box sx={{ width: "100%", ml: 1 }}>
                  <DatePicker
                    fullWidth
                    name="updateDateTo"
                    control={control}
                    label="Last updated to: "
                  />
                </Box>
                <SelectMultipleComponent
                  control={control}
                  options={statusOpts}
                  multiple={true}
                  value={selectedOrderStatuses}
                  name="offerStatus"
                  label="Status"
                  variant="filled"
                  onChange={handleChange}
                />
              </form>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="primary"
                onClick={resetFormData}
              >
                Clear
              </Button>
              <Button color="primary" onClick={handleFilterSubmit}>
                Confirm
              </Button>
            </Box>
          </Box>
        </form>
      </DrawerComponent>
      {offerMessage && (
        <SendOfferTicketModal
          isOpen={offerMessage}
          handleModalClose={() => handleClose(setOfferMessage)}
          project={editData}
        />
      )}
      {addOpen && (
        <AddProjectModal
          isOpen={addOpen}
          handleModalClose={() => handleClose(setAddOpen)}
          getProjectData={getData}
        />
      )}
      {editOpen && (
        <EditProjectModal
          isOpen={editOpen}
          handleModalClose={() => handleClose(setEditOpen)}
          getEditData={getData}
          project={editData}
        />
      )}
      {editStatus && (
        <EditStatusModal
          isOpen={editStatus}
          handleModalClose={() => handleClose(setEditStatus)}
          project={editData}
          getData={getData}
        />
      )}
      {acceptLvlOne && (
        <AcceptLvlOneModal
          isOpen={acceptLvlOne}
          handleModalClose={() => handleClose(setAcceptLvlOne)}
          project={editData}
          getData={getData}
        />
      )}
      {acceptLvlTwo && (
        <AcceptLvlTwoModal
          isOpen={acceptLvlTwo}
          handleModalClose={() => handleClose(setAcceptLvlTwo)}
          project={editData}
          getData={getData}
        />
      )}
      {successOrFail && (
        <SuccessOrFailModal
          isOpen={successOrFail}
          handleModalClose={() => handleClose(setSuccessOrFail)}
          project={editData}
          getData={getData}
        />
      )}
      {proposePriceModal && (
        <ProposePriceModal
          isOpen={proposePriceModal}
          handleModalClose={() => handleClose(setProposePrice)}
          project={editData}
        />
      )}
    </ComponentCard>
  );
};

export default Projects;
