import GroupIcon from "@mui/icons-material/Group";
import ForumIcon from "@mui/icons-material/Forum";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import AssistantIcon from "@mui/icons-material/Assistant";
import HandymanIcon from "@mui/icons-material/Handyman";
import { Role } from "../hooks/useAuth";

export const listItems = [
  {
    name: "Users",
    link: "/users",
    icon: <GroupIcon color="secondary" />,
    role: [Role.ADMIN],
  },
  {
    name: "Projects",
    link: "/projects",
    icon: <HandymanIcon color="secondary" />,
    role: [Role.ADMIN, Role.ACCEPT2, Role.ACCEPT1, Role.USER],
  },
  {
    name: "Messages",
    link: "/messages",
    icon: <ForumIcon color="secondary" />,
    role: [Role.ADMIN, Role.ACCEPT2, Role.ACCEPT1, Role.USER],
  },
  {
    name: "Dictionary",
    link: "/dictionary",
    icon: <ImportContactsIcon color="secondary" />,
    role: [Role.ADMIN, Role.ACCEPT2],
  },
  {
    name: "Raports",
    link: "/raports",
    icon: <ArticleIcon color="secondary" />,
    role: [Role.ADMIN, Role.ACCEPT2, Role.ACCEPT1],
  },
  {
    name: "Variables",
    link: "/variables",
    icon: <SettingsIcon color="secondary" />,
    role: [Role.ADMIN],
  },
  {
    name: "New admin ticket",
    link: "/newAdminTicket",
    icon: <AssistantIcon color="secondary" />,
    role: [Role.ADMIN, Role.ACCEPT2, Role.ACCEPT1, Role.USER],
  },
];
